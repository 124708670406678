/*
	genuinePG frontend

	JS entry point

    @file         main.js
    @copyright    P&G

*/

import App from "./App.svelte";

const app = new App({
  target: document.body,
});

export default app;
