<!--
	genuinePG frontend

	Page, opened from Page_check.svelteHTML

    Imprint, General and legal information about app

    @file         Page_about.svelte
    @copyright    P&G
-->

<script>
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from "./i18n"
  import { PAGE } from "./config";
  import { ArrowLeftCircleIcon } from "svelte-feather-icons";

  // ========================= Swipe right to go back ==========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("page-termsAndCondition");

    // // console.log(swipeArea);

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        close();
      }
    });
  }; //setupSwipeRight()

  // =========================== Construction / Init ===========================

  import { onMount } from "svelte";

  onMount(async () => {
    setupSwipeRight();
  }); //onMount()

  const close = () => {
    dispatch("close", { page: PAGE.check });
  }; //close()
</script>

<!-- =============================  H T M L  ============================= -->

<div class="page" id="page-termsAndCondition">
  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>

  <h2>{$t("page_tnc.tnc")}</h2>
  <p>{$t("page_tnc.linkDesc")}
     <a
      href="https://termsandconditions.pg.com/en-us/"
      target="_blank"
      rel="noreferrer">{$t("page_tnc.tnc")}</a
    >
    {$t("page_tnc.linkDesc1")}
  </p>

  <!-- 
    <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  p {
    margin-bottom: 1em;
  }

  #btn-back {
    color: var(--PG-blue);
    border: none;
    background: none;
  }
</style>
