<!--
	genuinePG frontend

	Page, opened from Modal_menu.svelte

    User settings, e.g. registrations / account

    @file         Page_settings.svelte
    @copyright    P&G
-->

<script>
  import { PAGE } from "./config";
  import Child_page_registraion from "./Page_registraion.svelte";
  import { URI_API, dateStr, toEncrypt, toDecrypt } from "./minions";
  import { MailIcon, KeyIcon, ArrowLeftCircleIcon } from "svelte-feather-icons";
  import { createEventDispatcher } from "svelte";
  import {t} from "./i18n"
  import { st_token_id } from "./stores";
  let dispatch = createEventDispatcher();
  export let theme;
  let busy = false;

  let token_id;
  st_token_id.subscribe((value)=> {
    token_id = value;
  })

  // ============================= store variables =============================

  import { st_connected, st_permissionIDB } from "./stores";

  let permissionIDB = true;
  st_permissionIDB.subscribe((value) => {
    permissionIDB = value;
  });

  // ============================ User Registration ============================

  import { domainDash } from "./config";
  import { st_username, st_email, st_userRegistered, st_auth } from "./stores";
  import {
    storeUserData,
    updateInstructions,
    readUserData,
    clearUserData,
  } from "./minions";

  let userRegistered = false;
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
  });

  let user_name;
  st_username.subscribe((value) => {
    user_name = value;
  });

  let user_email;
  let user_email_valid = false;
  st_email.subscribe((value) => {
    user_email = value;
  });

  let auth;
  st_auth.subscribe((value) => {
    auth = value;
  });

  let user_code = "";

  const validateEmail = () => {
    let str = user_email.toLowerCase();
    str = str.replace(/([^a-z0-9_\.@!#%&\-+%~`']+)/gi, "");
    user_email = str;

    // Check if valid email address
    user_email_valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      str
    );
  }; //validateEmail()

  const requestUserRegistration = async () => {
    validateEmail();
    if (!user_email_valid) {
      dispatch("message", { text: $t("page_settings.register")  });
      return;
    }
    if (user_code.length < 2) {
      dispatch("message", { text: $t("page_settings.inviteCode")  });
      return;
    }

    if (busy) {
      return;
    }
    busy = true;

    let reqJson = {
      email: await toEncrypt(user_email),
      token: await toEncrypt(user_code),
      token_type: await toEncrypt("usecode"),
      browser:
        (await toEncrypt(navigator.userAgent)) || (await toEncrypt("unk")),
    };

    const reply = await fetch(URI_API("registerUser"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqJson),
    });

    // // console.log("reply register: ",reply);

    if (reply.ok) {
      const data = await reply.json();

      if (!("name" in data && "userID" in data && "token" in data)) {
        dispatch("message", {
          text: $t("page_settings.registerFailed") ,
        });
        busy = false;
        return;
      }
      data.name = await toDecrypt(data.name);
      data.token = await toDecrypt(data.token);
      data.userID = await toDecrypt(data.userID);
      data.token_id = await toDecrypt(data.token_id);
      
    
      // // console.log("data 2: ",data);

      await storeUserData({ ...data, email: user_email});
      await readUserData();
      await updateInstructions();

      if (userRegistered) {
        usecode = false;
        migration = false;
        dispatch("message", { text: $t("page_settings.registerSuccess") });

        await checkAccountActive();
       
      } else {
        dispatch("message", {
          text: $t("page_settings.registerFailed1"),
        });
      }
    } else {
      if (reply.status >= 500) {
        st_connected.set(false);
        dispatch("message", {
          text: $t("page_settings.offline"),
        });
      } else {
        const msg = await reply.text();
        dispatch("message", {
          text: $t("page_settings.registerFailed2") + msg,
        });
      }
    }
    busy = false;
  }; //registerUser()

   const requestUserMigrationRegistration = async()=>{
    validateEmail();
    if (!user_email_valid) {
      dispatch("message", { text: $t("page_settings.register") });
      return;
    }
    if (user_code.length < 2) {
      dispatch("message", { text: $t("page_settings.inviteCode") });
      return;
    }

    if (busy) {
      return;
    }
    busy = true;

    let reqJson = {
      email: await toEncrypt(user_email),
      token_storage: await toEncrypt(user_code),
      token_type: await toEncrypt("migration"),
      browser:
        (await toEncrypt(navigator.userAgent)) || (await toEncrypt("unk")),
    };

    const reply = await fetch(URI_API("registerUsingTransferToken"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqJson),
    });

    // // console.log("reply register: ",reply);

    if (reply.ok) {
      const data = await reply.json();

      if (!("name" in data && "userID" in data && "token" in data)) {
        dispatch("message", {
          text: $t("page_settings.registerFailed"),
        });
        busy = false;
        return;
      }
      data.name = await toDecrypt(data.name);
      data.token = await toDecrypt(data.token);
      data.userID = await toDecrypt(data.userID);
      data.token_id = await toDecrypt(data.token_id);


      // // console.log("data 2: ",data);

      await storeUserData({ ...data, email: user_email });
      await readUserData();
      await updateInstructions();

      if (userRegistered) {
        usecode = false;
        migration = false;
        dispatch("message", { text: $t("page_settings.registerSuccess") });

        await checkAccountActive();
      } else {
        dispatch("message", {
          text: $t("page_settings.registerFailed1"),
        });
      }
    } else {
      if (reply.status >= 500) {
        st_connected.set(false);
        dispatch("message", {
          text: $t("page_settings.offline"),
        });
      } else {
        const msg = await reply.text();
        dispatch("message", {
          text: $t("page_settings.registerFailed2") + msg,
        });
      }
    }
    busy = false;
  }; //registerUser()

  let accountStatus = "unknown";
  let userGroup = "unknown";
  let loginExpires = "unknown";
  function takebackcontrol(event)
  {
    usecode = false;
    migration = false;
  }

  export const checkAccountActive = async () => {
    accountStatus = "unknown";
    userGroup = "unknown";
    loginExpires = "unknown";

    if (auth.userID < 1) {
      return;
    }

    const reply = await fetch(URI_API("accountStatus"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(auth),
    });

    if (reply && reply.ok) {
      const data = await reply.json();

      // // console.log('user status');
      // // console.log(data);
      if ("status" in data && "group" in data) {
        data.expires = await toDecrypt(data.expires);
        data.group = await toDecrypt(data.group);
        data.status = await toDecrypt(data.status);

        // // console.log("data: ",data);

        accountStatus = data.status;
        userGroup = data.group;
        loginExpires = dateStr(data.expires);

        if (!data.status.includes("active")) {
          storeUserData({ token: "extern", instructions: [] });
          st_userRegistered.set(false);
          // // console.log('set as unregisterd');
        }
      }
    }
  }; //checkAccountActive()

  const requestReInvite = async () => {
    if (!user_email) {
      dispatch("message", {
        text: $t("page_settings.reinvite"),
      });
      return;
    }

    if (busy) {
      return;
    }
    busy = true;

    const reply = await fetch(domainDash + "be/reinviteme/" + user_email).catch(
      (err) => {
        // console.log(err);
      }
    );

    if (reply && reply.ok) {
      const userEmail = user_email;
      dispatch("message", {
        text: $t("page_settings.invite") + user_email + ".",
      });
      await clearUserData();
      setTimeout(() => {
        (user_email = userEmail), (user_code = "");
      }, 500);
    } else {
      dispatch("message", {
        text: $t("page_settings.inviteFailed") + user_email + $t("page_settings.failed"),
      });
    }

    busy = false;
  }; //requestReInvite()

  //=>

  async function disabletoken(tokenIdToBeDeleted)
    {
      
        let reqJson = {'tokenId':tokenIdToBeDeleted}
       // console.log("Delete token ",tokenIdToBeDeleted);
        const reply = await fetch(URI_API("disableToken"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqJson),
        });

        if(reply.ok)
        {
            let data = await reply.json();
          // console.log("Logout successfully");
        }
    }

    export const resetRegistration = async () => {
      await clearUserData();
    let tokenId  = await token_id;
    await disabletoken(tokenId);
    await close(PAGE.check);
  }; //resetRegistration()

  // ========================= Swipe right to go back ==========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("page");

    // // console.log(swipeArea);

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        close(PAGE.check);
      }
    });
  }; //setupSwipeRight()

  // =========================== Construction / Init ===========================

  import { onMount, onDestroy } from "svelte";
  import App from "./App.svelte";

  onMount(async () => {
    setupSwipeRight();
    checkAccountActive();
  }); //onMount()

  async function close(nextPage = "") {
    dispatch("close", { page: nextPage });
  } //close()

  onDestroy(async () => {
    // Save email address of unregistered user
    if (!userRegistered && user_email_valid) {
      storeUserData({ email: user_email });
      st_email.set(user_email);
    }
  }); //onDestroy()

  let migration=false;
  let usecode= false;
  function usemigrationcode() {
   usecode = false;
   migration = true;
  }
  function usetokencode() {
    usecode = true;
    migration = false;
  }
  function backbutton()
  {
    migration = false;
    usecode = false;
  }
  function use_code_func(event)
  {
    // console.log(event.detail);
    user_code = event.detail.user_code;
    requestUserRegistration();
  }
  function use_migration_code_func(event){
    // console.log(event.detail.user_code);
    user_code = event.detail.user_code;
    requestUserMigrationRegistration();
  }
</script>

<!-- =============================  H T M L  ============================= -->
{#if migration == false && usecode == false}
<div class="page" id="page" class:light={!theme} class:dark={theme}>
  <!-- 
    <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:textLight={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>

  <h2>{$t("page_settings.yourAccount")}</h2>

  {#if !permissionIDB}
    <p>
      {$t("page_settings.permissionIDB")}
    </p>
    <p>
      {$t("page_settings.siteDataPermission")}
    </p>

    <!-- {:else if userRegistered} -->
  {:else if auth.userID > 0}
    <div id="accountInfo" on:click={checkAccountActive}>
      {$t("page_settings.registeredName")}<br />
      <strong>{user_name}</strong><br />
      {$t("page_settings.registeredEmail")}<br />
      <strong>{user_email}</strong>.<br />
      {$t("page_settings.accountStatus")}<br />
      <strong>{accountStatus}</strong> (group: {userGroup})<br />
      {#if accountStatus.includes("active")}
        {$t("page_settings.regExp")}<br />
        {loginExpires}
      {/if}
    </div>
    <div class="cont-row">
      <button
        class="btn"
        on:click={resetRegistration}
        class:btnDark={theme}
        title="Delete/Reset Registration"
      >
        {$t("page_settings.deleteRegistration")}
      </button>
    </div>
    <br />
    <!-- <div class="cont-row">
      <button
        class="btn"
        on:click={requestReInvite}
        title="Request New Invitation"
      >
        Get new invitation email
      </button>
    </div> -->
  {:else}
    <div class="input-txt" class:textLight={!theme} class:dark={theme}>
      <div class="cont-row" >
        <MailIcon size="2x" />
        <label for="txt-email">{$t("page_settings.email")}</label>
      </div>
      <div class="cont-row">
        <input
          id="txt-email"
          bind:value={user_email}
          on:input={validateEmail}
          type="email"
          class:lightInput={!theme} class:darkInput={theme}
          placeholder={$t("page_settings.emailPlaceholder")
}          autocomplete="email"
        />
      </div>
    </div>

    <div
      class="registration-call"
      style="border-radius:10px;margin-top:1rem;"
      class:lightBox={!theme} class:darkBox={theme}
    >
      <div
        class="registration-using-code"
        style="padding:1rem; display:flex;flex-direction:row;justify-content:space-between;"
      >
        <div class="cont-row" class:textLight={!theme}>
          <KeyIcon size="2x" />
          <label for="txt-code" class:textLight={!theme} 
            >{$t("page_settings.regCode")}</label
          >
        </div>
        <div
          style="color:white;background-color:var(--PG-blue);padding:1px; padding: 0px 5px;font-size: 1.5rem;"

          on:click={usetokencode} >
          &gt
        </div>
      </div>
      <div class="borderline" style="border-bottom:1px solid gray;"></div>
      <div class="registration-using-migration">
        <p
          style="padding :1rem;text-decoration:underline;margin-top:0px;"
          class:textLight={!theme} 
          on:click={usemigrationcode}
        >
        {$t("page_settings.migCode")}
        </p>
      </div>
    </div>
    {/if}
  </div>
    {:else if migration == false && usecode == true}
   <Child_page_registraion  migration ={false} on:user_code={use_code_func} on:takeback = {takebackcontrol} bind:theme={theme}/>
    
    {:else if migration == true && usecode == false}
    <Child_page_registraion  migration ={true} on:user_code={use_migration_code_func} on:takeback = {takebackcontrol} bind:theme={theme} />

 
    {/if}



<!--
<p>
  {$t("page_settings.emailAndCode")}
</p>

<div class="input-txt">
  <div class="cont-row">
    <KeyIcon size="2x" />
    <label for="txt-code">{$t("page_settings.code")}</label>
  </div>
  <div class="cont-row">
    <input
      id="txt-code"
      bind:value={user_code}
      autocomplete="off"
      type="text"
      placeholder= {$t("page_settings.inviteCode")}
    />

    <button
      id="btn-register"
      on:click={requestUserRegistration}
      title="Register"
    >
      {$t("page_settings.register1")}
    </button>
  </div>
</div>
<p>
  Do you have a migration code ? <a href="#">ENTER</a>
</p>
<div class="input-txt">
  <div class="cont-row">
    <KeyIcon size="2x" />
    <label for="txt-code">Code</label>
  </div>
  <div class="cont-row">
    <input
      id="txt-code"
      bind:value={user_code}
      autocomplete="off"
      type="text"
      placeholder= "Migration Code (Optional)"
    />

    <button
      id="btn-register"
      title="Register"
    >
      {$t("page_settings.register1")}
    </button>
  </div>
</div> -->
<!-- on:click={requestUserRegistration} -->



    
<!-- ============================  S T Y L E  ============================ -->

<style>
  p {
    margin-top: 2em;
  }

  #accountInfo {
    line-height: 150%;
    margin-top: 2em;
    margin-bottom: 4em;
  }

  .input-txt {
    margin-top: 1em;
    width: 100%;
    text-align: left;
  }
  .input-txt label {
    margin-left: 10px;
    line-height: 30px;
  }

  .input-txt input {
    line-height: 1.4em;
    width: 100%;
    font-size: 1.4em;
    padding: 0.2em 0.6em 0.2em 0.6em;
    border-radius: 50vh;
  }
  #txt-code {
    border-radius: 50vh 0px 0px 50vh;
    flex: 2;
  }
  #btn-register {
    /* flex: 1; */
    color: white;
    font-size: 1em;
    background-color: var(--PG-blue);
    width: 25%;
    min-width: 100px;
    border-radius: 0px 50vh 50vh 0px;
    border: none;
    height: 2.65em;
    margin-left: -1px;
    border: none;
  }

  #btn-back {
    position: absolute;
    margin-top: -4%;
    border: none;
    background: none;
  }
  .light{
    background-color: var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #242424;
  }
  .textLight{
    color: var(--PG-blue);
  }
  .lightInput{
    border: 1px solid var(--PG-blue);
  }
  .darkInput{
    border: 1px solid #ffffff;
    color: #fff;
    background-color: #242424;
  }
  .btnDark{
    background-color: #000000;
    border-color: #ffffff;
    color: #ffffff;
  }
  .lightBox{
    background-color:#fff;
  }
  .darkBox{
    background-color:rgb(102, 97, 97);
    color: #fff;
  }
</style>
