<!--
	genuinePG frontend

	Modal, opened from Page_check

    Reads barcodes from camera video stream

    @file         Modal_scanEAN.svelte
    @copyright    P&G
-->

<script>
  import { eanScanAutoAcceptLevel, scanbotBarcodeFormats, scanbotLicenceKey } from "./config";
  import { ArrowLeftCircleIcon, VideoIcon, CameraIcon, ChevronDownIcon, ChevronUpIcon } from "svelte-feather-icons";
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from "./i18n"
  export let theme
  let showDownwardDrpdwnIcon = true;
  let showUpwardDrpdwnIcon = false;
  // ============================== Barcode scan ===============================

  import { storeUserData, scanbotSDK } from "./minions";
  import { st_videoDevices, st_selectedVideoDeviceID } from "./stores";

  let videoDevices;
  st_videoDevices.subscribe((value) => {
    videoDevices = value;
  });

  let selectedVideoDeviceID;
  st_selectedVideoDeviceID.subscribe((value) => {
    selectedVideoDeviceID = value;
  });

  const selectCamera = async (deviceID) => {
    // User selects camera by button
    if (deviceID) {
      selectedVideoDeviceID = deviceID;
      await Quagga.stop();

      try {
        initQuagga();
      } catch (error) {
        // console.log(error);
      }

      try {
        storeUserData({ selectedVideoDeviceID: selectedVideoDeviceID });
      } catch (error) {
        // console.log(error);
      }

      return;
    }

    // Get camera devices at first use (stored in user data)
    if (!selectedVideoDeviceID) {
      // List all camera devices
      try {
        const allMediaDevices = await navigator.mediaDevices.enumerateDevices();
        // // console.log("allMediaDevices: ",allMediaDevices);

        let videoDeviceInfos = allMediaDevices.filter((item) => {
          return item.kind === "videoinput";
        });
        // Filter for back cameras
        let videoDevicesBack = videoDeviceInfos.filter((item) => {
          return item.label.includes("back");
        });

        if (videoDevicesBack.length > 0) {
          videoDeviceInfos = videoDevicesBack;
        }

        videoDevices = [];
        videoDeviceInfos.forEach((item) => {
          videoDevices.push({ label: item.label, deviceId: item.deviceId });
        });

        if (videoDevices.length === 1) {
          selectedVideoDeviceID = videoDevices[0].deviceId;
        } else if (videoDevices.length > 1) {
          // Get backfacing camera by requesting 'environment' facing stream
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "environment" },
          });
          selectedVideoDeviceID = stream.getCapabilities().deviceId;
          stream.getTracks().forEach((track) => track.stop());
        } else {
          selectedVideoDeviceID = undefined;
        }

        storeUserData({
          videoDevices: videoDevices,
          selectedVideoDeviceID: selectedVideoDeviceID,
        });
      } catch (error) {
        // console.log(error);
      }
    }
  }; //selectCamera()

  // ============================== Barcode scan ===============================

  import Quagga from "@ericblade/quagga2";

  let ean = undefined;
  let scanResults = {};

  const initQuagga = () => {
    Quagga.init(
      // configure Barcode scanner and video input
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: document.querySelector("#bcScanner"),
          constraints: {
            width: { min: 480, ideal: 480 },
            height: { min: 640, ideal: 640 },
            deviceId: selectedVideoDeviceID,
            // facingMode: "environment"
            // aspectRatio: {min: 1, max: 2}// NOT working with Safari!
          },
          area: {
            top: "10%",
            right: "10%",
            left: "10%",
            bottom: "10%",
          },
        },
        locate: true,
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        decoder: {
          readers: ["ean_reader", "i2of5_reader", "code_128_reader"],
          multiple: false,
        },
      },
      (err) => {
        // start scanner after configuration (err only if failed)
        if (err) {
          return;
        }
        let video = document.querySelector("video");
        video.style.width = "100%";
        video.style.objectFit = "contain";
        let videoBox = document.getElementById("videoBox");
        videoBox.style.maxHeight = video.offsetHeight;

        scanResults = {};

        Quagga.start();

        Quagga.onDetected(function (result) {
          if (!(result.codeResult && result.codeResult.code)) {
            return;
          }

          const readEAN = result.codeResult.code;

          if (readEAN in scanResults) {
            scanResults[readEAN]++;
          } else {
            scanResults[readEAN] = 1;
          }
          // Get most detected code as ean
          ean = Object.keys(scanResults).reduce((a, b) =>
            scanResults[a] > scanResults[b] ? a : b
          );

          if (
            eanScanAutoAcceptLevel > 0 &&
            scanResults[ean] >= eanScanAutoAcceptLevel
          ) {
            closeModal();
          }
        }); //Quagga.onDetected()
      }
    ); // start Quagga -> barcode scan
  }; //initQuagga()

  // ========================== Confirm or Reject Scan =========================

  const rescan = async () => {
    // scanResults = {};
    ean = undefined;
    await start();
  }; //rescan()

  const acceptScan = () => {
    if (!ean) {
      return;
    }

    closeModal();
  }; //acceptScan()
  

  const configuration = {
    barcodeFormats: scanbotBarcodeFormats,
    style: {
      hint: $t("scanEAN.align")
    },
    //  The `id` of the containing HTML element where the Barcode Scanner will be initialized.
    containerId: "scanner",
    onBarcodesDetected: (result) => {
      // If you're happy with the result, dispose the scanner right away
      scanner.dispose();
      // Otherwise the scanner will continue scanning and delivering results
      const format = result.barcodes[0].format; // The barcode's symbology
      const text = result.barcodes[0].text; // The text value of the barcode
      // ...
      ean = text;
      // closeModal();
    },
  };
  let scanner;
  let currIndex=0;
  let cameras = [];
  let deviceID = 0;
  let deviceLabel = '';
  let isCamIdMatch = false;
  let camMatchId = 0;
  let camMatchLabel = '';
  async function start() {
    // console.log("--------SCANBOT STARTED--------");
    // const scanbotSDK = await ScanbotSDK.initialize({
    //     licenseKey: scancotLicenceKey,
    //     barcodeFormats: scanbotBarcodeFormats
    // });
    
    scanner = await scanbotSDK.createBarcodeScanner(configuration);

    // --------------------- for camera options ----------------------
    cameras = await scanner.fetchAvailableCameras();
    // // console.log("cameras: ",cameras);
    cameras.forEach((camId) => {
      if(camId.label == "camera2 0, facing back"){
        isCamIdMatch = true;
        camMatchId = camId.deviceId;
        camMatchLabel = camId.label;
      }
    })

    if(isCamIdMatch){
      scanner?.switchCamera(camMatchId,false);
      deviceID = camMatchId;
      deviceLabel = camMatchLabel;
    }
    // else{
    //   // console.log("inside else");
    //   scanner?.switchCamera(cameras[0].deviceId,false);
    //   deviceID = cameras[0].deviceId;
    //   deviceLabel = cameras[0].label;
    // }
  }

  // async function nextCamera() {
  //   if(currIndex==cameras.length-1){
  //     currIndex=0;
  //   }
  //   else{
  //     currIndex++;
  //   }
    
  //   scanner?.switchCamera(cameras[currIndex].deviceId,false);
  //   deviceID = cameras[currIndex].deviceId;
  //   deviceLabel = cameras[currIndex].label;
  // }

  // =========================== Construction / Init ===========================

  import { onMount, onDestroy } from "svelte";

  onMount(async () => {
    // await initQuagga();
    // await selectCamera();
    await start();
  }); //onMount()

  const closeModal = () => {
    // Quagga.stop();
    // scanResults = {};
    scanner.dispose();
    dispatch("close", { ean: ean });
  }; //close()

  const displayCameraList = () => {
    // document.getElementById("cameraDropdown").classList.toggle("show");
    let currView=document.getElementById('cameraDropdown').style.getPropertyValue('display').toLowerCase();
    document.getElementById('cameraDropdown').style.removeProperty('display');
    document.getElementById('cameraDropdown').style.setProperty('display',currView=='none' || currView=='' ?'block':'none');
    showDownwardDrpdwnIcon = currView=='none' || currView=='' ? false : true;
    showUpwardDrpdwnIcon = currView=='none' || currView=='' ? true : false;
  }

  const launchSelectedCam = (selectedCamId) => {
    // // console.log("selectedCamId: ",selectedCamId);
    scanner?.switchCamera(selectedCamId,false);
    document.getElementById('cameraDropdown').style.setProperty('display','none');
    showDownwardDrpdwnIcon = true;
    showUpwardDrpdwnIcon = false;
  }
 
  onDestroy(async () => {
    // Quagga.stop();
  }); //onDestroy()

  function setFocusToTextBox() {
    document.getElementById("bcScanner").focus();
  }
</script>

<!-- =============================  H T M L  ============================= -->

<div class="modal" class:light={!theme} class:dark={theme}>
  <div id="frame" >
    <div style="background-color: #00468C; min-height:2.7em;padding-top:0.45em;">
    <div style="position: relative;">
      <button id="btn-close" on:click={closeModal} title="Back">
        <ArrowLeftCircleIcon style="height:10%;width:auto; position: fixed;" />
      </button>
    </div>
      <div class="cam-dropdown">
        <div on:click={displayCameraList}>
          <div style="float:left">
          <button id="btn-camera" class="cam-dropbtn"> 
            <CameraIcon style="height:10%;width:auto" />
          </button>
        </div>
        <div style="float:right">
          <button class="arrow-icon">
            {#if showDownwardDrpdwnIcon}<ChevronDownIcon style="height:5%;width:auto;padding-top:5px" strokeWidth="4"/>{/if}
            {#if showUpwardDrpdwnIcon}<ChevronUpIcon style="height:5%;width:auto;padding-top:5px" strokeWidth="4"/>{/if}
          </button>
        </div>
        </div>
        <div id="cameraDropdown" class="cam-dropdown-content">
          <a href="javascript:void(0)" class="defaultSelection">{$t("scanEAN.camera")}</a>
          <hr/>
          {#each cameras as cam, i}
            <a href="javascript:void(0)" on:click={launchSelectedCam(cam.deviceId)} class="cameraOptions">
              <CameraIcon size="1.5x"/>
              <span style="margin-left: 15px;">{cam.label}</span>
            </a>
          {/each}
        </div>
      </div>
    
      <!-- <button id="btn-next"
        title="Next Camera"
        on:click={() => nextCamera()}>
        Next Camera
      </button> -->
    </div>
    <!-- <div style="width: 100%;float:left;">
      <span><b>Device ID:</b> {deviceID}  <b>Device Label:</b> {deviceLabel}</span>
    </div> -->
    <div id="scanner" class:light={!theme} class:dark={theme}>
      {#if ean}
        <h3>GTIN: {ean}</h3>
        <div class="btn-row">
          <div class="btn"  on:click={rescan}>{$t("scanEAN.rescan")}</div>
          <div class="btn" on:click={acceptScan}>{$t("scanEAN.ok")}</div>
        </div>
      {:else}
        <h3>{$t("scanEAN.scan")}</h3>
      {/if}
    </div>
  </div>
</div>

<!-- <div class="modal">
    <div id="frame">

        <button id="btn-close" on:click={closeModal} title="Back">
            <ArrowLeftCircleIcon size='2.5x' />
        </button>

        <div id="result">
            {#if ean}
                <h3>GTIN: {ean}</h3>
                <div class="btn-row">
                    <div class="btn" on:click={(rescan)}>
                        Rescan
                    </div>
                    <div class="btn" on:click={acceptScan}>
                        OK
                    </div>
                </div>

            {:else}
                <h3>Scanning for barcode ...</h3>
            {/if}
        </div>

        <div id="videoBox">
            <div id="bcScanner" on:load={setFocusToTextBox}/>
        </div>

        {#if videoDevices.length > 1}
            <div class="btn-row small-btn">
                {#each videoDevices as cam}
                <button class="btn" on:click={()=> selectCamera(cam.deviceId)} title="Select camera">
                    <VideoIcon size="1x" />
                    {cam.label}
                </button>
                {/each}
            </div>
        {/if}

    </div>
</div> -->

<!-- ============================  S T Y L E  ============================ -->

<style>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 214, 218, 0.8);
    display: block;
    z-index: 51;
  }

  #frame {
    margin-top: 20%;
    display: block;
    width: 100%;
    overflow: hidden;
  }

  #frame h3 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  #videoBox {
    width: 100%;
    height: 20em;
    overflow: hidden;
    display: block;
    background-color: white;
    position: relative;
  }
  .btn{
    display: flex;
    font-size: 1em;
    border-radius: 50vh;
    border: 1px solid var(--PG-blue);
    background-color: var(--PG-blue);
    color: #fff;
    height: 3em;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0.5em;
  }
  #btn-close {
    float: left;
    /* margin-top: 2em; */
    margin-left: 0.5em;
    color: white;
    border: none;
    background: none;
  }

  #result {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: white;
  }

  .btn-row {
    display: flex;
    flex-direction: row;
  }

  .btn-row.small-btn {
    font-size: 0.8em;
  }

  #scanner {
    width: 100%;
    height: 550px;
    padding-bottom: 1em;
  }

  #btn-next {
    color: white;
    font-size: 1em;
    background-color: var(--PG-blue);
    width: 25%;
    min-width: 115px;
    border-radius: 50vh 50vh 50vh 50vh;
    height: 2.65em;
    border: none;
    margin-right: 0.5em;
    float: right;
  }

  #btn-camera {
    color: white;
    border: none;
    background: none;
    /* margin-right: 8%;
    float: right; */
  }

  .cam-dropbtn {
  background-color: #3498DB;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.cam-dropbtn:hover, .cam-dropbtn:focus {
  background-color: #2980B9;
}

.cam-dropdown {
  position: relative;
  display: inline-block;
  float: right;
  margin-right: 0.3rem;
}

.cam-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100vw;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 2.1rem;
  right: -0.3rem;
}

.cam-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.cam-dropdown a:hover {background-color: #ddd;}

/* .show {display: block;} */
.defaultSelection {
  text-align: left;
  font-weight: bold;
}
.cameraOptions {
  margin-left: 25px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
}

.arrow-icon {
  color: white;
  border: none;
  background: none;
  vertical-align: super;
}
.light {
    background-color: #fff;
    color: #242424;
  }
  .dark{
    color: #fff !important;
    background-color: #242424;
  }
</style>
