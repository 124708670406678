<!-- =============================  SCRIPT  ==============================-->
<script>
  import { PAGE } from "./config";
  import { ArrowLeftCircleIcon } from "svelte-feather-icons";
  import { URI_API, dateStr, toEncrypt, toDecrypt, readVersion } from "./minions";
  import FetchWorker from "web-worker:./fetchWorker";
  import { t } from "./i18n"
  export let theme;
  import {
    st_auth,
    st_userRegistered,
    st_instructions,
    st_token_device_details,
  } from "./stores";
  import { beforeUpdate, createEventDispatcher } from "svelte";
  import { st_token_id, st_token_details, st_user_id } from "./stores";
  import { onMount, onDestroy, afterUpdate } from "svelte";
  let dispatch = createEventDispatcher();
  let versionStr = "v0.0.0";
 let showLoader = true;
  let code = "";
  let intervalid;
  let startTime = 58;
  let timer = 0;
  let copyTextDom = document.querySelector("#copycode");
  let worker;
  import { clearUserData } from "./minions";
  import { st_email } from "./stores";

  const close = async (nextPage = 0) => {
    // console.log("jhgfrdhuytrytuiokiouytuiftgyhuj");
    dispatch("close", { page: nextPage });
  };

  let user_email;
  st_email.subscribe((value) => {
    user_email = value;
  });
  let auth;
  st_auth.subscribe((value) => {
    auth = value;
  });

  let token_details;
  st_token_details.subscribe((value) => {
    token_details = value;
  });
  let token_id;
  st_token_id.subscribe((value) => {
    token_id = value;
    // console.log("Hi token id generated ", value);
  });
  let user_id;
  st_user_id.subscribe((value) => {
    user_id = value;
  });

  let device_details;
  st_token_device_details.subscribe((value) => {
    device_details = value;
  });

  // copyTextDom.addEventListener('click',()=>{
  //   document.execCommand("copy",true,copyTextDom.textContent);
  // })
  export const resetRegistration = async () => {
    let email = user_email;
    await clearUserData();
    st_email.set(email);
    // console.log("dterfgyuhigtfrdfgyuhigtfrdesdtfyguhjhygtfr");
    await close(PAGE.check);
  };

  async function generateCode() {
    code = Math.floor(100000 + Math.random() * 900000);
    // console.log("generated code : ", code);

    let generatebutton = document.querySelector(".generatebtn");
    generatebutton.disabled = true;
    generatebutton.style.background = "gray";
    document.querySelector(".timer").style.display = "block";
    await requestToStoreToken();
    worker = new FetchWorker();
    worker.onmessage = async (event) => {
      timer = event.data;
      if (timer < 50000) {
        // console.log("timer ", timer);
        makeEnable();
        resetRegistration();
      } else {
        //   resetMigrateToken()
        code="";
       await requestToStoreToken()
        // console.log("timer ", timer);
      }
    };
    let tokenId = await token_id;
    // console.log("data we got ,", tokenId);
    worker.postMessage({ token_id: tokenId, token_storage: code });

    intervalid = setInterval(() => {
      if (startTime >= 10) {
        document.querySelector(".timer").innerHTML = `00:${startTime}`;
      } else {
        document.querySelector(".timer").innerHTML = `00:0${startTime}`;
      }
      if (startTime == 0) {
        makeEnable();
      }
      startTime--;
    }, 1000);
  }
  async function  doCopy() {
    const copyText = document.getElementById("copycode").innerText;
    // console.log('copy Text ',copyText);
    document.querySelector('.msg').style.display = "block"
    setTimeout(() => {
      document.querySelector('.msg').style.display = "none"
    }, 2000);
    await navigator.clipboard.writeText(copyText)
        .then(() => {
            
        })
        .catch((error) => {
            console.error("Error copying text:", error);
        });
}
  function makeEnable() {
    clearInterval(intervalid);
    let generatebutton = document.querySelector(".generatebtn");
    generatebutton.disabled = false;
    code = "";
    startTime = 58;
    document.querySelector(".timer").innerHTML = "00:59";
    document.querySelector(".timer").style.display = "none";
    generatebutton.style.background = "var(--PG-blue)";
  }
  let edit = false;
  function callEdit() {
    edit = !edit;
  }
  const requestToStoreToken = async () => {
    let tokenId = await token_id;
    const reqbody = {
      token_storage: code,
      tokenID: tokenId,
    };
    const reply = await fetch(URI_API("storeToken"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqbody),
    });
    if (reply.ok) {
      const message = await reply.json();
      // console.log("Response Received ", message);
    }
  };
  // onDestroy()
  // {
  //     clearTokenIfExist();
  // }
  let data_to_be_render = [];
  let this_device = [];

  async function disabletoken(tokenIdToBeDeleted) {
   
    let tokenId = await token_id;
    let reqJson = { tokenId: tokenIdToBeDeleted };
    // console.log("Delete token ", tokenIdToBeDeleted);
    const reply = await fetch(URI_API("disableToken"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqJson),
    });

    if (reply.ok) {
      let data = await reply.json();
    //   // console.log(
    //     "HI iam deleting ",
    //     data,
    //     "HI iam deleting ",
    //     tokenId,
    //     "HI iam deleting ",
    //     tokenIdToBeDeleted
    //   );
      // if(parseInt(tokenId.trim()) === parseInt(tokenIdToBeDeleted.trim()))
      // {// console.log("Hi am deleting");
      //     await resetRegistration();
      // }
      // else{
      //     // console.log("HI I am deleting again : ",tokenId.length,"nffren ",tokenIdToBeDeleted.length);
      //      // console.log("HI iam deleting ", data,"HI iam deleting ",tokenId,"HI iam deleting ",tokenIdToBeDeleted);
      // }
      
      data_to_be_render = data_to_be_render.filter((obj) => {
        return obj.tokenId != tokenIdToBeDeleted;
      });
    }
  }

  onMount(async () => {
    versionStr = await readVersion();
    let reqJson = {
      user_id: await toEncrypt(user_id),
    };

    const reply = await fetch(URI_API("tokenDetails"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqJson),
    });
    if (reply.ok) {
      let data = await reply.json();

      data.forEach(async (value) => {
        let datares = await toDecrypt(value.token);
        const data_length = datares.length;
        datares = datares.slice(1, data_length - 1).split(",");
        // console.log("data res", Array.isArray(datares));
        let obj = {};
        // console.log("This is browser: ",datares[0].includes("Android"))
        // console.log("This is browser: ",datares[0].includes("Windows"))
        if (datares[0].includes("Android")) {
          obj.device = "Android";
        } else if (datares[0].includes("Windows")) {
          obj.device = "Windows";
        } else if (datares[0].includes("iPhone")) {
          obj.device = "IPhone";
        } else {
          obj.device = "Unknown Device";
        }
        obj.version = versionStr;
        obj.location = datares[3].trim().substring(1) + ', ' + datares[4].trim().slice(0,-1);
        // console.log("location is: ",obj.location)
        if(obj.location == 'unk, unk'){
          // console.log("unknown locationn")
          obj.location = "Location not enabled"
        }
        obj.tokenId = datares[2].trim();
        data_to_be_render.push(obj);
        // console.log("mhvbjhgjkhbk ", datares);
        if (parseInt(datares[2]) == parseInt(token_id)) {
          this_device.push(obj);
        }
      });
 
      this_device = this_device;
      data_to_be_render = data_to_be_render;
      st_token_device_details.set(data_to_be_render);
      setTimeout(() => {
       showLoader = false;
        this_device = this_device;
        data_to_be_render = data_to_be_render;
      }, 2000);
    }
  });
  onDestroy(async () => {
    // console.log("back call");
    makeEnable();
    // console.log("back call11");
    if (worker != undefined) {
      await worker.terminate();
    }
  });
</script>

<!-- =============================  H T M L  ============================= -->

<main>
<div class="page" class:light={!theme} class:dark={theme}>
  <div class="header">
      <button class="btn-back" class:textBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
        <ArrowLeftCircleIcon size="2.5x" />
      </button>
    {#if !edit && !showLoader}
      <div
        class="edit-done-button"
        class:textBlue={!theme}
        on:click={callEdit}
      >
        {$t("manageDevices.edit")}
      </div>
    {/if}

    {#if edit && !showLoader}
      <div
        class="edit-done-button"
        class:textBlue={!theme}
        on:click={callEdit}
      >
      {$t("manageDevices.done")}
      </div>
    {/if}
  </div>
  {#if !showLoader}
  <div class="main-body">
    <div class="mainheading" class:textBlue={!theme} class:dark={theme}>{$t("manageDevices.manageD")}</div>
    <div class="current-device-desc">
      <div class="this-device" class:textBlue={!theme} class:dark={theme}>{$t("manageDevices.thisDevice")}</div>
      {#if this_device.length > 0}
        {#each this_device as item}
          <div class="this-device-container" class:lightContainer={!theme} class:darkContainer={theme}>
            <div class="device-icon">
              <img src="/img/responsive.png" alt="" />
            </div>
            <div class="sub-desc">
              <div class="device-name">
                {item.device}
              </div>
              <div class="version">
                {item.version}
              </div>
              <div class="location">
                {item.location}
              </div>
            </div>
          </div>
        {/each}
      {/if}
    </div>
    <div class="current-device-desc">
      <div class="this-device" class:textBlue={!theme} class:dark={theme}>{$t("manageDevices.active")}</div>
      {#if data_to_be_render.length > 0}
        {#each data_to_be_render as item}
          <div class="this-device-container" class:lightContainer={!theme} class:darkContainer={theme}>
            {#if edit}
              <span on:click={disabletoken(item.tokenId)}
                ><img
                  src="/img/minus-button.png"
                  alt=""
                  style="width:1.2rem;padding-right:0.2rem;"
                /></span
              >
            {/if}
            <div class="device-icon">
              <img src="/img/responsive.png" alt="" />
            </div>

            <div class="sub-desc">
              <div class="device-name">
                {item.device}
              </div>
              <div class="version">
                {item.version}
              </div>
              <div class="location">
                {item.location}
              </div>
            </div>
          </div>
        {/each}
      {/if}
    </div>
    <div class="current-device-desc">
      <div class="this-device" class:textBlue={!theme} class:dark={theme}>{$t("manageDevices.move")}</div>
      <div class="device-code" class:textBlue={!theme} class:dark={theme}>
        {$t("manageDevices.unlink")}
      </div>
      <div class="generated-code" style="text-align: center;">
        {#if code}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div id="copycode"  on:click={doCopy}>{code}</div>
          <span class="msg" style="color: var(--PG-blue); display:none">Copied</span>
        {/if}
      </div>
      <div
        class="timer"
        style="display: none;color:var(--PG-blue);font-weight:800; text-align:center; margin:auto"
      >
        00:59
      </div>
      <div class="genearate-code-btn">
        <button class="btn generatebtn" on:click={generateCode}
          >{$t("manageDevices.generateCode")}</button
        >
      </div>
    </div>
  </div>
  {:else}
  <div class="loader" />
  {/if}

</div>
</main>

<!-- =======================================  CSS ==================================================-->
<style>
  .header {
    margin-top: 1.5rem;
  }
  
  /*.main-body {
    padding: 1.5rem;
  }
  .mainheading {
    text-align: left;
    font-size: x-large;
    color: var(--PG-blue);
    padding-bottom: 0.2rem;
    border-bottom: 0.1rem solid gray;
  }
  .this-device {
    color: black;
    font-weight: 700;
    text-align: left;
    font-size: 1.2rem;
    padding: 0.5rem 0;
  }
  .this-device-container {
    display: flex;
    flex-direction: row;
    padding: 0.2rem 0.5rem;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 0.5rem;
  }
  .device-code {
    text-align: left;
  }
  .sub-desc {
    text-align: left;
  }
  .device-icon {
    padding-right: 0.5rem;
  }
  .btn {
    width: 100%;
    color: white;
    background-color: var(--PG-blue);
  }
  .generated-code {
    padding: 0.5rem 0;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
  } */
  .btn {
    width: 100%;
    color: white;
    background-color: var(--PG-blue);
  }
    /* .loader {
    display: flex;
    position: relative;
    top: 30%;
    left: 45%;
    transform: translate(-50%, -50%);
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid var(--PG-blue);
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    /* animation: spin 1.5s linear infinite;
  } */

  /* Safari */
  /* @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */ 
  .generatebtn{
    background-color: var(--PG-blue);
  }
  .btn-back{
    border: none;
  }
  .light{
    background-color: var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #242424;
  }
  .textBlue{
    color: var(--PG-blue);
  }
  .lightContainer{
    background-color: #fff;
    color: #000000;
  }
  .darkContainer{
    background-color: rgb(94, 89, 89);
    color: #fff;
  }
</style>
