<!-- 
	genuinePG frontend

	Main frame of application, single site 

    @file         App.svelte
-->

<script>
  import { PAGE } from "./config";
  import { readUserData ,clearUserData} from "./minions";
  import {
    CloudOffIcon,
    MenuIcon,
    UploadIcon,
    UploadCloudIcon,
    PauseIcon,
    MapPinIcon,
    GlobeIcon,
    XCircleIcon,
    SunIcon,
    MoonIcon,
  } from "svelte-feather-icons";
  import P_check from "./Page_check.svelte";
  import P_reportNewFake from "./Page_reportNewFake.svelte";
  import P_locations from "./Page_locations.svelte";
  import P_managedevices from "./Page_managedevices.svelte"
  import P_settings from "./Page_settings.svelte";
  import P_about from "./Page_about.svelte";
  import P_help from "./Page_help.svelte";
  import P_termsAndCondition from "./Page_termsAndCondition.svelte";
  import P_dataPolicy from "./Page_dataPolicy.svelte";
  import M_menu from "./Modal_menu.svelte";
  import I_noGeo from "./Icon_noGeo.svelte";

  let activePage = PAGE.check;
  let showMenu = false;

  // ============================== Message Box ================================

  let messageVisible = false;
  let messageText = "";

  const showMessage = (evt) => {
    if (evt.detail.text) {
      messageText = evt.detail.text;
      messageVisible = true;
    }
  }; //showMessage()

  // ============================== Geo Location ===============================

  //TODO regularly check. make locationAvailable st_
  import {
    st_location_available,
    st_location_latitude,
    st_location_longitude,
    st_location_accuracy,
  } from "./stores";

  //  Set in startLocationService()
  let location_watchID;

  let location_available = false;
  // st_location_available.subscribe(value => {
  // 	location_available = value;
  // });

  const startLocationService = () => {
    if (!navigator.geolocation) {
      return;
    }
    // initial, rough position
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // // console.log(position)
        st_location_latitude.set(position.coords.latitude);
        st_location_longitude.set(position.coords.longitude);
        st_location_accuracy.set(position.coords.accuracy);
        location_available =
          Math.abs(position.coords.latitude) > 1 ||
          Math.abs(position.coords.longitude) > 1;
        st_location_available.set(location_available);
      },
      (err) => {
        messageText = $t("app.messageText1");
        messageVisible = true;
      },
      { enableHighAccuracy: true }
    );
    // continiously update position
    location_watchID = navigator.geolocation.watchPosition(
      (position) => {
        // // console.log('geo watcher')
        // // console.log(position)
        st_location_latitude.set(position.coords.latitude);
        st_location_longitude.set(position.coords.longitude);
        st_location_accuracy.set(position.coords.accuracy);
        location_available =
          Math.abs(position.coords.latitude) > 1 ||
          Math.abs(position.coords.longitude) > 1;
        st_location_available.set(location_available);
      },
      (err) => {
        // console.log(err);
        // Don't show message, as it comes up repeatedly
      },
      { maximumAge: 3600, enableHighAccuracy: true }
    );
  }; //startLocationService()

  const endLocationService = () => {
    if (location_watchID) {
      navigator.geolocation.clearWatch(location_watchID);
    }
  }; //endLocationService()

  // ======================= Stored Upload / Connectivity ======================

  import { checkConnectionInterval_ms } from "./config";
  import {createEventDispatcher} from 'svelte';
  import {
    st_permissionIDB,
    st_userRegistered,
    st_connected,
    st_dbImages,
    st_waitingUploads,
    st_uploadRunning,
    st_pauseUpload,
    st_dbPlaces,
  } from "./stores";
  import {
    openDbImages,
    packageUpload,
    togglePauseUpload,
    serverReachable,
    updateInstructions,
    openDbPlaces,
    readStoredPlaces,
  } from "./minions";

  let permissionIDB;
  st_permissionIDB.subscribe((value) => {
    permissionIDB = value;
  });

  let userRegistered;
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
  });

  let connected = true;
  let tokenIdExist = true;
  st_connected.subscribe((value) => {
    connected = value;
  });

  let dbImages;
  st_dbImages.subscribe((value) => {
    dbImages = value;
  });

  let numOfWaitingUploads = 0;
  st_waitingUploads.subscribe((value) => {
    numOfWaitingUploads = value;
  });

  let uploadRunning = false;
  st_uploadRunning.subscribe((value) => {
    uploadRunning = value;
  });

  let pauseUpload;
  st_pauseUpload.subscribe((value) => {
    pauseUpload = value;
  });

  let dbPlaces;
  st_dbPlaces.subscribe((value) => {
    dbPlaces = value;
  });

  // Interval set in onMount()
  let checkConnectionInterval;
  let checkTokenExistInterval;

  const checkConnection = async () => {
    // only check when app in foregound, i.e. active use
    if (document.visibilityState === "visible") {
      let pre = connected;

      connected = await serverReachable();
      st_connected.set(connected);

      if (!pre && connected) {
        tryPackageUpload();
      }
    }
  }; //checkConnection();
  document.addEventListener("ononline", checkConnection);
  document.addEventListener("onoffline", checkConnection);
  document.addEventListener("visibilitychange", checkConnection);

  let dispatch = createEventDispatcher();
    const close = async (nextPage = 0) => {
        // console.log("jhgfrdhuytrytuiokiouytuiftgyhuj ",nextPage);
        dispatch("close", { page: nextPage });
  };

    export const resetRegistration = async () => {
    let email = user_email;
    await clearUserData();
    st_email.set(email);
    // console.log("dterfgyuhigtfrdfgyuhigtfrdesdtfyguhjhygtfr");
    await close(PAGE.check);

  }; 
//   const checkTokenExist = async()=>{
//     // console.log("HI I AM CHECKING TOKEN ");
// if (document.visibilityState === "visible") {


//       let tokeIDExist = await tokenIdexist();
      
//       if(tokeIDExist == false)
//       {
//          resetRegistration();
//       }
//     }
//   }


  const tryPackageUpload = async () => {
    // upload if possible
    messageText = await packageUpload();
    if (messageText && messageText.length > 1) {
      messageVisible = true;
    }
  }; //tryPackageUpload()

  const continueUpload = () => {
    togglePauseUpload();
    tryPackageUpload();
  }; //continueUpload()

  // ============================= Page navigation =============================

  const closePage = (evt) => {
    if (evt.detail.page && evt.detail.page > 0) {
      activePage = evt.detail.page;
    }
    if (evt.detail.uploadPackage) {
      activePage = PAGE.check;
      tryPackageUpload();
    }
    // if (evt.detail.message) {
    // 	messageText = evt.detail.message;
    // 	messageVisible = true;
    // }
    showMenu = false;
  }; //closePage()

  const goHome = () => {
    location.reload();
  }; //goHome()

  //=========================== Multilingual support ===========================

  import { t, locale } from "./i18n";
  
  // import M_scanEAN, { updateConfig} from "./Modal_scanEAN.svelte"
  let lang;
  // const unsubscribe =
  locale.subscribe((val) => {
    lang = val.toUpperCase();
  });
  const displayLangList = () => {
    let currView = document
      .getElementById("langDropdown")
      .style.getPropertyValue("display")
      .toLowerCase();
    document.getElementById("langDropdown").style.removeProperty("display");
    document
      .getElementById("langDropdown")
      .style.setProperty(
        "display",
        currView == "none" || currView == "" ? "block" : "none"
      );
  };
  const closeModal = () => {
    document.getElementById("langDropdown").style.removeProperty("display");
  };
  const setLang = async(value, langToChange) => {
    locale.set(value);
    localStorage.setItem("userLang", value);
    locale.subscribe((val) => {
      lang = val.toUpperCase();
    });
    document.getElementById("langDropdown").style.removeProperty("display");
    document.getElementById(langToChange).style.color = "blue";
    let languges = ["en", "hi", "vi", "th", "es", "pt"];
    for (let i = 0; i < 6; i++) {
      if (langToChange != languges[i]) {
        document.getElementById(languges[i]).style.color = "black";
      }
    }
    document.dispatchEvent(new CustomEvent("langChanged", { detail: value }));
    
    // updateConfig();
  };
  // ============================ Dark Mode Toggle ===============================
  // import { theme } from "./stores";
  let theme;
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    theme = localStorage.getItem("theme") === "dark" ? true : false;
  }
  let showLogo = !theme;
  const toggleTheme = () => {
    // // console.log("Toggle theme: ", theme);
    // theme.set(!theme);
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      localStorage.setItem("theme", theme? 'light':'dark')
      theme = !theme;
    }
    if (theme) showLogo = false;
    else showLogo = true; 
  };

  // ========================== Construction / Init ============================

  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {st_instructions,st_token_id} from './stores';
  import FetchWorker from 'web-worker:./checkTokenExistWorker';

  let tokenId;
  st_token_id.subscribe((value)=>{
    tokenId  = value;
    // console.log("token value we got , ",value);
  })
 
  let instructions_loaded = false;
  st_instructions.subscribe((value) => {
    instructions_loaded = value && Object.keys(value).length > 1;
  });
  
 const resetRegistrationToken = async () => {
  await clearUserData();
  activePage = PAGE.check;
}
let worker,checkToken;
  async function tosetupTokenWorker(token_id)
{     if(worker != undefined) return;

       worker = new FetchWorker();
       worker.onmessage =async (event)=>{
           checkToken = event.data;
           if(checkToken == false)
           {
              await resetRegistrationToken();
           }    
       }
       // let tokenId=await token_id
       // // console.log("data we got ,",tokenId);
       worker.postMessage({token_id:token_id});
}

  onMount(async () => {
    // Chrome (and chromium) reports itself as user Agent  "..Chrome/xx Safari/xx.."
    if (navigator.userAgent.indexOf("Safari/") == -1) {
      messageText = $t("app.messageText2");
      messageVisible = true;
    }

    if (navigator.geolocation) {
      startLocationService();
    }

    if (window.indexedDB) {
      await readUserData();

      if (permissionIDB) {
        await openDbPlaces();
        readStoredPlaces();
      }

      if (userRegistered) {
        if (permissionIDB) {
          await openDbImages();
          tryPackageUpload();

          updateInstructions();
        } else {
          messageText = $t("app.messageText3");
          messageVisible = true;
        }
      }

      document.getElementById(lang.toLowerCase()).style.color = "blue";
    }

    checkConnection();
    checkConnectionInterval = setInterval(
      checkConnection,
      checkConnectionInterval_ms
    );
  }); //onMount()

  afterUpdate(async()=>{
    if (userRegistered && instructions_loaded)
    {  let token_id = await tokenId;
      // console.log("Hi Im calling this.fxdgchvjbkn");
      tosetupTokenWorker(token_id);
    }
    // console.log("user registaration , ",userRegistered);
    // console.log("token Id ,",instructions_loaded);
  })

  onDestroy(async () => {
    endLocationService();
    clearInterval(checkConnectionInterval);
    if (dbImages) {
      dbImages.close();
    }
    if (dbPlaces) {
      dbPlaces.close();
    }
  }); //onDestroy()
</script>

<!-- =============================  H T M L  ============================= -->
<main class:light={!theme} class:dark={theme}>
  <div class="topBar" class:light={!theme} class:dark={theme}>
    <button
      class="btn-home"
      class:visible={showLogo}
      class:hidden={!showLogo}
      title="P&G"
    >
      <img class="logo" src="/img/logo-PG.svg" alt="P&G" on:click={goHome} />
    </button>
    <div
      style="margin-right: 2.5em;"
      class="topBarRight"
      class:light={!theme}
      class:dark={theme}
    >
      <div
        style="display: flex;flex-direction: row;justify-content: flex-start;"
      >
        <button
          on:click={displayLangList}
          title="select-app-language"
          aria-label="select-app-language"
          class="lang-dropbtn"
          class:light={!theme}
          class:dark={theme}
        >
          <GlobeIcon strokeWidth="1.3" size="1.5x" />
        </button>
      </div>
      <div
        style="align-items: center;justify-content: center; flex-direction: row;display: flex;font-size: 1.2em; margin-right: 0.5em; "
        on:click={displayLangList}
        class:textBlue={!theme} class:dark={theme}
      >
        {lang}
      </div>
      <div id="indicators">
        {#if !location_available}
          <div class="icon" on:click={startLocationService} class:textBlue={!theme} class:dark={theme}>
            <I_noGeo />
          </div>
        {/if}
        {#if pauseUpload}
          <div class="icon" on:click={continueUpload} class:textBlue={!theme} class:dark={theme}>
            <PauseIcon />
          </div>
        {/if}
        {#if numOfWaitingUploads > 0}
          <div class="icon" on:click={tryPackageUpload} class:textBlue={!theme} class:dark={theme}>
            <UploadIcon />{numOfWaitingUploads}
          </div>
        {/if}
        {#if !connected}
          <div class="icon" on:click={checkConnection} class:textBlue={!theme} class:dark={theme}>
            <CloudOffIcon />
          </div>
        {/if}
        {#if uploadRunning}
          <div class="icon" class:textBlue={!theme} class:dark={theme}>
            <UploadCloudIcon />
          </div>
        {/if}
      </div>
      <button
        id="btn-menu"
        style="margin-right: -2.5em;"
        on:click={() => (showMenu = true)}
        title="Menu"
        class:textBlue={!theme} class:dark={theme}
      >
        <MenuIcon size="2.5x" />
      </button>
    </div>
  </div>
  <div class="lang-dropdown">
    <div
      id="langDropdown"
      class="lang-dropdown-content"
      class:light={!theme}
      class:darkDD={theme}
    >
      <div style="margin-top: 4%;">
        <div
          style="color: white; background-color: #00468C; display: flex; justify-content: space-between; align-items: center;"
        >
          <strong style="margin-left: 0.5em;">{$t("app.selectLang")}</strong>
          <div
            id="btn-close"
            class="backButton"
            on:click={closeModal}
            style="margin-right: 0.5em; margin-top: 1%"
            title="Back"
          >
            <XCircleIcon />
          </div>
        </div>
      </div>
      <hr />
      <a
        href="javascript:void(0)"
        style="text-align: left; font-weight: bold; margin-left: 15px;"
        on:click={() => {
          setLang("en", "en");
        }}
        class="langOptions"
        id="en">English (English)</a
      >
      <a
        href="javascript:void(0)"
        style="text-align: left; font-weight: bold; margin-left: 15px;"
        on:click={() => {
          setLang("hi", "hi");
        }}
        class="langOptions"
        id="hi">Hindi (हिंदी)</a
      >
      <a
        href="javascript:void(0)"
        style="text-align: left; font-weight: bold; margin-left: 15px;"
        on:click={() => {
          setLang("pt", "pt");
        }}
        class="langOptions"
        id="pt">Portuguese (Português)</a
      >
      <a
        href="javascript:void(0)"
        style="text-align: left; font-weight: bold; margin-left: 15px;"
        on:click={() => {
          setLang("es", "es");
        }}
        class="langOptions"
        id="es">Spanish (Español)</a
      >
      <a
        href="javascript:void(0)"
        style="text-align: left; font-weight: bold; margin-left: 15px;"
        on:click={() => {
          setLang("th", "th");
        }}
        class="langOptions"
        id="th">Thai (แบบไทย)</a
      >
      <a
        href="javascript:void(0)"
        style="text-align: left; font-weight: bold; margin-left: 15px;"
        on:click={() => {
          setLang("vi", "vi");
        }}
        class="langOptions"
        id="vi">Vietnamese (Tiếng Việt)</a
      >
    </div>
  </div>
  <div class="desk" class:grayDesk={!theme} class:dark={theme}>
    <div class="btn_container">
      <div class="btn_theme" class:light={!theme} class:dark={theme} >
        <div
          class="btn_indicator"
          class:light={!theme}
          class:dark={theme}
          on:click={toggleTheme}
        >
          <div class="btn_icon-container">
          </div>
        </div>
      </div>
    </div>
    {#if activePage == PAGE.check}
      <P_check
        on:close={closePage}
        on:message={showMessage}
        on:retryGeo={startLocationService}
        bind:theme={theme}
      />
    {:else if activePage == PAGE.reportNewFake}
      <P_reportNewFake
        on:close={closePage}
        on:message={showMessage}
        on:retryGeo={startLocationService}
        bind:theme={theme}
      />
    {:else if activePage == PAGE.locations}
      <P_locations
        on:close={closePage}
        on:message={showMessage}
        on:retryGeo={startLocationService}
        bind:theme={theme}
      />
    {:else if activePage == PAGE.settings}
      <P_settings on:close={closePage} on:message={showMessage} bind:theme={theme} />
    {:else if activePage == PAGE.managedevices}
      <P_managedevices on:close={closePage} on:message={showMessage} bind:theme={theme}/>
    {:else if activePage == PAGE.about}
      <P_about on:close={closePage} bind:theme={theme}/>
    {:else if activePage == PAGE.help}
      <P_help on:close={closePage} bind:theme={theme}/>
    {:else if activePage == PAGE.termsAndCondition}
      <P_termsAndCondition on:close={closePage} bind:theme={theme}/>
    {:else if activePage == PAGE.dataPolicy}
      <P_dataPolicy on:close={closePage} bind:theme={theme}/>
    {:else}
      <P_check on:close={closePage} on:message={showMessage} bind:theme={theme}/>
    {/if}
  </div>

  {#if showMenu}
    <M_menu on:close={closePage} bind:theme={theme}/>
  {/if}

  <!-- Full screen info box / message to user  -->
  {#if messageVisible && messageText && messageText.length > 1}
    <div class="message" on:click={() => (messageVisible = false)}>
      <div class="message-box">
        {messageText}
      </div>
    </div>
  {/if}
</main>

<!-- Generic document element for photo app picture taking -->
<input
  id="photoAppInput"
  type="file"
  accept="image/*"
  capture="environment"
  style="display:none;"
/>

<!-- ============================  S T Y L E  ============================ -->

<style>
  main {
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100vh;
  }

  .topBar {
    padding: 0.5em;
    background-color: white;
    box-shadow: 0px 5px 10px var(--txt-gray);
    position: fixed;
    top: 0px;
    color: var(--tF-blue);
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 1050;
  }
  .light {
    background-color: #fff;
    color: #242424;
  }
  .textBlue{
    color: var(--PG-blue);
  }
  .dark {
    color: #fff !important;
    background-color: #000000;
  }
  .grayDesk {
    background-color: var(--bg-gray);
    color: #242424;
  }
  .darkDD {
    color: #fff;
    background-color: #c5c2c2;
  }
  .hidden {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
  .topBar .logo {
    width: 95px;
    object-fit: contain;
    float: left;
  }
  .topBarRight {
    display: flex;
    flex-direction: row;
  }
  #indicators {
    display: flex;
    flex-direction: row;
    font-size: 1.2em;
    justify-content: center;
    align-items: center;
    /* color: #003da7; */
  }
  #indicators .icon {
    margin-right: 1em;
  }

  .desk {
    height: 100%;
    width: 100%;
  }
  #btn-menu {
    /* color: var(--PG-blue); */
    margin-right: 0.5em;
    border: none;
    background: none;
  }

  .btn-home {
    color: var(--PG-blue);
    margin-right: 0.5em;
    border: none;
    background: none;
  }
  .lang-dropbtn {
    margin: 2px;
    color: #003da7;
    font-size: 20px;
    border: none;
    cursor: pointer;
    width: 20%;
  }
  .lang-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    /* margin-right: 0.3rem; */
  }
  .backButton {
    cursor: pointer;
    /* color: white; */
    display: inline-block;
    /* margin-left: 20%; */
    /* background-color:003da7; */
  }
  .lang-dropdown-content {
    flex: 3;
    display: none;
    position: absolute;
    /* background-color: white; */
    width: 100%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 55;
    top: 2.1rem;
    /* right: -0.3rem; */
  }
  .lang-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .message-box {
    color: black;
  }
  .btn_theme {
    width: 4rem;
    height: 2rem;
    border-radius: 10em;
    padding: 0 0.5em;
    box-shadow:
      inset 0 8px 60px rgba(0, 0, 0, 0.1),
      inset 0 8px 8px rgba(0, 0, 0, 0.1),
      inset 0 -4px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .btn_theme .dark {
    transform: translateX(1.5rem);
  }
  .btn_indicator {
    background-color: #ffffff;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    padding: 2px;
    /* box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2); */
    transition: transform 0.3s ease;
  }
  .btn_icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1 1 1 1;
  }
  .btn_container {
    position: absolute;
    margin-top: 3.5rem;
    right: 0;
    margin-right: 5%;
    z-index: 25;
  }
</style>
