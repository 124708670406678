<script>
    export let migration;
    import {createEventDispatcher} from 'svelte';
    import { MailIcon, KeyIcon, ArrowLeftCircleIcon } from "svelte-feather-icons";
    export let theme;
    import { t } from './i18n';
    let user_code='';
     const dispatch = createEventDispatcher();
     function takeback()
     {
      dispatch("takeback",{
        migration:false,
        usecode:false
      })
     }
     function registerUserBypassingcode(){
      dispatch("user_code",{
        user_code:user_code
      })
     }
     function registerUserBypassingMigrationcode(){
      dispatch("user_code",{
        user_code:user_code
      })
     }

 </script>


<!-- ===========================================HTML================================================ -->

{#if migration == false}
    <div class="page" class:light={!theme} class:dark={theme}> 
      <button id="btn-back" title="Back" on:click={takeback} class:textLight={!theme} class:dark={theme}>
      <ArrowLeftCircleIcon size="2.5x" />
    </button>
  
    <h2 class:textLight={!theme}>{$t("page_reg.enterCode")}</h2>   

      <div class="input-txt">
        <div class="cont-row" class:textLight={!theme} class:dark={theme}>
          <KeyIcon size="2x" />
          <label for="txt-code" class:textLight={!theme} class:dark={theme}>{$t("page_reg.regCode")}</label>
        </div>
        <div class="cont-row">
          <input
            id="txt-code"
            class="txt-code"
            bind:value={user_code}
            autocomplete="off"
            type="text"
            placeholder= {$t("page_reg.inviteCode")}
            class:lightInput={!theme} class:dark={theme}
          />
  
          <button
            id="btn-register"
            on:click={registerUserBypassingcode}
            title="Register"
          >
            {$t("page_reg.register")}
          </button>
        </div>
      </div>
    </div>

{:else}

<div class="page" class:light={!theme} class:dark={theme}>  
  <button id="btn-back" title="Back" on:click={takeback} class:textLight={!theme} class:dark={theme}>
  <ArrowLeftCircleIcon size="2.5x" />
</button>

<h2 class:textLight={!theme}>{$t("page_reg.enterMig")}</h2>  
    <p class:textLight={!theme}>
      {$t("page_reg.enterMigGenerated")}
      </p>
      <div class="input-txt">
        <div class="cont-row" class:textLight={!theme} class:dark={theme}>
          <KeyIcon size="2x" />
          <label for="txt-code" class:textLight={!theme} class:dark={theme}>{$t("page_reg.migCode")}</label>
        </div>
        <div class="cont-row">
          <input
            id="txt-code"
            class="txt-code"
            bind:value={user_code}
            autocomplete="off"
            type="text"
            placeholder={$t("page_reg.migCode")}
            class:lightInput={!theme} class:dark={theme}
          />
  
          <button
            id="btn-register"
            on:click={registerUserBypassingMigrationcode}
            title="Register"
          >
          {$t("page_reg.register")}
          </button>
        </div>
</div>
</div>
{/if}


 <!-- ======================================== STYLE =========================================== -->

 
<style>
  p {
    margin-top: 2em;
  }

  #accountInfo {
    line-height: 150%;
    margin-top: 2em;
    margin-bottom: 4em;
  }

  .input-txt {
    margin-top: 1em;
    width: 100%;
    text-align: left;
    color: var(--PG-blue);
  }
  .input-txt label {
    margin-left: 10px;
    line-height: 30px;
  }

  .input-txt input {
    line-height: 1.4em;
    width: 100%;
    font-size: 1.4em;
    padding: 0.2em 0.6em 0.2em 0.6em;
    border-radius: 50vh;
    border: 1px solid var(--PG-blue);
  }
  .txt-code {
    border-radius: 50vh 0px 0px 50vh !important;
    flex: 2;
  }
  #btn-register {
    /* flex: 1; */
    color: white;
    font-size: 1em;
    background-color: var(--PG-blue);
    width: 25%;
    min-width: 90px;
    border-radius: 0px 50vh 50vh 0px;
    height: 2.65em;
    margin-left: -1px;
    border: none;
  }

  #btn-back {
    position: absolute;
    margin-top: -4%;
    border: none;
    background: none;
  }
  .light{
    background-color: var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #242424;
  }
  .textLight{
    color: var(--PG-blue);
  }
  .lightInput{
    background-color: #fff;
    color: #242424;
  }
</style>