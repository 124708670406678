/*
	genuinePG frontend

	Variables accessible on multiple pages

    @file         stores.js
    @copyright    P&G

*/

import { writable } from "svelte/store";
import {t} from "./i18n";
import {scanbotBarcodeFormats} from "./config";


// User data
export const st_auth = writable({ userID: 0, token: "extern" ,token_id:0});
export const st_username = writable("");
export const st_email = writable("");
export const st_instructions = writable([]);
export const st_userRegistered = writable(false);
export const st_permissionIDB = writable(true);
export const st_videoDevices = writable([]);
export const st_selectedVideoDeviceID = writable(undefined);
export const showAllFooter = writable(false);

// geolocation data, updated from watcher in App.svelte
export const st_location_available = writable(false);
export const st_location_latitude = writable(0);
export const st_location_longitude = writable(0);
export const st_location_accuracy = writable(0);

// product codes
export const st_ean = writable(undefined);
export const st_batch = writable("");

// Number of image packages stored for upload
export const st_connected = writable(true);
export const st_dbImages = writable(undefined);
export const st_waitingUploads = writable(0);
export const st_uploadRunning = writable(false);
export const st_pauseUpload = writable(false);

// Stored places
export const st_dbPlaces = writable(undefined);
export const st_places = writable({});
let address1, value, align ;
t.subscribe((val)=>{
  address1=val("store.address");
});
t.subscribe((val)=>{
  value=val("scanEAN.align");
});

//Stored token id
export const st_token_id = writable(undefined);
export const st_token_details = writable(undefined)
export const st_user_id = writable(undefined);


export const st_lastUsedPlace = writable({
  name: "P&G",
  latitude: 51.428286549999996,
  longitude: -0.9704032726365385,
  accuracy_m: 1,
  address: address1,
  image: "",
  active: true,
});

export const st_token_device_details = writable(undefined);
export let hint = writable(value)

// Dark Mode 
export const theme = writable(false);

