<!--
    genuinePG frontend

    Page, opened from Page_check

    Invited users can upload images of new suspect products

    @file         Page_reportNewFake.svelte
    @copyright    P&G
-->

<script>
  import { PAGE } from "./config";
  import { deepCopyObject, toEncrypt, toDecrypt } from "./minions";
  import { ArrowLeftCircleIcon } from "svelte-feather-icons";
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from "./i18n"
  import M_locationSelect from "./Modal_locationSelect.svelte";

  let activateSubmit = false;
  export let theme;

  // ============================= store variables =============================

  import {
    st_instructions,
    st_userRegistered,
    st_location_available,
    st_location_latitude,
    st_location_longitude,
    st_location_accuracy,
    st_ean,
    st_batch,
  } from "./stores";

  // instructinos
  let instructions;
  let instructions_reset;
  st_instructions.subscribe((value) => {
    instructions = deepCopyObject(value);
    instructions_reset = deepCopyObject(value);
  });
  // // console.log("instructions: ", instructions);

  // user management
  let userRegistered = false;
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
  });

  // geolocation
  let location_available;
  let location_latitude;
  let location_longitude;
  let location_accuracy;
  st_location_available.subscribe((value) => {
    location_available = value;
  });
  st_location_latitude.subscribe((value) => {
    location_latitude = value;
  });
  st_location_longitude.subscribe((value) => {
    location_longitude = value;
  });
  st_location_accuracy.subscribe((value) => {
    location_accuracy = value;
  });

  // product codes
  let ean;
  let ean_valid = false;

  const validateEAN = () => {
    if (!ean) {
      ean = "";
      ean_valid = false;
      return;
    }
    let str = ean;
    str = str.replace(/([^0-9]+)/gi, "");
    str = str.substring(0, 8);
    // console.log("EAN:", ean)
    // console.log("STR:", str)

    ean_valid = str.length == 8;
    // ean = str;
  }; //validateEAN()

  st_ean.subscribe((value) => {
    ean = value;
    validateEAN();
  });

  // batch codes
  let batch;
  let batch_valid = false;

  const validateBatch = () => {
    if (!batch) {
      batch = "";
      batch_valid = false;
      return;
    }

    let str = batch.toUpperCase(); //.substring(0, 12);

    str = str.replace(/\s/gi, "_"); // Whitespaces to undersores
    str = str.replace(/_+/gi, "_"); // only one underscore at a time
    str = str.replace(/([^A-Z0-9:_]+)/gi, "");

    batch_valid = str.length >= 4;
    batch = str;
  }; //validateBatch()

  st_batch.subscribe((value) => {
    batch = value;
    validateBatch();
  });

  // ============================ External Photo App ===========================

  import { reportImg_maxSideLength_px } from "./config";
  import { additionalImg_src, pictureFromExtPhotoApp } from "./minions";

  let activeItem;

  const extPhotoApp = async (item) => {
    activeItem = item;
    if (item == "added") {
      let nameNewItem = "image " + Object.keys(instructions).length;
      instructions[nameNewItem] = {
        title: nameNewItem,
        src: additionalImg_src,
        instruction: $t("page_reportNewFake.additionalImage"),
      };
      activeItem = nameNewItem;
    }

    const image = await pictureFromExtPhotoApp(
      reportImg_maxSideLength_px
    ).catch((err) => {
      // // console.log('Image capture failed');
      return;
    });

    // // console.log("image: ",image);
    if (image) {
      instructions[activeItem]["src"] = image ? image : additionalImg_src;
      instructions[activeItem]["done"] = true;
      checkAllDone();
    } else {
      dispatch("message", { text: $t("page_reportNewFake.fileNotSupported") });
    }
  }; //extPhotoApp()

  // =========================== Location selection ============================

  import { findAddressFromGeo } from "./minions";
  import { st_lastUsedPlace } from "./stores";

  let place;
  let showLocationSelect = false;

  let lastUsedPlace;
  st_lastUsedPlace.subscribe((value) => {
    lastUsedPlace = value;
  });

  const place_here = async () => {
    if (!location_available) {
      dispatch("retryGeo");
      return;
    }

    place = {
      name: "Current position",
      address: "",
      latitude: location_latitude,
      longitude: location_longitude,
      accuracy_m: location_accuracy,
      image: "",
      active: true,
    };

    const address = await findAddressFromGeo(
      location_latitude,
      location_longitude
    );
    place.address = address;
  }; //place_here()

  const locationSelected = (evt) => {
    if (evt.detail.place) {
      place = evt.detail.place;
    }

    showLocationSelect = false;
  }; //locationSelected()

  // ============================== Image upload ===============================

  import { addImgPakgToBrowserStorage, storeLastUsedPlace } from "./minions";

  let comment_investigator = "";
  let allDone = false;
  let submissionInProgress = false;

  const checkAllDone = () => {
    let done = true;
    const items = Object.keys(instructions);
    // // console.log("item in checkAllDone: ",items);
    // // console.log("instructions in checkAllDone: ",instructions);
    items.forEach((item) => {
      if (item == "batch") {
        // if a batch is given as string, a batch image is required
        if (batch && batch.length > 0) {
          done = done & instructions[item].done;
        }
      } else if (
        "optional" in instructions[item] &&
        !instructions[item].optional
      ) {
        done = done & instructions[item].done;
      }
    });

    //activate submit button when all the 3 mandatory images are uploaded
    if(instructions["GTIN"].done == true && instructions["backlabel"].done == true && instructions["batch"].done == true){
      activateSubmit = true;
    }
    allDone = done && ean_valid;
  }; //checkAllDone()

  const submitToDB = async () => {
    if (!allDone || submissionInProgress ) {
      return;
    }
   
    submissionInProgress = true;
    dispatch("message", {
      text: $t("page_reportNewFake.uploadMsg"),
    });
  
    // compile data package
    let imgSet = {};
    const items = Object.keys(instructions);
    items.forEach((item) => {
      if (instructions[item].done) {
        imgSet[item] = instructions[item].src;
      }
    });
    if (place.image) {
      imgSet["place_where_seen"] = place.image;
    }

    // // console.log("BATCH",batch)
    // // console.log("comment_investigator",comment_investigator)

    let imgPakg = {
      images: imgSet,
      ean: await toEncrypt(parseInt(ean, 10)),
      batch: batch ? await toEncrypt(batch.trim()) : "",
      comment_investigator: comment_investigator
        ? await toEncrypt(comment_investigator.trim())
        : "",
      latitude: await toEncrypt(place.latitude),
      longitude: await toEncrypt(place.longitude),
      location_accuracy: await toEncrypt(place.accuracy_m),
      timestamp: await toEncrypt(new Date().toISOString()),
      place_name: await toEncrypt(place.name),
    };
    //  // console.log("Data",imgPakg);
    //  // console.log("BATCH",imgPakg.batch)
    // // console.log("comment_investigator",imgPakg.comment_investigator)

    await addImgPakgToBrowserStorage(imgPakg);
    // // console.log('submittoDB-> added imgPakg in storage')

    await storeLastUsedPlace(place);
    // // console.log('submittoDB-> added place in storage')

    // Clean up, let App try image upload
    st_ean.set("");
    st_batch.set("");
    instructions = deepCopyObject(instructions_reset);
    submissionInProgress = false;
    dispatch("close", { uploadPackage: true });
  }; //submitToDB()

  // =========================== Construction / Init ===========================

  import { onMount, onDestroy } from "svelte";
  import { updateInstructions } from "./minions";

  onMount(async () => {
    if (!userRegistered) {
      close(PAGE.check);
    }

    // Set default location to last used location
    place = deepCopyObject(lastUsedPlace);

    updateInstructions();
  }); //onMount()

  function close(nextPage = "") {
    submissionInProgress = false;
    dispatch("close", { page: nextPage });
  } //close()

  onDestroy(async () => {}); //onDestroy()
</script>

<!-- =============================  H T M L  ============================= -->

<div class="page" class:light={!theme} class:dark={theme}>
  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:textWhite={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>

  <h2>{$t("page_reportNewFake.submitPhotos")}<br />{$t("page_reportNewFake.suspectProduct")}</h2>

  {#each Object.entries(instructions) as [item, details]}
    <div class="box-instruction" on:click={() => extPhotoApp(item)}>
      <button class="box-inst-img">
        <img src={details.src} alt={details.title} />
      </button>
      <div class="box-inst-text">
        {#if details.optional !== undefined} 
         {#if details.optional} <!-- In case of Receipt -->
          {#if details.title == "Receipt"}
           <h3>{$t("page_reportNewFake.titleReceipt")}</h3>
           <p>{$t("page_reportNewFake.instrReceipt")}</p>
           {/if}
         {:else}  <!-- In case of GTIN, backlabel, batch -->
          {#if details.title == "Bar code"}
            <h3><span style="color: red;">* </span>{$t('page_reportNewFake.titleGTIN')} <span style="color: red; font-size: small;">{$t("page_reportNewFake.required")}</span></h3>
            <p>{$t('page_reportNewFake.instrGTIN')}</p>
          {:else if details.title == "Batch code"}
            <h3><span style="color: red;">* </span>{$t('page_reportNewFake.titleBatch')} <span style="color: red; font-size: small;">{$t("page_reportNewFake.required")}</span></h3>
            <p>{$t('page_reportNewFake.instrBatch')}</p>
          {:else if details.title == "Back label"}
            <h3><span style="color: red;">* </span>{$t('page_reportNewFake.titleBackLabel')} <span style="color: red; font-size: small;">{$t("page_reportNewFake.required")}</span></h3>
            <p>{$t('page_reportNewFake.instrBackLabel')}</p>
          {/if}
         {/if}
        {:else} <!-- In case of any additional images -->
          <h3>{details.title}</h3>
          <p>{details.instruction}</p>
        {/if}
       </div>
    </div>
  {/each}

  {#if Object.keys(instructions).length <= 5 && allDone}
    <div class="box-instruction" on:click={() => extPhotoApp("added")}>
      <button class="box-inst-img">
        <img src={additionalImg_src} alt="Additional image" />
      </button>
      <div class="box-inst-text">
        <h3>{$t("page_reportNewFake.additionalImage1")}</h3>
        <p>{$t("page_reportNewFake.additionalImage2")}.</p>
      </div>
    </div>
  {/if}


  <label for="txt-comment" class:textWhite={!theme} class:dark={theme}>{$t("page_reportNewFake.comment")}</label>
  <textarea
    id="txt-comment"
    bind:value={comment_investigator}
    class="txt-comment"
    class:inputBox={!theme} class:gray={theme}
    rows="4"
    maxlength="300"
    placeholder={$t("page_reportNewFake.additionalImagePlaceholder")}
  />

  <label for="txt-batch" class:textWhite={!theme} class:dark={theme}>{$t("page_reportNewFake.checkBatchcode")}</label>
  <input
    id="txt-batch"
    class ="txt-batch"
    bind:value={batch}
    on:input={validateBatch}
    class:inputBox={!theme} class:gray={theme}
    type="text"
    placeholder={$t("page_reportNewFake.batchCode")}
  />

  <div class="label-div" class:textWhite={!theme} class:dark={theme}>{$t("page_reportNewFake.whereDidYouSee")}</div>
  <div id="btns-location">
    <button
      class="btn"
      class:btn-gray={!location_available}
      on:click={place_here}
      class:btnDark={theme}
      title="Use my current location"
    >
    {$t("page_reportNewFake.useCurrentLocation")}
    </button>
    <button
      class="btn"
      on:click={() => {
        showLocationSelect = true;
      }}
      style="flex:2;"
      class:btnDark={theme}
      title="Choose different location"
    >
    {$t("page_reportNewFake.differentLocation")}
    </button>
  </div>
  <div id="box-place-info">
    {#if place && place.name && place.address}
      <span class:textWhite={!theme} class:dark={theme}>{place.name}:</span> {place.address}
    {/if}
  </div>

  <button
    on:click={submitToDB}
    id="btn-submit"
    disabled={!activateSubmit}
    class="btn btn-gray"
    class:btn-blue={activateSubmit}
    class:btnDark={activateSubmit && theme}
    title="Submit"

  >
    {#if submissionInProgress}
    {$t("page_reportNewFake.processing")}
    {:else}
    {$t("page_reportNewFake.submit")}
    {/if}
  </button>


{#if showLocationSelect}
  <M_locationSelect
    on:selected={locationSelected}
    on:retryGeo={() => dispatch("retryGeo")}
    bind:theme={theme}
  />
{/if}
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  #btn-back {
    border: none;
    background: none;
  }

  .box-instruction {
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
  }

  .box-inst-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    padding: none;
    border: 1px solid var(--txt-gray);
    margin-right: 1em;
    overflow: hidden;
  }
  .box-inst-img img {
    width: 105%;
    object-fit: contain;
    margin: -1px;
  }
  .box-inst-text {
    flex: 3;
  }

  label,
  .label-div {
    color: var(--PG-blue);
  }

  .txt-comment {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: none;
    padding: 0.5em;
    font-size: 1em;
    min-height: 100px;
    max-height: 250px;
    margin-bottom: 2em;
  }

  .txt-batch {
    width: 100%;
    border: none;
    padding: 0.5em;
    font-size: 1em;
    margin-bottom: 2em;
  }

  #btns-location {
    margin-top: 0.5em;
    width: 100%;
  }
  #btns-location .btn {
    width: 100%;
    margin-bottom: 0.5em;
  }

  #box-place-info {
    margin-top: 0.5em;
    font-size: 0.9em;
    min-height: 4em;
  }

  #btn-submit {
    margin-top: 2em;
  }
  .light{
    background-color:  var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #272525;
  }
  .textWhite{
    color: var(--PG-blue);
  }
  .inputBox{
    background-color:  white;
    color: #242424;
  }
  .gray{
    color: #fff;
    background-color: #5a5757;
  }
  .btnDark{
    background-color: #000000;
    border-color: #ffffff;
    color: #ffffff;
  }
</style>