export default {
  app: {
    selectLang: "Seleccione el idioma",
    messageText1: `Permítanos saber dónde vio el producto falso otorgando el permiso de "Ubicación de acceso".`,
    messageText2:
      "Esta aplicación debería funcionar en tu navegador,\ny para una mejor experiencia recomendamos usar Chrome o Safari.",
    messageText3:
      "Lo sentimos, su navegador prohíbe el acceso al almacenamiento. Consulte la página de ayuda.",
  },
  page_check: {
    textOffline:
      "Actualmente estás desconectado.\nAún puedes enviar nuevos sospechosos para cargarlos más tarde.",
    textUnauthorized:
      "Lo sentimos, la búsqueda en la base de datos falló.\n\n Verifique que su registro sea válido.",
    dbFailed: "Lo sentimos, la búsqueda en la base de datos falló.",
    thanksMessage: "Gracias por denunciar este producto falso.",
    checkGenuine: "Compruebe si su producto de P&G es genuino:",
    barCode: "código de barras",
    gtinBarcode: "código de barras GTIN",
    scanBtn: "Escanear",
    batchCode: "Código de lote",
    leaveBlank: "Déjelo en blanco si no hay ningún código de lote en el producto.",
    newSuspect: "Nueva sospechosa",
    searchList: "Lista de búsqueda",
    offline: "Actualmente estás desconectado.",
    noEntries :"No se han encontrado entradas de productos falsificados.",
    fake1: "1 producto falso conocido:",
    handMatch: "¿Coincide esto con el producto que tienes en la mano?",    
    knownFake: "productos falsos conocidos:",
    imageMatch:"¿Las imágenes de alguno de estos productos coinciden con el producto que tienes en la mano?",
    clickItem: "Haga clic en el artículo para obtener más detalles. Preste especial atención al código de lote.",
    productImage: "Imagen del producto",
    batch: "Lote",
    seeMore: "Ver más",
    about: "Acerca de",
    dataPolicy: "Política de datos",
    privacy: "Privacidad",
    privacyChoices:"Sus opciones de privacidad",
    tnc: "Términos y condiciones",
    allrights:"Reservados todos los derechos",
  },
  page_reportNewFake: {
    additionalImage: "Imagen adicional. Agregue un comentario a continuación para explicarlo.",
    fileNotSupported:"Tipo de archivo no compatible",
    uploadMsg: "Cargando en segundo plano;\npuede tardar unos segundos.",
    close: "Cerrar",
    submitPhotos:"Enviar fotos de",
    suspectProduct: "producto sospechoso",
    required: "Requerido",
    additionalImage1: "Imagen adicional",
    additionalImage2: "Agregue una imagen adicional, si cree que esto será útil.",
    comment: "Comentario",
    additionalImagePlaceholder:"Comentarios y/u observaciones adicionales.",
    checkBatchcode: "¿Código de lote correcto?",
    batchCode: "Código de lote",
    whereDidYouSee: "¿Dónde lo viste?",
    useCurrentLocation:"Usar mi ubicación actual",
    differentLocation:"Elige una ubicación diferente",
    processing: "..Procesando..",
    submit:"Enviar utilizando la dirección anterior",
    titleReceipt: "Recibo",
    instrReceipt: "Opcional. Añade una foto del recibo de venta.",
    titleGTIN: "Código de barras",
    instrGTIN: "Por favor, toma una foto de cerca del código de barras del producto (GTIN).",
    titleBatch: "Código de lote",
    instrBatch: "Por favor, toma una foto del código de lote.",
    titleBackLabel: "Etiqueta trasera",
    instrBackLabel: "Por favor, toma una foto de la etiqueta trasera completa del producto."
  },
  page_locations:{
    permissionIDB: "Desafortunadamente, esta aplicación no puede acceder al almacenamiento del navegador en su dispositivo para guardar ubicaciones por usted.",
    browserPermission: "Verifique la configuración de permisos de su navegador y permita los `datos del sitio` en `Privacidad y seguridad`.",
    addLocation: "Agregar Direccion",
    locationName: "Nombre del lugar:",
    nameTaken: "nombre ya en uso",
    placeName: "Nombre del lugar",
    search: "Buscar:",
    NameAndAdd: "Nombre y dirección",
    locationImage: "Imagen de ubicación",
    address: "DIRECCIÓN:",
    coordinates: "Coordenadas:",
    here: "aquí",
    save: "Guardar",
    storedLocations: "Ubicaciones almacenadas",
    searchSaved: "Buscar en ubicaciones guardadas:",
    filterByName: "Filtrar por nombre",
    deleteAllPlaces: "Eliminar todos los lugares",
  },
  page_settings: {
    register:"Por favor ingrese el correo electrónico para registrarse.",
    inviteCode: "Necesita un código de invitación para registrarse.",
    registerFailed: "Lo sentimos, el registro falló.\n(Error del servidor)",
    registerSuccess: "Su registro fue exitoso",
    registerFailed1: "Lo sentimos, algo salió mal con tu registro.",
    offline: "Actualmente estás desconectado.\nVuelve a intentarlo más tarde.",
    registerFailed2: "Lo sentimos, el registro falló porque:\n",
    reinvite: "La reinvitación requiere una dirección de correo electrónico válida.",
    invite: "Se ha enviado un correo electrónico de invitación a ",
    inviteFailed: "enviando invitación a ",
    failed: " fallido.",
    permissionIDB:"Desafortunadamente, esta aplicación no puede acceder al almacenamiento del navegador en su dispositivo para conservar sus datos de inicio de sesión.",
    yourAccount:"Su cuenta",
    siteDataPermission:"Verifique la configuración de permisos de su navegador y permita los `datos del sitio` en `Privacidad y seguridad`.",
    registeredName:"Estás registrado como",
    registeredEmail: "con el correo electrónico",
    accountStatus: "El estado de su cuenta es",
    regExp: "El registro vence el",
    deleteRegistration: "Eliminar / restablecer registro",
    email: "Correo electrónico",
    emailPlaceholder: "Su dirección de correo electrónico",
    emailAndCode:"Si ha sido invitado,\n ingrese el correo electrónico y el código\n antes de hacer clic en 'Registrarse'.",
    code: "Código",
    inviteCode:"Código de invitaciónInvitation code",
    register1: "Registro",
    regCode: "Código de Registro",
    migCode: "¿Tienes un código de migración?"

  },
  page_about: {
    privacy: "Privacidad",
    cookies: "Este servicio web no utiliza cookies.",
    warning:"Cuando envía un informe de un producto sospechoso de falsificación, la aplicación recopila y envía la siguiente información al servidor:",
    details:"Detalles del producto - GTIN, Imágenes",
    timeAndDate: "Hora y fecha del informe.",
    location:"Ubicación del informe",
    email:"Dirección de correo electrónico",
    browserInfo:"Información del navegador",
    referLink:"Para obtener más información, consulte",
    privacy: "política de privacidad",
  },
  page_help:{
    about: "Acerca de",
    aboutDesc: "La aplicación web Genuine le permite buscar y verificar si un producto sospechoso de P&G es genuino o falsificado escaneando o ingresando su Número global de artículo comercial (GTIN).",
    aboutDesc1: "Como usuario registrado, la aplicación le permite tomar y enviar fotografías de presuntas falsificaciones. Informar de un avistamiento está disponible tanto en modo en línea como fuera de línea.",
    counterfeit: "Informe sobre avistamientos de falsificaciones",
    counterfeitDesc: "Cuando encuentre un producto falsificado identificado y le permita informar el avistamiento, se le dirigirá a un cuadro de diálogo donde podrá compartir información detallada con P&G.",
    counterfeitDesc1: "Decirnos dónde vio el producto falsificado nos permite proteger tanto a nuestros consumidores como a nuestra marca. Por favor deje un comentario antes de informar un avistamiento.",
    support : "Su apoyo es realmente apreciado.",
    reportFake: "Reportar falso = sospechoso",
    welcomeMsg: "P&G le ha invitado personalmente a utilizar esta aplicación no sólo para comparar productos con productos falsos conocidos, sino también para denunciar nuevos sospechosos de productos falsos.",
    instruction: "Comuníquese con P&#38;G para obtener instrucciones detalladas.",
  },
  page_tnc:{
    tnc: "Términos y condiciones",
    linkDesc: "El uso y acceso a la información de este sitio está sujeto a las",
    linkDesc1: ", establecido en nuestro acuerdo legal.",
  },
  page_dp:{
    dataPolicy: "Política de datos",
    cookies:"Este servicio web no utiliza cookies.",
    dpDesc:"Cuando envía un informe de un producto sospechoso de falsificación, la aplicación recopila y envía la siguiente información al servidor:",
    productDetails: "Detalles del producto - GTIN, Imágenes",
    tnd:"Hora y fecha del informe.",
    location: "Ubicación del informe",
    email: "Dirección de correo electrónico",
    browserInfo: "Información del navegador",
  },
  minion:{
    offlineMsg: "Actualmente estás desconectado.\n\nTus fotos se cargarán\ncuando vuelvas a estar conectado.",
    uploadMsg:"¡Gracias, la carga se realizó correctamente!",
    uploadMsg1:"La carga tardó mucho más de lo esperado.\nInténtelo de nuevo con una mejor conexión.",
    uploadMsg2:"Lo sentimos, la carga falló.",
    uploadMsg3:"La carga de datos se canceló,\nporque tardó mucho más de lo esperado.\nInténtelo de nuevo con una mejor conexión.",
    uploadMsg4:"No se pudieron cargar todos los datos.\nInténtelo nuevamente cuando esté en línea.",
    errMsg: "Problema al analizar el archivo de imagen.",
    errMsg1: "Tipo de archivo no compatible.",
    errMsg2: "No se puede encontrar cuando no está conectado."
  },
  badlistMatch:{
    backLabel:"Etiqueta trasera",
    batchCode:"Código de lote",
    position:"Posición actual",
    offlineMsg:"Actualmente no estás conectado.\nAún puedes enviar nuevos sospechosos para cargarlos más tarde.",
    failureMsg: "Lo sentimos, no se ha podido informar del avistamiento.",
    sightingConfirmed:"¿Es este el producto? Si tiene un código de lote, ¿se ve exactamente como en la imagen de arriba?",
    no: "No",
    yes: "Sí",
    whereDidYouSee: "¿Dónde lo viste?",
    currentLocation: "Usar mi ubicación actual",
    differentLocation: "Elige una ubicación diferente",
    comment: "¿Le gustaría dejar un comentario?",
    commentPlaceholder: "Observaciones adicionales...",
    processing: "..Procesando..",
    sendPg: " Enviar a P&G",
  },
  locationSelect: {
    currentposition: "Posición actual",
    picked:"Elegido en el mapa",
    useCurrentLocation: "Usar mi ubicación actual",
    useAddress:  "Usar la direcion de arriba",
    savedLocation: "Seleccione una ubicación guardada",
  },
  menu:{
    locations: "Ubicaciones",
    resumeUpload:"Continuar con la carga",
    manageDevice: "Administrar dispositivos",
    pauseUpload:"Pausar carga",
    account: "Cuenta",
    cache:"Limpiar cache",
    quit: "Salir de la aplicación",
  },
  scanEAN: {
    align:"Alinee el código de barras en el marco de arriba para escanearlo.",
    camera: "Seleccionar cámara",
    rescan:"Volver a escanear",
    ok:"OK",
    scan:"Escaneo de código de barras ...",
  },
  store:{
    address: "452, Basingstoke Road, Whitley, Reading, Inglaterra, RG2 0JF, Reino Unido",
  },
  manageDevices:{
    edit: "Editar",
    done: "Hecho",
    manageD: "Administrar Dispositivos",
    thisDevice:"Este Dispositivo",
    active: "Sesiones Activas",
    move: "Mover a un nuevo dispositivo",
    unlink:"Este dispositivo se desvinculará automáticamente después de haber emparejado con un nuevo dispositivo",
    generateCode: "Generar Código de Migración"
  },
  page_reg: {
    "enterCode": "Ingrese el código de registro",
    "enterMig": "Ingrese el código de migración",
    "regCode": "Código de Registro",
    "inviteCode": "Código de Invitación",
    "register": "Registrarse",
    "enterMigGenerated": "Introduce el código de migración generado desde tu cuenta existente para transferir la inscripción.",
    "migCode": "Código de Migración"
  }
};
