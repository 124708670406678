<!-- 
	Icon: Batch code (dotted 201)

    @file         Icon_batch.svelte
    @copyright    P&G
-->

<script>
  export let size = "24px";
</script>

<!-- =============================  H T M L  ============================= -->

<svg
  class="svg-icon"
  stroke="currentColor"
  fill="currentColor"
  width={size}
  height={size}
  viewBox="1000 21850 12802 6852"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    stroke="none"
    d="M 1851,28301 C 1851,28371 1832,28440 1797,28501 1762,28562 1712,28612 1651,28647 1590,28682 1521,28701 1451,28701 1380,28701 1311,28682 1250,28647 1189,28612 1139,28562 1104,28501 1069,28440 1050,28371 1050,28301 1050,28230 1069,28161 1104,28100 1139,28039 1189,27989 1250,27954 1311,27919 1380,27900 1451,27900 1521,27900 1590,27919 1651,27954 1712,27989 1762,28039 1797,28100 1832,28161 1851,28230 1851,28301 L 1851,28301 Z"
  />
  <path
    stroke="none"
    d="M 4801,28301 C 4801,28371 4782,28440 4747,28501 4712,28562 4662,28612 4601,28647 4540,28682 4471,28701 4401,28701 4330,28701 4261,28682 4200,28647 4139,28612 4089,28562 4054,28501 4019,28440 4000,28371 4000,28301 4000,28230 4019,28161 4054,28100 4089,28039 4139,27989 4200,27954 4261,27919 4330,27900 4401,27900 4471,27900 4540,27919 4601,27954 4662,27989 4712,28039 4747,28100 4782,28161 4801,28230 4801,28301 L 4801,28301 Z"
  />
  <path
    stroke="none"
    d="M 2851,28301 C 2851,28371 2832,28440 2797,28501 2762,28562 2712,28612 2651,28647 2590,28682 2521,28701 2451,28701 2380,28701 2311,28682 2250,28647 2189,28612 2139,28562 2104,28501 2069,28440 2050,28371 2050,28301 2050,28230 2069,28161 2104,28100 2139,28039 2189,27989 2250,27954 2311,27919 2380,27900 2451,27900 2521,27900 2590,27919 2651,27954 2712,27989 2762,28039 2797,28100 2832,28161 2851,28230 2851,28301 L 2851,28301 Z"
  />
  <path
    stroke="none"
    d="M 3851,28301 C 3851,28371 3832,28440 3797,28501 3762,28562 3712,28612 3651,28647 3590,28682 3521,28701 3451,28701 3380,28701 3311,28682 3250,28647 3189,28612 3139,28562 3104,28501 3069,28440 3050,28371 3050,28301 3050,28230 3069,28161 3104,28100 3139,28039 3189,27989 3250,27954 3311,27919 3380,27900 3451,27900 3521,27900 3590,27919 3651,27954 3712,27989 3762,28039 3797,28100 3832,28161 3851,28230 3851,28301 L 3851,28301 Z"
  />
  <path
    stroke="none"
    d="M 6801,25301 C 6801,25371 6782,25440 6747,25501 6712,25562 6662,25612 6601,25647 6540,25682 6471,25701 6401,25701 6330,25701 6261,25682 6200,25647 6139,25612 6089,25562 6054,25501 6019,25440 6000,25371 6000,25301 6000,25230 6019,25161 6054,25100 6089,25039 6139,24989 6200,24954 6261,24919 6330,24900 6401,24900 6471,24900 6540,24919 6601,24954 6662,24989 6712,25039 6747,25100 6782,25161 6801,25230 6801,25301 L 6801,25301 Z"
  />
  <path
    stroke="none"
    d="M 6851,24301 C 6851,24371 6832,24440 6797,24501 6762,24562 6712,24612 6651,24647 6590,24682 6521,24701 6450,24701 6380,24701 6311,24682 6250,24647 6189,24612 6139,24562 6104,24501 6069,24440 6050,24371 6050,24301 6050,24230 6069,24161 6104,24100 6139,24039 6189,23989 6250,23954 6311,23919 6380,23900 6450,23900 6521,23900 6590,23919 6651,23954 6712,23989 6762,24039 6797,24100 6832,24161 6851,24230 6851,24301 L 6851,24301 Z"
  />
  <path
    stroke="none"
    d="M 9851,24301 C 9851,24371 9832,24440 9797,24501 9762,24562 9712,24612 9651,24647 9590,24682 9521,24701 9451,24701 9380,24701 9311,24682 9250,24647 9189,24612 9139,24562 9104,24501 9069,24440 9050,24371 9050,24301 9050,24230 9069,24161 9104,24100 9139,24039 9189,23989 9250,23954 9311,23919 9380,23900 9451,23900 9521,23900 9590,23919 9651,23954 9712,23989 9762,24039 9797,24100 9832,24161 9851,24230 9851,24301 L 9851,24301 Z"
  />
  <path
    stroke="none"
    d="M 1801,23051 C 1801,23121 1782,23190 1747,23251 1712,23312 1662,23362 1601,23397 1540,23432 1471,23451 1401,23451 1330,23451 1261,23432 1200,23397 1139,23362 1089,23312 1054,23251 1019,23190 1000,23121 1000,23051 1000,22980 1019,22911 1054,22850 1089,22789 1139,22739 1200,22704 1261,22669 1330,22650 1401,22650 1471,22650 1540,22669 1601,22704 1662,22739 1712,22789 1747,22850 1782,22911 1801,22980 1801,23051 L 1801,23051 Z"
  />
  <path
    stroke="none"
    d="M 2801,22251 C 2801,22321 2782,22390 2747,22451 2712,22512 2662,22562 2601,22597 2540,22632 2471,22651 2401,22651 2330,22651 2261,22632 2200,22597 2139,22562 2089,22512 2054,22451 2019,22390 2000,22321 2000,22251 2000,22180 2019,22111 2054,22050 2089,21989 2139,21939 2200,21904 2261,21869 2330,21850 2401,21850 2471,21850 2540,21869 2601,21904 2662,21939 2712,21989 2747,22050 2782,22111 2801,22180 2801,22251 L 2801,22251 Z"
  />
  <path
    stroke="none"
    d="M 3851,22301 C 3851,22371 3832,22440 3797,22501 3762,22562 3712,22612 3651,22647 3590,22682 3521,22701 3451,22701 3380,22701 3311,22682 3250,22647 3189,22612 3139,22562 3104,22501 3069,22440 3050,22371 3050,22301 3050,22230 3069,22161 3104,22100 3139,22039 3189,21989 3250,21954 3311,21919 3380,21900 3451,21900 3521,21900 3590,21919 3651,21954 3712,21989 3762,22039 3797,22100 3832,22161 3851,22230 3851,22301 L 3851,22301 Z"
  />
  <path
    stroke="none"
    d="M 4801,23301 C 4801,23371 4782,23440 4747,23501 4712,23562 4662,23612 4601,23647 4540,23682 4471,23701 4401,23701 4330,23701 4261,23682 4200,23647 4139,23612 4089,23562 4054,23501 4019,23440 4000,23371 4000,23301 4000,23230 4019,23161 4054,23100 4089,23039 4139,22989 4200,22954 4261,22919 4330,22900 4401,22900 4471,22900 4540,22919 4601,22954 4662,22989 4712,23039 4747,23100 4782,23161 4801,23230 4801,23301 L 4801,23301 Z"
  />
  <path
    stroke="none"
    d="M 4801,24251 C 4801,24321 4782,24390 4747,24451 4712,24512 4662,24562 4601,24597 4540,24632 4471,24651 4401,24651 4330,24651 4261,24632 4200,24597 4139,24562 4089,24512 4054,24451 4019,24390 4000,24321 4000,24251 4000,24180 4019,24111 4054,24050 4089,23989 4139,23939 4200,23904 4261,23869 4330,23850 4401,23850 4471,23850 4540,23869 4601,23904 4662,23939 4712,23989 4747,24050 4782,24111 4801,24180 4801,24251 L 4801,24251 Z"
  />
  <path
    stroke="none"
    d="M 3801,25251 C 3801,25321 3782,25390 3747,25451 3712,25512 3662,25562 3601,25597 3540,25632 3471,25651 3401,25651 3330,25651 3261,25632 3200,25597 3139,25562 3089,25512 3054,25451 3019,25390 3000,25321 3000,25251 3000,25180 3019,25111 3054,25050 3089,24989 3139,24939 3200,24904 3261,24869 3330,24850 3401,24850 3471,24850 3540,24869 3601,24904 3662,24939 3712,24989 3747,25050 3782,25111 3801,25180 3801,25251 L 3801,25251 Z"
  />
  <path
    stroke="none"
    d="M 2851,26251 C 2851,26321 2832,26390 2797,26451 2762,26512 2712,26562 2651,26597 2590,26632 2521,26651 2451,26651 2380,26651 2311,26632 2250,26597 2189,26562 2139,26512 2104,26451 2069,26390 2050,26321 2050,26251 2050,26180 2069,26111 2104,26050 2139,25989 2189,25939 2250,25904 2311,25869 2380,25850 2451,25850 2521,25850 2590,25869 2651,25904 2712,25939 2762,25989 2797,26050 2832,26111 2851,26180 2851,26251 L 2851,26251 Z"
  />
  <path
    stroke="none"
    d="M 1801,27301 C 1801,27371 1782,27440 1747,27501 1712,27562 1662,27612 1601,27647 1540,27682 1471,27701 1401,27701 1330,27701 1261,27682 1200,27647 1139,27612 1089,27562 1054,27501 1019,27440 1000,27371 1000,27301 1000,27230 1019,27161 1054,27100 1089,27039 1139,26989 1200,26954 1261,26919 1330,26900 1401,26900 1471,26900 1540,26919 1601,26954 1662,26989 1712,27039 1747,27100 1782,27161 1801,27230 1801,27301 L 1801,27301 Z"
  />
  <path
    stroke="none"
    d="M 9801,25301 C 9801,25371 9782,25440 9747,25501 9712,25562 9662,25612 9601,25647 9540,25682 9471,25701 9401,25701 9330,25701 9261,25682 9200,25647 9139,25612 9089,25562 9054,25501 9019,25440 9000,25371 9000,25301 9000,25230 9019,25161 9054,25100 9089,25039 9139,24989 9200,24954 9261,24919 9330,24900 9401,24900 9471,24900 9540,24919 9601,24954 9662,24989 9712,25039 9747,25100 9782,25161 9801,25230 9801,25301 L 9801,25301 Z"
  />
  <path
    stroke="none"
    d="M 9801,26301 C 9801,26371 9782,26440 9747,26501 9712,26562 9662,26612 9601,26647 9540,26682 9471,26701 9401,26701 9330,26701 9261,26682 9200,26647 9139,26612 9089,26562 9054,26501 9019,26440 9000,26371 9000,26301 9000,26230 9019,26161 9054,26100 9089,26039 9139,25989 9200,25954 9261,25919 9330,25900 9401,25900 9471,25900 9540,25919 9601,25954 9662,25989 9712,26039 9747,26100 9782,26161 9801,26230 9801,26301 L 9801,26301 Z"
  />
  <path
    stroke="none"
    d="M 9801,27251 C 9801,27321 9782,27390 9747,27451 9712,27512 9662,27562 9601,27597 9540,27632 9471,27651 9401,27651 9330,27651 9261,27632 9200,27597 9139,27562 9089,27512 9054,27451 9019,27390 9000,27321 9000,27251 9000,27180 9019,27111 9054,27050 9089,26989 9139,26939 9200,26904 9261,26869 9330,26850 9401,26850 9471,26850 9540,26869 9601,26904 9662,26939 9712,26989 9747,27050 9782,27111 9801,27180 9801,27251 L 9801,27251 Z"
  />
  <path
    stroke="none"
    d="M 8851,28301 C 8851,28371 8832,28440 8797,28501 8762,28562 8712,28612 8651,28647 8590,28682 8521,28701 8451,28701 8380,28701 8311,28682 8250,28647 8189,28612 8139,28562 8104,28501 8069,28440 8050,28371 8050,28301 8050,28230 8069,28161 8104,28100 8139,28039 8189,27989 8250,27954 8311,27919 8380,27900 8451,27900 8521,27900 8590,27919 8651,27954 8712,27989 8762,28039 8797,28100 8832,28161 8851,28230 8851,28301 L 8851,28301 Z"
  />
  <path
    stroke="none"
    d="M 7601,28301 C 7601,28371 7582,28440 7547,28501 7512,28562 7462,28612 7401,28647 7340,28682 7271,28701 7201,28701 7130,28701 7061,28682 7000,28647 6939,28612 6889,28562 6854,28501 6819,28440 6800,28371 6800,28301 6800,28230 6819,28161 6854,28100 6889,28039 6939,27989 7000,27954 7061,27919 7130,27900 7201,27900 7271,27900 7340,27919 7401,27954 7462,27989 7512,28039 7547,28100 7582,28161 7601,28230 7601,28301 L 7601,28301 Z"
  />
  <path
    stroke="none"
    d="M 6801,27301 C 6801,27371 6782,27440 6747,27501 6712,27562 6662,27612 6601,27647 6540,27682 6471,27701 6401,27701 6330,27701 6261,27682 6200,27647 6139,27612 6089,27562 6054,27501 6019,27440 6000,27371 6000,27301 6000,27230 6019,27161 6054,27100 6089,27039 6139,26989 6200,26954 6261,26919 6330,26900 6401,26900 6471,26900 6540,26919 6601,26954 6662,26989 6712,27039 6747,27100 6782,27161 6801,27230 6801,27301 L 6801,27301 Z"
  />
  <path
    stroke="none"
    d="M 6801,26301 C 6801,26371 6782,26440 6747,26501 6712,26562 6662,26612 6601,26647 6540,26682 6471,26701 6401,26701 6330,26701 6261,26682 6200,26647 6139,26612 6089,26562 6054,26501 6019,26440 6000,26371 6000,26301 6000,26230 6019,26161 6054,26100 6089,26039 6139,25989 6200,25954 6261,25919 6330,25900 6401,25900 6471,25900 6540,25919 6601,25954 6662,25989 6712,26039 6747,26100 6782,26161 6801,26230 6801,26301 L 6801,26301 Z"
  />
  <path
    stroke="none"
    d="M 13801,28301 C 13801,28371 13782,28440 13747,28501 13712,28562 13662,28612 13601,28647 13540,28682 13471,28701 13401,28701 13330,28701 13261,28682 13200,28647 13139,28612 13089,28562 13054,28501 13019,28440 13000,28371 13000,28301 13000,28230 13019,28161 13054,28100 13089,28039 13139,27989 13200,27954 13261,27919 13330,27900 13401,27900 13471,27900 13540,27919 13601,27954 13662,27989 13712,28039 13747,28100 13782,28161 13801,28230 13801,28301 L 13801,28301 Z"
  />
  <path
    stroke="none"
    d="M 12801,28251 C 12801,28321 12782,28390 12747,28451 12712,28512 12662,28562 12601,28597 12540,28632 12471,28651 12401,28651 12330,28651 12261,28632 12200,28597 12139,28562 12089,28512 12054,28451 12019,28390 12000,28321 12000,28251 12000,28180 12019,28111 12054,28050 12089,27989 12139,27939 12200,27904 12261,27869 12330,27850 12401,27850 12471,27850 12540,27869 12601,27904 12662,27939 12712,27989 12747,28050 12782,28111 12801,28180 12801,28251 L 12801,28251 Z"
  />
  <path
    stroke="none"
    d="M 11801,28251 C 11801,28321 11782,28390 11747,28451 11712,28512 11662,28562 11601,28597 11540,28632 11471,28651 11401,28651 11330,28651 11261,28632 11200,28597 11139,28562 11089,28512 11054,28451 11019,28390 11000,28321 11000,28251 11000,28180 11019,28111 11054,28050 11089,27989 11139,27939 11200,27904 11261,27869 11330,27850 11401,27850 11471,27850 11540,27869 11601,27904 11662,27939 11712,27989 11747,28050 11782,28111 11801,28180 11801,28251 L 11801,28251 Z"
  />
  <path
    stroke="none"
    d="M 12801,22301 C 12801,22371 12782,22440 12747,22501 12712,22562 12662,22612 12601,22647 12540,22682 12471,22701 12401,22701 12330,22701 12261,22682 12200,22647 12139,22612 12089,22562 12054,22501 12019,22440 12000,22371 12000,22301 12000,22230 12019,22161 12054,22100 12089,22039 12139,21989 12200,21954 12261,21919 12330,21900 12401,21900 12471,21900 12540,21919 12601,21954 12662,21989 12712,22039 12747,22100 12782,22161 12801,22230 12801,22301 L 12801,22301 Z"
  />
  <path
    stroke="none"
    d="M 11801,23301 C 11801,23371 11782,23440 11747,23501 11712,23562 11662,23612 11601,23647 11540,23682 11471,23701 11401,23701 11330,23701 11261,23682 11200,23647 11139,23612 11089,23562 11054,23501 11019,23440 11000,23371 11000,23301 11000,23230 11019,23161 11054,23100 11089,23039 11139,22989 11200,22954 11261,22919 11330,22900 11401,22900 11471,22900 11540,22919 11601,22954 11662,22989 11712,23039 11747,23100 11782,23161 11801,23230 11801,23301 L 11801,23301 Z"
  />
  <path
    stroke="none"
    d="M 9851,23301 C 9851,23371 9832,23440 9797,23501 9762,23562 9712,23612 9651,23647 9590,23682 9521,23701 9451,23701 9380,23701 9311,23682 9250,23647 9189,23612 9139,23562 9104,23501 9069,23440 9050,23371 9050,23301 9050,23230 9069,23161 9104,23100 9139,23039 9189,22989 9250,22954 9311,22919 9380,22900 9451,22900 9521,22900 9590,22919 9651,22954 9712,22989 9762,23039 9797,23100 9832,23161 9851,23230 9851,23301 L 9851,23301 Z"
  />
  <path
    stroke="none"
    d="M 8851,22301 C 8851,22371 8832,22440 8797,22501 8762,22562 8712,22612 8651,22647 8590,22682 8521,22701 8451,22701 8380,22701 8311,22682 8250,22647 8189,22612 8139,22562 8104,22501 8069,22440 8050,22371 8050,22301 8050,22230 8069,22161 8104,22100 8139,22039 8189,21989 8250,21954 8311,21919 8380,21900 8451,21900 8521,21900 8590,21919 8651,21954 8712,21989 8762,22039 8797,22100 8832,22161 8851,22230 8851,22301 L 8851,22301 Z"
  />
  <path
    stroke="none"
    d="M 7801,22301 C 7801,22371 7782,22440 7747,22501 7712,22562 7662,22612 7601,22647 7540,22682 7471,22701 7401,22701 7330,22701 7261,22682 7200,22647 7139,22612 7089,22562 7054,22501 7019,22440 7000,22371 7000,22301 7000,22230 7019,22161 7054,22100 7089,22039 7139,21989 7200,21954 7261,21919 7330,21900 7401,21900 7471,21900 7540,21919 7601,21954 7662,21989 7712,22039 7747,22100 7782,22161 7801,22230 7801,22301 L 7801,22301 Z"
  />
  <path
    stroke="none"
    d="M 6851,23251 C 6851,23321 6832,23390 6797,23451 6762,23512 6712,23562 6651,23597 6590,23632 6521,23651 6450,23651 6380,23651 6311,23632 6250,23597 6189,23562 6139,23512 6104,23451 6069,23390 6050,23321 6050,23251 6050,23180 6069,23111 6104,23050 6139,22989 6189,22939 6250,22904 6311,22869 6380,22850 6450,22850 6521,22850 6590,22869 6651,22904 6712,22939 6762,22989 6797,23050 6832,23111 6851,23180 6851,23251 L 6851,23251 Z"
  />
  <path
    stroke="none"
    d="M 12851,24301 C 12851,24371 12832,24440 12797,24501 12762,24562 12712,24612 12651,24647 12590,24682 12521,24701 12451,24701 12380,24701 12311,24682 12250,24647 12189,24612 12139,24562 12104,24501 12069,24440 12050,24371 12050,24301 12050,24230 12069,24161 12104,24100 12139,24039 12189,23989 12250,23954 12311,23919 12380,23900 12451,23900 12521,23900 12590,23919 12651,23954 12712,23989 12762,24039 12797,24100 12832,24161 12851,24230 12851,24301 L 12851,24301 Z"
  />
  <path
    stroke="none"
    d="M 12801,25301 C 12801,25371 12782,25440 12747,25501 12712,25562 12662,25612 12601,25647 12540,25682 12471,25701 12401,25701 12330,25701 12261,25682 12200,25647 12139,25612 12089,25562 12054,25501 12019,25440 12000,25371 12000,25301 12000,25230 12019,25161 12054,25100 12089,25039 12139,24989 12200,24954 12261,24919 12330,24900 12401,24900 12471,24900 12540,24919 12601,24954 12662,24989 12712,25039 12747,25100 12782,25161 12801,25230 12801,25301 L 12801,25301 Z"
  />
  <path
    stroke="none"
    d="M 12801,26301 C 12801,26371 12782,26440 12747,26501 12712,26562 12662,26612 12601,26647 12540,26682 12471,26701 12401,26701 12330,26701 12261,26682 12200,26647 12139,26612 12089,26562 12054,26501 12019,26440 12000,26371 12000,26301 12000,26230 12019,26161 12054,26100 12089,26039 12139,25989 12200,25954 12261,25919 12330,25900 12401,25900 12471,25900 12540,25919 12601,25954 12662,25989 12712,26039 12747,26100 12782,26161 12801,26230 12801,26301 L 12801,26301 Z"
  />
  <path
    stroke="none"
    d="M 12801,27251 C 12801,27321 12782,27390 12747,27451 12712,27512 12662,27562 12601,27597 12540,27632 12471,27651 12401,27651 12330,27651 12261,27632 12200,27597 12139,27562 12089,27512 12054,27451 12019,27390 12000,27321 12000,27251 12000,27180 12019,27111 12054,27050 12089,26989 12139,26939 12200,26904 12261,26869 12330,26850 12401,26850 12471,26850 12540,26869 12601,26904 12662,26939 12712,26989 12747,27050 12782,27111 12801,27180 12801,27251 L 12801,27251 Z"
  />
  <path
    stroke="none"
    d="M 12851,23301 C 12851,23371 12832,23440 12797,23501 12762,23562 12712,23612 12651,23647 12590,23682 12521,23701 12451,23701 12380,23701 12311,23682 12250,23647 12189,23612 12139,23562 12104,23501 12069,23440 12050,23371 12050,23301 12050,23230 12069,23161 12104,23100 12139,23039 12189,22989 12250,22954 12311,22919 12380,22900 12451,22900 12521,22900 12590,22919 12651,22954 12712,22989 12762,23039 12797,23100 12832,23161 12851,23230 12851,23301 L 12851,23301 Z"
  />
</svg>

<!-- ============================  S T Y L E  ============================ -->

<style>
</style>
