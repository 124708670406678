<!--
	genuinePG frontend

	Modal, opened from App.svelte

    @file         Modal_menu.svelte
    @copyright    P&G
-->

<script>
  import { PAGE, reportImg_maxSideLength_px } from "./config";
  import { readVersion, togglePauseUpload, clearDbImages } from "./minions";
  import {
    ArrowLeftCircleIcon,
    LogOutIcon,
    Trash2Icon,
    PauseIcon,
    MapPinIcon,
    UserIcon,
    SunIcon,
    MoonIcon
  } from "svelte-feather-icons";
  import {t} from "./i18n"

  let versionStr = "v0.0.0";
  export let theme;

  // ============================= close app/site ==============================

  const quitApp = () => {
    window.close();
  }; //quitApp()

  // ============================= store variables =============================

  import {
    st_userRegistered,
    st_waitingUploads,
    st_pauseUpload,
  } from "./stores";

  // user management
  let userRegistered = false;
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
  });

  // items in cache
  let numOfWaitingUploads = 0;
  st_waitingUploads.subscribe((value) => {
    numOfWaitingUploads = value;
  });

  let pauseUpload;
  st_pauseUpload.subscribe((value) => {
    pauseUpload = value;
  });

  // =========================== Construction / Init ===========================

  import { onMount, createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();

  onMount(async () => {
    versionStr = await readVersion();
    let foc = document.querySelector("body");
    foc.addEventListener("keyup", checkTabPress);
    document.getElementById("btn-location").focus();
  }); //onMount()

  let previousTab = "";
  const checkTabPress = (e) => {
    e = e || event;
    if (previousTab == "btn-close") {
      document.getElementById("btn-location").focus();
    }
    previousTab = document.activeElement.id;
    // // console.log("previousTab: ",previousTab);
    // // console.log("keycode: ",e.keyCode);
    // // console.log("active eleemnt: ",document.activeElement);
  };

  // Close this modeal and inform main app which desk page to display
  const close = (nextPage = 0) => {
    document.getElementById("btn-menu").focus();
    previousTab = "";
    dispatch("close", { page: nextPage });
  }; //close()

  const checkFocus = () => {
    document.getElementById("btn-location").focus();
  };

  const cancelFocus = () => {
    return false;
  };

</script>

<!-- =============================  H T M L  ============================= -->

<div class="modal" class:light={!theme} class:dark={theme} on:click={close}>
  <!-- <div id="btn-close" on:click={close}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->

  <button
    id="btn-close"
    on:click={close}
    on:keydown={checkFocus}
    on:keyup={cancelFocus}
    title="Back"
    tabindex="120"
    class:textWhite={!theme} class:textDark={theme}
  >
    <ArrowLeftCircleIcon size="2.5x" />
  </button>
  <div class="version" class:light={!theme} class:dark={theme} on:click={close}>
    {versionStr}
  </div>

  <div id="blueFrame" class:frameBlue={!theme} class:darkFrame={theme}>
    <div id="btnlist">
      <button
        id="btn-location"
        on:click={() => close(PAGE.locations)}
        title="Locations"
        tabindex="100"
      >
        <div class="btn-icon"><MapPinIcon /></div>
        {$t("menu.locations")}
      </button>

      {#if userRegistered > 0}
        <!-- <button on:click={togglePauseUpload}>
                {#if pauseUpload}
                <div class="btn-icon" title="Resume upload"><PauseIcon /></div> Resume upload
                {:else}
                <div class="btn-icon" title="Pause upload"><PauseIcon /></div> Pause upload
                {/if}
            </button> -->
        {#if pauseUpload}
          <button
            on:click={togglePauseUpload}
            title="Resume upload"
            tabindex="101"
          >
            <div class="btn-icon"><PauseIcon /></div>
             {$t("menu.resumeUpload")}
          </button>
        {/if}
        <button
      on:click={() => close(PAGE.managedevices)}
      title="Manage Devices"
      tabindex="109"
    >
      <!-- <div class="btn-icon"><SettingsIcon /></div> Settings -->
      <div class="btn-icon"><UserIcon /></div>
      {$t("menu.manageDevice")}
    </button>
      {:else}
        <button
          on:click={togglePauseUpload}
          title="Pause upload"
          tabindex="102"
        >
          <div class="btn-icon"><PauseIcon /></div>
          {$t("menu.pauseUpload")}
        </button>
      {/if}

      <button
        on:click={() => close(PAGE.settings)}
        title="Account"
        tabindex="103"
      >
        <!-- <div class="btn-icon"><SettingsIcon /></div> Settings -->
        <div class="btn-icon"><UserIcon /></div>
        {$t("menu.account")}
      </button>

      {#if numOfWaitingUploads > 0}
        <button on:click={clearDbImages} title="Clear Cache" tabindex="104">
          <div class="btn-icon"><Trash2Icon /></div>
          {$t("menu.cache")}
        </button>
      {/if}

      <button on:click={quitApp} title="Quit App" tabindex="105">
        <div class="btn-icon"><LogOutIcon /></div>
        {$t("menu.quit")}
      </button>
    </div>
  </div>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 214, 218, 0.8);
    z-index: 1051;
  }
  .light{
    background-color: rgba(211, 214, 218, 0.8);
    color: black;
  }
  .dark{
    background-color: #242424;
    color: #fff;
  }
  .textWhite{
    color: var(--PG-blue);
  }
  .textDark{
    color: #ffffff;
  }
  .frameBlue{
    border: solid 3px var(--PG-blue);
  }
  .darkFrame{
    border: solid 3px #ffffff;
  }

  #blueFrame {
    margin-top: 3em;
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    z-index: 10;
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #btn-close {
    position: absolute;
    top: 8%;
    left: 10%;
    border: none;
    background: none;
  }

  .version {
    position: absolute;
    top: 10%;
    right: 10%;
    font-size: 0.7em;
    /* color: #444; */
    /* color: var(--txt-gray); */
  }

  #btnlist {
    height: 100%;
    width: 70%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  #btnlist button {
    font-size: 1em;
    text-align: left;
    width: 100%;
    margin: auto;
    background-color: white;
    border-radius: 50vh;
    border: none;
    padding: 0.5em;
    padding-left: 1em;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn-icon {
    margin-right: 0.5em;
  }
</style>
