<!--
	genuinePG frontend

	Page, opened from Page_check.svelteHTML

    Information about app and how to use it

    @file         Page_about.svelte
    @copyright    P&G
-->

<script>
  import { PAGE } from "./config";
  import { ArrowLeftCircleIcon } from "svelte-feather-icons";
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from './i18n'
  export let theme;
  // ============================ store variables ==============================

  import { st_userRegistered } from "./stores";

  // user management
  let userRegistered = false;
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
  });

  // ========================= Swipe right to go back ==========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("page-help");

    // // console.log(swipeArea);

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        close();
      }
    });
  }; //setupSwipeRight()

  // =========================== Construction / Init ===========================

  import { onMount } from "svelte";

  onMount(async () => {
    setupSwipeRight();
  }); //onMount()

  const close = () => {
    dispatch("close", { page: PAGE.check });
  }; //close()
</script>

<!-- =============================  H T M L  ============================= -->

<div class:light={!theme} class:dark={theme} id="page-help">
  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:btnBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>

  <!-- <h2>Disclaimer</h2>
  <p>
    This software currently serves for internal tests purposes only and is not
    intended for public use.
  </p> -->

  <h2>{$t("page_help.about")}</h2>
  <p>
    {$t("page_help.aboutDesc")}
  </p>
  <p>
    {$t("page_help.aboutDesc1")}
  </p>
  <h2>{$t("page_help.counterfeit")}</h2>
  <p>
    {$t("page_help.counterfeitDesc")}
  </p>
    {$t("page_help.counterfeitDesc1")}
  <p>
    
  </p>
  <p>{$t("page_help.support")}</p>

  {#if userRegistered}
    <h2>{$t("page_help.reportFake")}</h2>
    <p>
      {$t("page_help.welcomeMsg")}
    </p>
    <p>{$t("page_help.instruction")}</p>
  {/if}

  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:btnBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  p {
    margin-bottom: 1em;
  }

  #btn-back {
    text-align: left;
    border: none;
    background: none;
  }
  .light{
    background-color:var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #242424;
  }
  .btnBlue{
    color: var(--PG-blue);
  }
</style>
