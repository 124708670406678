<!--
	genuinePG frontend

	Modal, opened from Modal_badlistMatch.svelte and Page_reportNewFake.svelte

    Allows the user to select a predefined location or pick on on the map.

    @file         Modal_locationSelect.svelte
    @copyright    P&G
-->

<script>
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from "./i18n"
  export let theme

  // ============================= store variables =============================

  import {
    st_permissionIDB,
    st_places,
    st_lastUsedPlace,
    st_location_available,
    st_location_latitude,
    st_location_longitude,
    st_location_accuracy,
  } from "./stores";

  // User defined places in storage
  let permissionIDB = true;
  st_permissionIDB.subscribe((value) => {
    permissionIDB = value;
  });

  let places = [];
  st_places.subscribe((value) => {
    places = value;
  });

  let lastUsedPlace;
  st_lastUsedPlace.subscribe((value) => {
    lastUsedPlace = value;
  });

  // geolocation
  let location_available;
  let location_latitude;
  let location_longitude;
  let location_accuracy;
  st_location_available.subscribe((value) => {
    location_available = value;
  });
  st_location_latitude.subscribe((value) => {
    location_latitude = value;
  });
  st_location_longitude.subscribe((value) => {
    location_longitude = value;
  });
  st_location_accuracy.subscribe((value) => {
    location_accuracy = value;
  });

  // ============================= Maps -> Leaflet =============================

  import L from "leaflet";
  import { findAddressFromGeo } from "./minions";

  let map;
  let mapMarker;
  let mapCircle;
  let map_latitude = 51.4278;
  let map_longitude = -0.97127;
  let map_accuracy_m = 1;
  let place_name = "";
  let place_address = "";

  const createMap = () => {
    // Start with last used location
    place_name = lastUsedPlace.name;
    map_latitude = lastUsedPlace.latitude;
    map_longitude = lastUsedPlace.longitude;
    map_accuracy_m = lastUsedPlace.accuracy_m;
    place_address = lastUsedPlace.address;

    // Force new Leaflet map object
    if (map) {
      map.remove();
    }

    map = L.map("map");
    if (map_latitude == 0 && map_longitude == 0) {
      map.fitWorld();
    } else {
      map.setView([map_latitude, map_longitude]);
    }

    if (map_accuracy_m < 20) {
      map.setZoom(19);
    } else if (map_accuracy_m < 200) {
      map.setZoom(16);
    } else if (map_accuracy_m < 2000) {
      map.setZoom(14);
    } else {
      map.setZoom(12);
    }

    map.on("click", async (evt) => {
      map_latitude = evt.latlng.lat;
      map_longitude = evt.latlng.lng;
      map_accuracy_m = 1;
      place_name =  "Picked on map";
      updateMap();
      place_address = await findAddressFromGeo(map_latitude, map_longitude);
    });

    // Map tiles from OpenStreetMap
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 20,
      minZoom: 5,
      zoomSnap: 0.25,
      zoomDelta: 0.25,
      maxNativeZoom: 19,
      attribution: "©OpenStreetMap",
    }).addTo(map);

    // Map marker
    L.Icon.Default.imagePath = "/img/";
    mapMarker = L.marker([map_latitude, map_longitude], {
      draggable: true,
    }).addTo(map);

    mapMarker.on("dragstart", (evt) => {
      map_accuracy_m = 1;
      place_name = "Picked on map";
    });

    mapMarker.on("move", (evt) => {
      map_latitude = evt.latlng.lat;
      map_longitude = evt.latlng.lng;
    });

    mapMarker.on("moveend", async (evt) => {
      updateMap();
      place_address = await findAddressFromGeo(map_latitude, map_longitude);
    });

    // Accuracy circle
    mapCircle = L.circle([map_latitude, map_longitude], {
      color: "none",
      fillColor: "var(--PG-blue)",
      fillOpacity: 0.25,
      radius: map_accuracy_m,
    }).addTo(map);
  }; //createMap()

  const updateMap = () => {
    if (!map) {
      return;
    }

    map.setView([map_latitude, map_longitude]);

    if (mapMarker) {
      mapMarker.setLatLng([map_latitude, map_longitude]);
    }

    if (mapCircle) {
      mapCircle.setLatLng([map_latitude, map_longitude]);
      mapCircle.setRadius(map_accuracy_m);
    }
  }; //updateMap()

  const place_here = async () => {
    if (!location_available) {
      dispatch("retryGeo");
      return;
    }

    place_name = 'Current Position';
    place_address = "";
    map_latitude = location_latitude;
    map_longitude = location_longitude;
    map_accuracy_m = location_accuracy;

    updateMap();
    place_address = await findAddressFromGeo(map_latitude, map_longitude);

    chooseThisPlace();
  }; //place_here()

  // ============================ Manage place list ============================

  let placesFiltered = [];

  const filterPlaces = () => {
    placesFiltered = Object.values(places);

    if (placesFiltered.length < 1) {
      return;
    }

    // show only active
    placesFiltered = placesFiltered.filter((item) => {
      return item.active;
    });

    // (prepare for) chronological sorting
    for (let i = 0; i < placesFiltered.length; i++) {
      if (!("timestamp" in placesFiltered[i])) {
        placesFiltered[i].timestamp = new Date(2020, 1, 1);
      } else {
        // convert date strings to date object
        placesFiltered[i].timestamp = new Date(placesFiltered[i].timestamp);
      }
    }
    placesFiltered.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
  }; //filterPlaces()

  // ============================== Select Place ===============================

  const chooseThisPlace = () => {
    let choosenPlace = {
      name: place_name,
      address: place_address,
      latitude: map_latitude,
      longitude: map_longitude,
      accuracy_m: map_accuracy_m,
      image: "",
      active: true,
    };

    // // console.log(choosenPlace);

    dispatch("selected", { place: choosenPlace });
  }; //chooseThisPlace()

  const selectPlaceFromList = (place) => {
    dispatch("selected", { place: place });
  }; //selectPlaceFromList()

  // =========================== Construction / Init ===========================

  import { onMount, onDestroy } from "svelte";

  onMount(async () => {
    filterPlaces();
    createMap();
  }); //onMount()

  onDestroy(async () => {
    if (map) {
      map.remove();
    }
  }); //onDestroy()
</script>

<!-- =============================  H T M L  ============================= -->

<div class="modal" class:light={!theme} class:dark={theme}>
  <div id="map" />

  <div id="box-mapSelect">
    <div class="box-place-address" class:light={!theme} class:dark={theme}>
      <h4>Address:</h4>
      {place_address}
    </div>

    <button
      class="btn"
      class:btn-gray={!location_available}
      on:click={place_here}
      title="Use my current location"
    >
      {$t("locationSelect.useCurrentLocation")}
    </button>
    <button
      class="btn btn-blue"
      on:click={chooseThisPlace}
      title="Use the above address"
    >
      {$t("locationSelect.useAddress")}
    </button>
  </div>

  <div id="box-placeLst" class:light={!theme} class:dark={theme}>
    <h3>{$t("locationSelect.savedLocation")}</h3>

    {#each placesFiltered as place}
      <div class="box-place" on:click={() => selectPlaceFromList(place)}>
        <div class="box-place-img">
          {#if place.image}
            <img src={place.image} alt="Image" />
          {:else}
            <img src="/img/marker-icon-placeholder.png" alt="Marker Icon" />
          {/if}
        </div>

        <div class="box-place-address">
          <h4>{place.name}</h4>
          {place.address}
        </div>
      </div>
    {/each}
  </div>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  .modal {
    position: fixed;
    left: 0;
    top: 3.5em;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 214, 218, 0.8);
    z-index: 1051;
    text-align: left;
    background-color: var(--bg-gray);

    overflow-x: hidden;
    overflow-y: auto;
  }

  #map {
    display: block;
    width: 100%;
    height: 22em;
    overflow: hidden;
  }

  #box-mapSelect {
    padding: 1em;
    width: 100%;
  }
  .box-place-address {
    min-height: 5em;
    margin-bottom: 1em;
  }

  #box-mapSelect .btn {
    width: 100%;
    margin-bottom: 0.5em;
  }

  #box-placeLst {
    padding: 1em;
    padding-bottom: 6em;
  }

  .box-place {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .box-place-img {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    flex: 0 0 5.7em;
    width: 5.7em;
    height: 5.7em;
    padding: none;
    border: 1px solid var(--txt-gray);
    margin-right: 1em;
    overflow: hidden;
  }
  .box-place-img img {
    width: 105%;
    object-fit: contain;
    margin: -1px;
  }

  .box-place-address {
    font-size: 0.9em;
  }

  .box-place-address h4 {
    margin-top: 0px;
  }
  .light {
    background-color: #fff;
    color: #242424;
  }
  .dark {
    color: #fff !important;
    background-color: #000000;
  }
</style>
