/*
	genuinePG frontend

	Helper functions used on multiple pages

  @file         minions.js
  @copyright    P&G

*/

import { domain, scanbotBarcodeFormats, scanbotLicenceKey } from "./config";
import ScanbotSDK from "scanbot-web-sdk";
import {t} from "./i18n"
import {st_token_id} from "./stores";

// ============================ backend address ==============================

export const URI_API = (command) => {
  return domain + "be/" + command;
}; //URI_API()

export const URI_IMG = (imageName) => {
  return domain + "listimages/" + imageName;
  // 4dbg | Mainly for debug: disable caching
  // return domain + "listimages/" + imageName + "?" + (new Date()).getTime();
}; //URI_IMG()

// ================================ js fixes =================================

export const deepCopyObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}; //deepCopyObject()

// ========================= (cache) software version ========================

export const readVersion = async () => {
  const keyList = await caches.keys().catch((err) => {
    return "0.0.0";
  });
  if (keyList) {
    return keyList[0];
  }
  return "0.0.0";
}; //readVersion()

// =============================== Connectivity ==============================

import { st_connected, st_user_id} from "./stores";

let connected = true;
st_connected.subscribe((value) => {
  connected = value;
});

let token_id;
st_token_id.subscribe((value)=>{
  token_id  = value;
})

export const serverReachable = async (timeout_ms = 60000) => {
  let reachable = false;
  let controller = new AbortController();
  const tmo = setTimeout(() => controller.abort(), timeout_ms);

  const reply = await fetch(URI_API("ping"), {
    signal: controller.signal,
  }).catch((err) => {
    // console.log("ping timeout");
  });
  if (reply && reply.ok) {
    const msg = await reply.text();
    if (msg.includes("Greetings")) {
      reachable = true;
    }
  }

  clearTimeout(tmo);
  return reachable;
}; //connectionToServer()

// export const tokenIdexist = async (timeout_ms = 60000) => {
//   let tokenIdExist = true;
//   let controller = new AbortController();
//   const tmo = setTimeout(() => controller.abort(), timeout_ms);
//   let tokenId = await token_id
//   let data={tokenID:tokenId}
//   const reply = await fetch(URI_API("tokenIdExistForDeletion"), {
//     signal: controller.signal,
//     method:"post",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(data)
//   }).catch((err) => {
//     // console.log("ping timeout");
//   });
//   if (reply && reply.ok) {
//     const msg = await reply.text();
//     if (msg.status == "not ok") {
//       tokenIdExist = false
//     }
//   }

//   clearTimeout(tmo);
//   return tokenIdExist;
// };


//=> Download speed measurement was only used in test phase

// export const connectionSpeed = async (timeout_ms = 10000) => {

//     // Download 1 MB
//     let downloadURI = domain + "listimages/refsize1MB.dat?" + (new Date()).getTime();
//     let downloadSpeed_Mbps = 0;

//     // Need to use old XMLHttpRequest, since fetch is intercepted by service worker,
//     // leading to wrong results for fetch
//     let xhr = new XMLHttpRequest();
//     xhr.open( 'GET', downloadURI, false);
//     xhr.send();

//     const rT = performance.getEntriesByName(downloadURI);
//     // console.log(rT)
//     if(rT){
//         const T = rT[0];
//         if (('responseEnd' in T && 'responseStart' in T) && ((T.responseEnd - T.responseStart) > 0)){
//             downloadSpeed_Mbps = 8000 / (T.responseEnd - T.responseStart);
//             // console.log(`Time to download 1MB: ${(T.responseEnd - T.responseStart)}`);
//         }
//     }

//     return downloadSpeed_Mbps;
// };//connectionSpeed()

// =========================== Date and time tools ===========================

export const dateStr = (isoString) => {
  let d = new Date(isoString);
  return `${String(d.getDate()).padStart(2, "0")}.${String(
    d.getMonth() + 1
  ).padStart(2, "0")}.${d.getFullYear()}, ${String(d.getHours()).padStart(
    2,
    "0"
  )}:${String(d.getMinutes()).padStart(2, "0")}`;
}; //dateStr()

export const timeStr = (isoString) => {
  let d = new Date(isoString);
  return `${String(d.getHours()).padStart(2, "0")}:${String(
    d.getMinutes()
  ).padStart(2, "0")}`;
}; //dateStr()

// ============================== User Data iDB ==============================

import { openDB } from "idb";
import {
  st_auth,
  st_username,
  st_email,
  st_instructions,
  st_userRegistered,
  st_permissionIDB,
  st_videoDevices,
  st_selectedVideoDeviceID,
  st_location_latitude,
  st_location_longitude
} from "./stores";

st_token_id.subscribe((value)=>{
  token_id = value;
})

let userRegistered = false;
st_userRegistered.subscribe((value) => {
  userRegistered = value;
});

let latitude;
st_location_latitude.subscribe((value) => {
  latitude = value;
});
let longitude;
st_location_longitude.subscribe((value) => {
  longitude = value;
});
let auth;
st_auth.subscribe((value) => {
  auth = value;
});

let permissionIDB = true;

const iDB_store_user = "userdata";

export const storeUserData = async (data) => {
  const db = await openDB(iDB_store_user + "-store", 1, {
    upgrade(db) {
      db.createObjectStore(iDB_store_user);
    },
  }).catch((err) => {
    st_permissionIDB(false);
    permissionIDB = false;
  });

  if (db && permissionIDB) {
    const keys = Object.keys(data);
    await Promise.all(
      keys.map(async (key) => {
        await db.put(iDB_store_user, data[key], key);
      })
    );

    db.close();
  }
}; //storeUserData()

export const readUserData = async () => {
  let userData = {
    email: "",
    name: "",
    userID: 0,
    token: "extern",
    instructions: [],
    lastUsedPlace: undefined,
    videoDevices: [],
    selectedVideoDeviceID: undefined,
    token_id:0
  };

  const db = await openDB(iDB_store_user + "-store", 1, {
    upgrade(db) {
      db.createObjectStore(iDB_store_user);
    },
  }).catch((err) => {
    st_permissionIDB.set(false);
    permissionIDB = false;
  });

  if (db && permissionIDB) {
    const valuesDB = await db.getAll(iDB_store_user);
    const keysDB = await db.getAllKeys(iDB_store_user);

    const keys = Object.keys(userData);
    keys.forEach((key) => {
      let idx = keysDB.indexOf(key);
      if (idx > -1) {
        userData[key] = valuesDB[idx];
      }
    });

    db.close();
  }

  st_email.set(userData.email);
  st_username.set(userData.name);
  st_auth.set({ userID: parseInt(userData.userID), token: userData.token,token_id:userData.token_id });
  st_instructions.set(userData.instructions);
  st_token_id.set(userData.token_id);
	st_user_id.set(userData.userID);

  st_userRegistered.set(userData.userID > 0 && userData.token != "extern");

  if (userData.lastUsedPlace) {
    st_lastUsedPlace.set(userData.lastUsedPlace);
  }

  if (userData.videoDevices) {
    st_videoDevices.set(userData.videoDevices);
  }
  if (userData.selectedVideoDeviceID) {
    st_selectedVideoDeviceID.set(userData.selectedVideoDeviceID);
  }
}; //readUserData()

export const updateInstructions = async () => {
  if (!userRegistered || !connected) {
    return;
  }
  const lat = await latitude
  const long = await longitude
  // // console.log("lat: ", lat, "long: ", long)
  const modifiedAuth = {...auth, 'latitude':lat, 'longitude':long}
  const reply = await fetch(URI_API("instructions"), {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(modifiedAuth),
  }).catch((err) => {
    st_connected.set(false);
  });

  if (reply && reply.ok) {
    const instr = await reply.json();
    // // console.log("instr: ",instr);

    st_instructions.set(instr);

    if (permissionIDB) {
      const db = await openDB(iDB_store_user + "-store", 1, {
        upgrade(db) {
          db.createObjectStore(iDB_store_user);
        },
      });

      await db.put(iDB_store_user, instr, "instructions");

      db.close();
    }
  } else {
    // // console.log("Failed to obtain instructions.");
    return;
  }
}; //updateInstructions()

export const clearUserData = async () => {
  let userData = {
    email: "",
    name: "",
    userID: 0,
    token: "extern",
    instructions: [],
  };

  st_email.set(userData.email);
  st_username.set(userData.name);
  st_auth.set({ userID: userData.userID, token: userData.token });
  st_instructions.set(userData.instructions);

  st_userRegistered.set(false);

  if (permissionIDB) {
    const db = await openDB(iDB_store_user + "-store", 1, {
      upgrade(db) {
        db.createObjectStore(iDB_store_user);
      },
    });

    db.clear(iDB_store_user);
    db.close();
  }
}; //clearUserData()

// ========================= Image upload iCB ==========================

import { uploadTimeout_ms } from "./config";
import {
  st_dbImages,
  st_waitingUploads,
  st_uploadRunning,
  st_pauseUpload,
} from "./stores";

let dbImages;
let uploadRunning;
st_dbImages.subscribe((value) => {
  dbImages = value;
});
st_uploadRunning.subscribe((value) => {
  uploadRunning = value;
});

let pauseUpload;
st_pauseUpload.subscribe((value) => {
  pauseUpload = value;
});

export const iDB_store_img = "imgdata";

export const openDbImages = async () => {
  let imgDB = await openDB(iDB_store_img + "-store", 1, {
    upgrade(db) {
      db.createObjectStore(iDB_store_img, { autoIncrement: true });
    },
  });

  st_dbImages.set(imgDB);
  return imgDB;
}; //openDbImages()

export const addImgPakgToBrowserStorage = async (imgPakg) => {
  // // console.log("In addImgPakgToBrowserStorage...");
  if (!dbImages) {
    // // console.log("Need to open ImageDB");
    await openDbImages();
  }

  if (dbImages) {
    // // console.log("adding images to ImageDB");

    dbImages.add(iDB_store_img, imgPakg);

    let imgKeys = await dbImages.getAllKeys(iDB_store_img);
    st_waitingUploads.set(imgKeys.length);
  }
}; //addImgPakgToBrowserStoarage

export const togglePauseUpload = () => {
  st_pauseUpload.set(!pauseUpload);
}; //togglePauseUpload()

// Convert base64 encoding to mime type (blob) for efficient upload
const base64ToBlob = (imgBase64) => {
  let base64 = imgBase64.replace(/^data:image\/(png|jpeg);base64,/, "");
  let mime = "image/jpeg";
  let sliceSize = 1024;
  let byteChars = window.atob(base64);
  let byteArrays = [];

  for (
    let offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    let slice = byteChars.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  } //(offset)

  return new Blob(byteArrays, { type: mime });
}; //base64ToBlob()

export const packageUpload = async () => {
  if (!dbImages | uploadRunning | pauseUpload) {
    return "";
  }

  let imgKeys = await dbImages.getAllKeys(iDB_store_img);
  let waitingUploads = imgKeys.length;
  st_waitingUploads.set(waitingUploads);

  // // console.log("Number of packages to upload: " + imgKeys.length);
  if (imgKeys.length < 1) {
    return "";
  }

  // upload latest item first, so that errors don't block
  imgKeys.reverse();
  let offlineMsg;
  let online = await serverReachable();
  if (!online) {
    // return $t("minion.offlineMsg");
    t.subscribe((val)=>{
      offlineMsg=val("minion.offlineMsg");
    });
    return offlineMsg;
  }

  st_uploadRunning.set(true);
  // let uploadMsg = $t("minion.uploadMsg"); 
  let uploadMsg;
  t.subscribe((val)=>{
    uploadMsg=val("minion.uploadMsg");
  });
  // Await the end of all parallel fetches
  await Promise.all(
    imgKeys.map(async (key) => {
      let imgPakg = await dbImages.get(iDB_store_img, key);

      let imageData = imgPakg.images;
      imgPakg.images = Object.keys(imgPakg.images);

      // -- PRE : Check which images need to be uploaded --
      const formData = new FormData();
      formData.append("jsonStr", JSON.stringify({ ...auth, ...imgPakg }));

      let controller = new AbortController();
      const tmo_pre = setTimeout(
        () => controller.abort(),
        uploadTimeout_ms / 3
      );
      const reply_pre = await fetch(URI_API("report_fake_product_parallel"), {
        method: "post",
        body: formData,
        signal: controller.signal,
      }).catch((err) => {
        // uploadMsg =
        // $t("minion.uploadMsg1");
        t.subscribe((val)=>{
          uploadMsg = val("minion.uploadMsg1");
        });
      });
      
      clearTimeout(tmo_pre);
      
      if (!reply_pre || !reply_pre.ok) {
        // uploadMsg = $t("minion.uploadMsg2");
        // uploadMsg = 
        t.subscribe((val)=>{
          uploadMsg = val("minion.uploadMsg2");
        });
        return;
      }

      const msg_pre = await reply_pre.text();
      // // console.log(`Message from server re upload: ${msg_pre}`);

      // Try to upload missing images
      if (msg_pre.includes("Missing image files:")) {
        const images2upload = msg_pre.split(":")[1].split(",");
        // // console.log(`images2upload: ${images2upload}`);

        if (!images2upload) {
          // // console.log("failed to parse missing images");
          return "";
        }

        await Promise.all(
          images2upload.map(async (imageName) => {
            // // console.log(`uploading image ${imageName}`)

            const imageBase64 = imageData[imageName];
            let imgBlob = base64ToBlob(imageBase64);

            const formImg = new FormData();
            formImg.append("imageFile", imgBlob);
            formImg.append(
              "jsonStr",
              JSON.stringify({
                ...auth,
                timestamp: imgPakg.timestamp,
                imageName: imageName,
              })
            );

            let controller = new AbortController();
            const tmo_img = setTimeout(
              () => controller.abort(),
              uploadTimeout_ms
            );
            const reply_img = await fetch(
              URI_API("report_fake_product_parallel"),
              {
                method: "post",
                body: formImg,
                signal: controller.signal,
              }
            ).catch((err) => {
              // uploadMsg =
                // $t("minion.uploadMsg3");
                t.subscribe((val)=>{
                  uploadMsg=val("minion.uploadMsg3");
                });
            });
            clearTimeout(tmo_img);
            if (!reply_img) {
              return;
            }

            const msg_img = await reply_img.text();
            // // console.log(`Message from server re upload: ${msg_img}`);

            if (!reply_img.ok) {
              return; // TODO shift to reply check above
            }
          })
        ); //(each missing image upload)
      } //[if files are missing]

      // Submit to database if all uploaded successfully
      const tmo_post = setTimeout(
        () => controller.abort(),
        uploadTimeout_ms / 3
      );
      const reply_post = await fetch(URI_API("report_fake_product_parallel"), {
        method: "post",
        body: formData,
        signal: controller.signal,
      }).catch((err) => {
        // uploadMsg =$t("minion.uploadMsg3");
        // uploadMsg = 
        t.subscribe((val)=>{
          uploadMsg=val("minion.uploadMsg3");
        });
          
      });

      clearTimeout(tmo_post);

      if (!reply_post || !reply_post.ok) {
        return;
      }

      const msg_post = await reply_post.text();
      // // console.log(`Message from server post upload: ${msg_post}`);

      if (
        msg_post &&
        (msg_post.includes("OK") || msg_post.includes("already in database"))
      ) {
        let transaction = dbImages.transaction(iDB_store_img, "readwrite");
        transaction.objectStore(iDB_store_img).delete(key);
        waitingUploads--;
        st_waitingUploads.set(waitingUploads);
      } else {
        // fetch failed, e.g. service worker returned status 500
        // uploadMsg = $t("minion.uploadMsg4");
        // uploadMsg = 
        t.subscribe((val)=>{
          uploadMsg=val("minion.uploadMsg4");
        });
      }
    })
  ); //(each imgPakg in browser storage)
  st_uploadRunning.set(false);

  return uploadMsg;
}; //packageUpload()

export const readNumOfWaitingUploads = async (dbImages) => {
  if (!dbImages) {
    dbImages = await openDbImages();
  }

  let imgKeys = await dbImages.getAllKeys(iDB_store_img);
  st_waitingUploads.set(imgKeys.length);
}; //readNumOfWaitingUploads()

export const clearDbImages = async () => {
  st_waitingUploads.set(0);
  if (!dbImages) {
    dbImages = await openDbImages();
  }

  dbImages.clear(iDB_store_img);
}; //openDbImages()

// ============================= Image capture ===============================

export const additionalImg_src = `data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 44 44"
fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
<path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
<circle cx="12" cy="13" r="4"></circle></svg>`;

export const checkFileType = (fileName) => {
  let extension = fileName.split(".")[1].toLowerCase();
  if (
    extension == "jpg" ||
    extension == "jpeg" ||
    extension == "heic" ||
    extension == "heif"
  ) {
    return true;
  } else {
    return false;
  }
};

export const pictureFromExtPhotoApp = (maxSideLength_px) => {
  let pa = document.getElementById("photoAppInput");
  if (!pa) {
    // // console.log(
    //   'Could not find side element "photoAppInput" in App.svelte HTML code.'
    // );
    return "";
  }

  // const draganddrop = new Promise((resolve,reject)=>{
  //   let dropContainer = document.querySelector(".box-instruction");
  //   dropContainer.ondragover = function(evt) {
  //             evt.preventDefault();
  //   };
  //   dropContainer.ondragenter = function(evt) {
  //     evt.preventDefault();
  //   };
    
  //   dropContainer.ondrop = function(evt) {
  //     const dT = new DataTransfer();
  //     dT.items.add(evt.dataTransfer.files[0]);
  //    // console.log(dT.files);
  //    let file = dT.files;
  //    if (file) {
  //     // console.log("filename ",file[0].name);
  //      let isReqFileExtension = checkFileType(file[0].name);
  //      if (isReqFileExtension) {
  //        const reader = new FileReader();
  //        reader.onload = () => {
  //          // Use original size image
  //          if (!maxSideLength_px || maxSideLength_px < 1) {
  //            resolve(reader.result);
  //          }

  //          // Resize
  //          var image = new Image();
  //          image.onload = (evt) => {
  //            let newWidth = image.width;
  //            let newHeight = image.height;

  //            if (Math.max(newWidth, newHeight) > maxSideLength_px) {
  //              if (newWidth > newHeight) {
  //                newHeight *= maxSideLength_px / newWidth;
  //                newWidth = maxSideLength_px;
  //              } else {
  //                newWidth *= maxSideLength_px / newHeight;
  //                newHeight = maxSideLength_px;
  //              }
  //            }

  //            let canvas = document.createElement("canvas");
  //            canvas.width = newWidth;
  //            canvas.height = newHeight;
  //            canvas
  //              .getContext("2d")
  //              .drawImage(image, 0, 0, newWidth, newHeight);

  //            // use resized image
  //            resolve(canvas.toDataURL("image/jpeg"));
  //          }; //on image obj ready
  //          image.src = reader.result;
  //        }; //on file loaded

  //        reader.onerror = () => {
  //          temporaryFileReader.abort();
  //          let errMsg;
  //          t.subscribe((val)=>{
  //            errMsg=val("minion.errMsg");
  //          });
  //          reject(new DOMException(errMsg));
  //        };

  //        reader.readAsDataURL(file[0]);
  //      } else {
  //        let errMsg;
  //        t.subscribe((val)=>{
  //          errMsg=val("minion.errMsg1");
  //        });
  //        reject(new DOMException(errMsg));
  //      }
  //    }
  //     // console.log("I have dropedd the image");
  //     evt.preventDefault();
  //   };

  //   pa.click();
  // })

  return new Promise((resolve, reject) => {
    pa.onchange = (evt) => {
      let file = evt.target.files[0];
      if (file) {
        let isReqFileExtension = checkFileType(file.name);
        if (isReqFileExtension) {
          const reader = new FileReader();
          reader.onload = () => {
            // Use original size image
            if (!maxSideLength_px || maxSideLength_px < 1) {
              resolve(reader.result);
            }

            // Resize
            var image = new Image();
            image.onload = (evt) => {
              let newWidth = image.width;
              let newHeight = image.height;

              if (Math.max(newWidth, newHeight) > maxSideLength_px) {
                if (newWidth > newHeight) {
                  newHeight *= maxSideLength_px / newWidth;
                  newWidth = maxSideLength_px;
                } else {
                  newWidth *= maxSideLength_px / newHeight;
                  newHeight = maxSideLength_px;
                }
              }

              let canvas = document.createElement("canvas");
              canvas.width = newWidth;
              canvas.height = newHeight;
              canvas
                .getContext("2d")
                .drawImage(image, 0, 0, newWidth, newHeight);

              // use resized image
              resolve(canvas.toDataURL("image/jpeg"));
            }; //on image obj ready
            image.src = reader.result;
          }; //on file loaded

          reader.onerror = () => {
            temporaryFileReader.abort();
            let errMsg;
            t.subscribe((val)=>{
              errMsg=val("minion.errMsg");
            });
            reject(new DOMException(errMsg));
          };

          reader.readAsDataURL(file);
        } else {
          let errMsg;
          t.subscribe((val)=>{
            errMsg=val("minion.errMsg1");
          });
          reject(new DOMException(errMsg));
        }
      } //[file]
    };

    pa.click();
  }); //Promise....

  // return Promise.any([draganddrop,clickOnOpen]);
}; //resizeImage()

// ============================ Location Data iDB ============================

import { st_dbPlaces, st_places, st_lastUsedPlace } from "./stores";
import { PAGE } from "./config";

let dbPlaces;
st_dbPlaces.subscribe((value) => {
  dbPlaces = value;
});

const iDB_store_places = "places";

export const openDbPlaces = async () => {
  let placeDB = await openDB(iDB_store_places + "-store", 1, {
    upgrade(db) {
      db.createObjectStore(iDB_store_places);
    },
  }).catch((err) => {
    // // console.log("Failed to open indexDB for places.");
    st_permissionIDB.set(false);
    permissionIDB = false;
  });

  st_dbPlaces.set(placeDB);
  return placeDB;
}; //openDbPlaces()

export const storePlace = async (place) => {
  if (dbPlaces) {
    dbPlaces.put(iDB_store_places, place, place.name);

    storeLastUsedPlace(place);
  }
}; //storeUserData()

export const deleteStoredPlace = async (place) => {
  if (dbPlaces) {
    let transaction = dbPlaces.transaction(iDB_store_places, "readwrite");
    transaction.objectStore(iDB_store_places).delete(place.name);
  }
}; //storeUserData()

export const readStoredPlaces = async () => {
  let places = {};
  if (dbPlaces) {
    const valuesDB = await dbPlaces.getAll(iDB_store_places);
    const keysDB = await dbPlaces.getAllKeys(iDB_store_places);

    keysDB.forEach((key) => {
      let idx = keysDB.indexOf(key);
      if (idx > -1) {
        places[key] = valuesDB[idx];
      }
    });
  }
  st_places.set(places);
}; //readStoredPlaces()

// Store last used location in USER DATA !
export const storeLastUsedPlace = async (place) => {
  //check valid place
  if (
    !(
      "name" in place &&
      "latitude" in place &&
      "longitude" in place &&
      "accuracy_m" in place &&
      "address" in place
    )
  ) {
    // // console.log("Invalid place to store.");
    return;
  }

  st_lastUsedPlace.set(place);

  storeUserData({ lastUsedPlace: place });
}; //storeLastUsedLocation()

export const clearDbPlaces = async () => {
  if (!dbPlaces) {
    dbPlaces = await openDbPlaces();
  }
  // // console.log("deleting place iDB");
  dbPlaces.clear(iDB_store_places);
  st_places.set({});
}; //deleteAllPlaces()

export const findAddressFromGeo = async (latitude, longitude) => {
  if (latitude == 0 && longitude == 0) {
    return "Unknown";
  }

  const reply = await fetch(
    `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&zoom=18&format=json`
  ).catch((err) => {
    // // console.log(err);
    // return $t("minion.errMsg2");
    let errMsg;
    t.subscribe((val)=>{
      errMsg=val("minion.errMsg2");
    });
    return errMsg
  });

  if (reply && reply.ok) {
    const data = await reply.json();
    if (data && "display_name" in data) {
      return data.display_name;
    }
  }
}; //findAddressFromGeo()

//=======================================Functions to Generate Keys====================================

export async function loadPublicKey() {
  const publicKeyToEncrypt =
    "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0FveFcK3Y9ZLFj1+HBa38KZ0SDBVkR5kNTg+GJqpG23m+yAaVwr7hHY5FbWS0rtea/iFxD8LQ87KjuR6Pq16uoFc879hJGETbib+3qwPF7tGtOYnW7QJyEhpPZSXFH2oOzzl4Y2qPM/1QVBlMJJ/QCIh9+jap5kY09JRTc0BS9sHwo8mxhZ/GuVJxcahesIG+cpwVdz8X7afs3Y/WHcQLa9tU2yBZgPKyS4wlzHt+pKC6cTMA4RdUuFS1r6tQ0ok1i3LtAwFKPBc98VKLG6LsytCN5W/lyBIIlt7orruU8rfeBHOE+QYPFPgRPeL2fs19vXCCZFAvZ6EYvrOyG5yrQIDAQAB-----END PUBLIC KEY-----";

  const publicKeyToEncryptBody = publicKeyToEncrypt
    .replace("-----BEGIN PUBLIC KEY-----", "")
    .replace("-----END PUBLIC KEY-----", "");

  const publicKeyToEncryptBytes = Uint8Array.from(
    atob(publicKeyToEncryptBody),
    (c) => c.charCodeAt(0)
  );
  const publicKeyToEncryptArrayBuffer = publicKeyToEncryptBytes.buffer;

  const publicKey = await crypto.subtle.importKey(
    "spki",
    publicKeyToEncryptArrayBuffer,
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    true,
    ["encrypt"]
  );

  return publicKey;
}

export async function loadPrivateKey() {
  const privateKeyToDecrypt =
    "-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2dIsTbdAQZjn+r4F5vFFkVUw5OZHH+dCWlEOyydXCzMaZq0qUeV2nQBB1LCjrModA26MyZDzqEnHxruUrLndqXZIJdaQTB91gQ7sbfdAP2QXofSGKsHpnSw3HHeUWFPpA7SNuXw6qUXLsenwLqDRG3i9B9TXTR4IKhO8eM4B/o8WiE80Uah6I9pPplBajuuvsa0Ol3RxpKAJgR9WzIoHQKBwEJKOqubZ/uCxtX/TdrGNceNajkF7OYihpSz64wkjIO02T6dYzARJpyeJBu8xXoem/WtOVDhZMuHlZ2wpFSMXxStEe5hy5fWc4Hk0Aw7harXOGFYgUs919Ho/JFsQ1AgMBAAECggEADb8opllY+o9VMcbz5KHnaP2TPWGExhIgoOPBWQRjfmaS1Qumlhqxz3Lgeo+N8rCPJ7gUquyJ+WHT4293tUHuPd2Ybe2G4+ctCv53Z7oaNcN2cj6RSyOCSWuKeXTbaIdClqc3Su7PCk6SL9aHp5IwBCHj4h6RRF6oIdZDtN0RAqzJ2YHNZTn0V3GHL2PPjACncOQTB09S9Xj2MdSev5hSm+R3uJWlS+1GaBlMCsQ0pZhPsN7RjiakVIWGJj6WRpD7+czaNYFCczBxjnUEkJ8bc7Z7yiGsRIzcWYWaxgcb2GIRTg4qUMIt+7v6MXvXNRjrzzbMXrnb6OeVkGr6Mu+gEQKBgQDcoLmBX65EkjxscPf2Fu7YnxFWWSYf6f2JQLZwr/doj0peYHR0VqyZrw+HdUyshbtAHCseWDYxAhmV64dKzK81yhYRFLPVW7GYZrAFb1ZbzHif0YNgjQmMScyVW2/YimTf9zTBEZ5iAqiGXPJ3pvjdsVNB0UfEvt0F11Hm4cOxBQKBgQDTtRfVuulDuIuXHJWZD7+YthC8/6d1Jtaaut4ifxab7oTEn3TugTfG7TcOf0nXa+RD5DZrUX+qBjPTPszApumBQNqDd8KBweqwcSpFQeGKecU9ZwT2QOyoD9v4wEZqni9wv3alAgRbSLoNVB/9YPC+vFkdL+l0VcZUyiDF48TtcQKBgQDPukCPEBBbnbhofcDpolQF/1bqjwt3Vu+RMC3WUcfHWcqyq2xaMVVXad1cM9QaR9uzFBKBApsbb5e5cUYz5h49reUy9eQQScIf9OdcZqTPz+fuyexBlulbfgyyv6HCJyOtP+sasUUHkOstfwwIHl0JFrGGMc657t7ZZg9GLGD9JQKBgADKm4U0Bq03ImZ1N+Xzlh7HB3b9aa3ZcFHxwuTUHsyEbsCha/l8SC4ZMux981O31omeb8mk61pCXa07fd52gUb3bBmzt+luRSdVtPe/UE2JOQCKwwz8nyXdsU/TwC+j2b+NJ69kO4nv0qo8F0aGN0AvShH+3NuPeodUnhy/RjwxAoGALFpVVEYefDmcnKjLgkNIxb9KRhu6LdOb22YR/ZSIsnsEKysYfoX+sSCGAYEXeZcPdJe4xSzcC5OWkOMHLhlDProuNLd0BIFbcGXaamMEjOT6ZkyX/aquV6ZZ1dFyl7kFRSMNp/4evHs43lAbyvTVMo4iecz3sAwjrn0f9n/z5DU=-----END PRIVATE KEY-----";

  const privateKeyToDecryptBody = privateKeyToDecrypt
    .replace("-----BEGIN PRIVATE KEY-----", "")
    .replace("-----END PRIVATE KEY-----", "");

  const privateKeyToDecryptBytes = Uint8Array.from(
    atob(privateKeyToDecryptBody),
    (c) => c.charCodeAt(0)
  );
    // // console.log("Private key to decrypt: ", privateKeyToDecryptBytes)
  const privateKeyToDecryptArrayBuffer = privateKeyToDecryptBytes.buffer;

  const privateKey = await crypto.subtle.importKey(
    "pkcs8",
    privateKeyToDecryptArrayBuffer,
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    true,
    ["decrypt"]
  );
  return privateKey;
}

//=========================================Function to Decrypt===================================================
export async function toDecrypt(encryptedText) {
  const privateKey = await loadPrivateKey();
  const encryptedDataBuffer = new Uint8Array(
    atob(encryptedText)
      .split("")
      .map((c) => c.charCodeAt(0))
  );
  let decryptedString = "";
  await crypto.subtle
    .decrypt(
      {
        name: "RSA-OAEP",
      },
      privateKey,
      encryptedDataBuffer
    )
    .then((decryptedDataBuffer) => {
      decryptedString = new TextDecoder().decode(decryptedDataBuffer);
      // // console.log("decryptedString:", decryptedString);
    })
    .catch((error) => {
      // console.log(error);
    });
  return decryptedString;
}
//=========================================Function to Encrypt===================================================
export async function encryptData(publicKey, plainText) {
  const encodedData = new TextEncoder().encode(plainText);
  const encryptedData = await crypto.subtle.encrypt(
    { name: "RSA-OAEP" },
    publicKey,
    encodedData
  );
  return new Uint8Array(encryptedData).buffer;
}

//=======================================Common method for encryption=======================

export async function toEncrypt(text) {
  const publicKey = await loadPublicKey();
  const encryptedData = await encryptData(publicKey, text);

  let TYPED_ARRAY = new Uint8Array(encryptedData);
  const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    return data + String.fromCharCode(byte);
  }, "");
  let encData = btoa(STRING_CHAR);
  // // console.log("encData", encData);

  return encData;
}
// =====================Scanbot Initialization=======================================

export let scanbotSDK;
export let scanbotInitializationDone = false;
export async function initializeScanbot() {
  // console.log("--------SCANBOT INITIALIZED--------");
  scanbotSDK = await ScanbotSDK.initialize({
    licenseKey: scanbotLicenceKey,
    // barcodeFormats: scanbotBarcodeFormats,
    allowThread: true,
    // engine: "barcode-scanner/"
  });
  return scanbotSDK;
}