<!--
	genuinePG frontend

	Page, opened from Page_check.svelteHTML

  Imprint, General and legal information about app

  @file         Page_about.svelte
  @copyright    P&G
-->

<script>
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from "./i18n"
  import { PAGE } from "./config";
  import { ArrowLeftCircleIcon } from "svelte-feather-icons";
  export let theme;
  // ========================= Swipe right to go back ==========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("page-about");

    // // console.log(swipeArea);

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        close();
      }
    });
  }; //setupSwipeRight()

  // =========================== Construction / Init ===========================

  import { onMount } from "svelte";

  onMount(async () => {
    setupSwipeRight();
  }); //onMount()

  const close = () => {
    dispatch("close", { page: PAGE.check });
  }; //close()
</script>

<!-- =============================  H T M L  ============================= -->

<div id="page-about" class:light={!theme} class:dark={theme}>
  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:btnBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>

  <!-- <h2>About genuinePG</h2>
    <p>
        This software serves currently only for internal tests
        and is not intended for public use.
    </p> -->

  <!-- <h2>Impressum</h2>
    <p>This web service is currently developped by:</p>
    
    <p>
        Responsible for the content in accordance with §55 Abs. 2 RStV is<br/> Dr. Robert Fischer.
    </p> -->

  <h2>{$t("page_about.privacy")}</h2>
  <p>{$t("page_about.cookies")}</p>
  <p>
    {$t("page_about.warning")}
  </p>
  <ul>
    <li>{$t("page_about.details")}</li>
    <li>{$t("page_about.timeAndDate")}</li>
    <li>{$t("page_about.location")}</li>
    <li>{$t("page_about.email")}</li>
    <li>{$t("page_about.browserInfo")}</li>
  </ul>
  <p>
    {$t("page_about.referLink")} <a
      href="https://privacypolicy.pg.com/en/"
      target="_blank"
      rel="noreferrer">{$t("page_about.privacy")}</a
    >
  </p>

  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:btnBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  p {
    margin-bottom: 1em;
  }

  #btn-back {
    border: none;
    background: none;
  }

  ul {
    padding-left: 2em;
    margin-bottom: 2em;
  }
  .light{
    background-color: #fff;
    color: #242424;
  }
  .btnBlue{
    color: var(--PG-blue);
  }
  .dark{
    color: #ffffff;
    background-color: #242424;
  }
</style>
