/*
	Central configuration file for genuinePG frontend

  @file         config.js
  @copyright    P&G

*/


// local does not work - missing cert
// export const domainDash = "https://webapp-badlist-dashpgbe-dev-01.azurewebsites.net/";

//  --------------------------Change while deploying to dev/prod-------------------------------


// export const domain = "http://localhost:8202/";
// export const domain = "https://192.168.178.78/";
// export const domain = "https://webapp-badlist-genuinepgbe-dev-01.azurewebsites.net/";
// export const domain = "https://webapp-badlist-genuinebe-qa-02.azurewebsites.net/";
export const domain = "https://webapp-badlist-genuinebe-prod-01.azurewebsites.net/";
// export const domainCDN = "https://d1obrizhe1f36b.cloudfront.net/";

// local does not work - missing cert
// export const domainDash = "http://localhost:8803/";
// export const domainDash = "https://webapp-badlist-dashpgbe-dev-01.azurewebsites.net/";
// export const domainDash = "https://webapp-badlist-dashpgbe-qa-02.azurewebsites.net/";
 export const domainDash = "https://webapp-badlist-dashbe-prod-01.azurewebsites.net/";

export const PAGE = {
  check: 1,
  reportNewFake: 2,
  locations: 3,
  settings: 4,
  about: 5,
  help: 6,
  termsAndCondition: 7,
  dataPolicy: 8,
  managedevices:9
};

export const checkConnectionInterval_ms = 60000; // check all 10 seconds

export const eanScanAutoAcceptLevel = 7; // set to -1 to deactivate

export const uploadTimeout_ms = 200000; // 20 seconds timeout for uploads

export const reportImg_maxSideLength_px = 2000; // longer side of fake suspect images
export const placeImg_maxSideLength_px = 350; // longer side of place images



//  --------------------------Change while deploying to dev/prod-------------------------------

// -------------------SCANBOT Licence Key for localhost, QA and Dev ----------------------

// export const scanbotLicenceKey = 
// "Z4ckdW1NmEZuQ+MhZ5GxQ/wTYC724T" +
// "4HlwccsO+oFEDUWIkKk9VFs1UBd4q0" +
// "/PqfoTf21FXyMC34szr48hZWReW1kl" +
// "GA27nTPkzzQdWEmeOiuWZXzN7me8n3" +
// "jLGwND05V0KNyMxeGu9+lLTKljlT+d" +
// "KYl0QeL5+TzYxcjZMBMbxMyr9waM9L" +
// "t2l3t30EB8By2dpREu7ST1cg0rDiPm" +
// "RWPIRIDYMhTV0xfcqzxykNNJchjIPw" +
// "xNI0eSpjE6pk5lfsZpbC/5vXdy8Hjm" +
// "LpsTSbuMHHHJyIhS7kDSeSjKmQwBXl" +
// "vuzXSSuYvQdFY7fMMFTvB1AqHCRjfh" +
// "vjuPQ9Q1Re8Q==\nU2NhbmJvdFNESw" +
// "pwZ2dlbnVpbmUuY29tfHBnZ2VudWlu" +
// "ZS1kZXYucGcuY29tfHBnZ2VudWluZS" +
// "1xYS5wZy5jb218bG9jYWxob3N0CjE3" +
// "NjE4Njg3OTkKNTEyCjg=\n";

  // export const scanbotLicenceKey =
  //     "oCnee8FjxyI+oobRnMspRCN43k5jjR" +
  //     "rvYsbXpOezaUztz4g9iL49mmWLrp2h" +
  //     "2OEhH6SKMvx2noEZQG/wSXdVhQizuU" +
  //     "MnP9uWhhScVpG3aA1XnDpRH4d4BuwY" +
  //     "v4Dc4HG/AF2NkU0Rsv+ZRf/zuWpE2J" +
  //     "i9Ly2YleUcZla3uvo4lQ/lUzq+Hvjt" +
  //     "PCGXKqvC7k2lLk/b5BoP/xjBab8bkH" +
  //     "iEQIAkJMYwcGeHb7HgazAdDQDyIVQ3" +
  //     "znqjOix9u8q6/4Ecsaiq7fwpVi5IHd" +
  //     "tlcIp1PDDNmRfsqKmXtO2UJyUxtMAP" +
  //     "5a/o1jDkdyrtN/0Wr4Vkpzq8PT5CTM" +
  //     "f2Z9kqhvYtPA==\nU2NhbmJvdFNESw" +
  //     "p3ZWJhcHAtYmFkbGlzdC1nZW51aW5l" +
  //     "ZmUtcWEtMDIuYXp1cmV3ZWJzaXRlcy" +
  //     "5uZXR8bG9jYWxob3N0fCpwZ2dlbnVp" +
  //     "bmUtZGV2LnBnLmNvbXwqcGdnZW51aW" +
  //     "5lLXFhLnBnLmNvbXxwZ2dlbnVpbmUt" +
  //     "ZGV2LnBnLmNvbSp8cGdnZW51aW5lLX" +
  //     "FhLnBnLmNvbSoKMTczMDMzMjc5OQo1" +
  //     "MTIKOA==\n";

      
export const scanbotBarcodeFormats = [
  // "AZTEC",
  "CODABAR",
  "CODE_39",
  "CODE_93",
  "CODE_128",
  // "DATA_MATRIX",
  "EAN_8",
  "EAN_13",
  "ITF",
  "MAXICODE",
  // "PDF_417",
  // "QR_CODE",
  "RSS_14",
  "RSS_EXPANDED",
  "UPC_A",
  "UPC_E",
  "UPC_EAN_EXTENSION",
  "MSI_PLESSEY",
];

// -------------------SCANBOT Licence Key for Prod----------------------

export const scanbotLicenceKey =
  "KL/EvEkImjA5ziU/4D+GtzEpiWYDWA" +
  "nPjWvJk/72MpKbIjR1Z1aRR4oCDq8p" +
  "KQoqPGtPfWxFivuf1hloYuESsMXJMl" +
  "NDcX7myKYL6IkBZCsboVRJEfwLS9en" +
  "XZvEVqXxxk3towbAiTnuPa3lW2jkVJ" +
  "d9MqI06qNov/8Xtg0gFLw8+PmUTa4x" +
  "uTckxVA7cyUyPd7Jg5/fDCwkXpollO" +
  "uPmdqC4SABRXymKXhU4/VyDJr7uYHF" +
  "UkqqtYY4fxzTKoLTmv6dyaMRNcnn01" +
  "C9FWd938AC8O82n7O3iUaRuvf1/BTi" +
  "89dP0V26EkzCA9ojjQAEnRVCJc+Wiz" +
  "3+n5BwahTCPQ==\nU2NhbmJvdFNESw" +
  "pwZ2dlbnVpbmUuY29tfHBnZ2VudWlu" +
  "ZS1kZXYucGcuY29tfHBnZ2VudWluZS" +
  "1xYS5wZy5jb218d3d3LnBnZ2VudWlu" +
  "ZS5jb20KMTc2MTg2ODc5OQo1MTIKOA" +
  "==\n";
