export default {
    app: {
      selectLang: "เลือกภาษา",
      messageText1:
        "โปรดแจ้งให้เราทราบว่าคุณพบสินค้าปลอมที่ไหนโดยให้สิทธิ์ `ตำแหน่งการเข้าถึง`",
      messageText2:
        "แอปนี้ควรใช้งานได้ในเบราว์เซอร์ของคุณ\nและเพื่อประสบการณ์ที่ดีที่สุด เราขอแนะนำให้ใช้ Chrome หรือ Safari",
      messageText3:
        "ขออภัย เบราว์เซอร์ของคุณห้ามการเข้าถึงที่เก็บข้อมูล โปรดดูหน้าความช่วยเหลือ",
    },
    page_check: {
      textOffline:
        "คุณกำลังออฟไลน์อยู่\nคุณยังสามารถส่งผู้ต้องสงสัยรายใหม่เพื่ออัปโหลดในภายหลัง",
      textUnauthorized:
        "ขออภัย การค้นหาฐานข้อมูลล้มเหลว\n\n โปรดตรวจสอบว่าการลงทะเบียนของคุณถูกต้อง",
      dbFailed: "ขออภัย การค้นหาฐานข้อมูลล้มเหลว.",
      thanksMessage: "ขอบคุณสำหรับการรายงานผลิตภัณฑ์ปลอมนี้",
      checkGenuine: "ตรวจสอบว่าผลิตภัณฑ์ของคุณเป็นของแท้จาก P&G หรือไม่:",
      barCode: "บาร์โค้ด",
      gtinBarcode: "บาร์โค้ด",
      scanBtn: "สแกน",
      batchCode: "รหัสแบทช์",
      leaveBlank: "เว้นว่างไว้หากไม่มีรหัสแบทช์บนผลิตภัณฑ์",
      newSuspect: "ผู้ต้องสงสัยรายใหม่",
      searchList: "รายการค้นหา",
      offline: "ขณะนี้คุณออฟไลน์",
      noEntries :"ไม่พบรายการสินค้าปลอม",
      fake1: "1 สินค้าปลอมที่ทราบ:",
      handMatch: "ตรงกับสินค้าในมือของคุณหรือไม่?",    
      knownFake: "สินค้าปลอมที่รู้จัก:",
      imageMatch:"รูปภาพจากผลิตภัณฑ์เหล่านี้ตรงกับผลิตภัณฑ์ในมือของคุณหรือไม่?",
      clickItem: "คลิกที่รายการเพื่อดูรายละเอียด โปรดใส่ใจเป็นพิเศษกับรหัสแบทช์",
      productImage: "รูปภาพสินค้า",
      batch: "แบทช์",
      seeMore: "ดูเพิ่มเติม",
      about: "เกี่ยวกับ",
      dataPolicy: "นโยบายข้อมูล",
      privacy: "ความเป็นส่วนตัว",
      privacyChoices:"ทางเลือกความเป็นส่วนตัวของคุณ",
      tnc: "ข้อตกลงและเงื่อนไข",
      allrights:"สงวนลิขสิทธิ์",
    },
    page_reportNewFake: {
      additionalImage: "รูปภาพเพิ่มเติม โปรดเพิ่มความคิดเห็นด้านล่างเพื่ออธิบาย",
      fileNotSupported:"ไม่รองรับประเภทไฟล์",
      uploadMsg: "กำลังอัปโหลดในเบื้องหลัง\nอาจใช้เวลาสักครู่",
      close: "ปิด",
      submitPhotos:"ส่งภาพถ่ายของ",
      suspectProduct: "สินค้าต้องสงสัย",
      required: "ที่จำเป็น",
      additionalImage1: "รูปภาพเพิ่มเติม",
      additionalImage2: "เพิ่มรูปภาพเพิ่มเติม หากคุณคิดว่าสิ่งนี้จะเป็นประโยชน์",
      comment: "ความคิดเห็น",
      additionalImagePlaceholder:"ความเห็นและ/หรือข้อสังเกตเพิ่มเติม",
      checkBatchcode: "รหัสชุดถูกต้องหรือไม่",
      batchCode: "รหัสแบทช์",
      whereDidYouSee: "รหัสแบทช์",
      useCurrentLocation:"ใช้ตำแหน่งปัจจุบันของฉัน",
      differentLocation:"เลือกสถานที่อื่น",
      processing: "..กำลังประมวลผล..",
      submit:"ส่งโดยใช้ที่อยู่ข้างต้น",
      titleReceipt: "ใบเสร็จ",
      instrReceipt: "ไม่จำเป็นต้องมี ถ่ายรูปใบเสร็จขายสินค้า",
      titleGTIN: "บาร์โค้ด",
      instrGTIN: "กรุณาถ่ายรูปบาร์โค้ดสินค้า (GTIN) ให้ชัดเจน",
      titleBatch: "รหัสชุด",
      instrBatch: "กรุณาถ่ายรูปรหัสชุด",
      titleBackLabel: "ฉลากด้านหลัง",
      instrBackLabel: "กรุณาถ่ายรูปฉลากด้านหลังของผลิตภัณฑ์ทั้งหมด"
    },
    page_locations:{
      permissionIDB: "ขออภัย แอปนี้ไม่สามารถเข้าถึงพื้นที่เก็บข้อมูลของเบราว์เซอร์บนอุปกรณ์ของคุณเพื่อบันทึกตำแหน่งให้กับคุณได้",
      browserPermission: "โปรดตรวจสอบการตั้งค่าการอนุญาตเบราว์เซอร์ของคุณและอนุญาต `ข้อมูลไซต์` ภายใต้ `ความเป็นส่วนตัวและความปลอดภัย`",
      addLocation: "เพิ่มสถานที่",
      locationName: "ชื่อสถานที่:",
      nameTaken: "มีการใช้ชื่อนี้แล้ว",
      placeName: "ชื่อสถานที่",
      search: "ค้นหา:",
      NameAndAdd: "ชื่อและที่อยู่",
      locationImage: "รูปภาพสถานที่",
      address: "ที่อยู่:",
      coordinates: "พิกัด:",
      here: "ที่นี่",
      save: "บันทึก",
      storedLocations: "สถานที่ที่บันทึกไว้",
      searchSaved: "ค้นหาในตำแหน่งที่บันทึกไว้:",
      filterByName: "กรองตามชื่อ",
      deleteAllPlaces: "ลบสถานที่ทั้งหมด",
    },
    page_settings: {
      register:"กรุณากรอกอีเมล์เพื่อลงทะเบียน",
      inviteCode: "คุณต้องมีรหัสเชิญเพื่อลงทะเบียน",
      registerFailed: "ขออภัย การลงทะเบียนล้มเหลว\n(ข้อผิดพลาดของเซิร์ฟเวอร์)",
      registerSuccess: "การลงทะเบียนของคุณสำเร็จแล้ว",
      registerFailed1: "ขออภัย มีบางอย่างผิดพลาดกับการลงทะเบียนของคุณ",
      offline: "คุณกำลังออฟไลน์อยู่\nโปรดลองอีกครั้งในภายหลัง",
      registerFailed2: "ขออภัย การลงทะเบียนล้มเหลวเนื่องจาก:\n",
      reinvite: "การเชิญซ้ำต้องใช้ที่อยู่อีเมลที่ถูกต้อง",
      invite: "อีเมลคำเชิญได้ถูกส่งไปยัง ",
      inviteFailed: "กำลังส่งคำเชิญไปที่",
      failed: " ล้มเหลว.",
      permissionIDB:"ขออภัย แอปนี้ไม่สามารถเข้าถึงพื้นที่เก็บข้อมูลของเบราว์เซอร์บนอุปกรณ์ของคุณเพื่อเก็บรายละเอียดการเข้าสู่ระบบไว้ได้",
      yourAccount:"บัญชีของคุณ",
      siteDataPermission:"โปรดตรวจสอบการตั้งค่าการอนุญาตเบราว์เซอร์ของคุณและอนุญาต 'ข้อมูลไซต์' ภายใต้ 'ความเป็นส่วนตัวและความปลอดภัย'",
      registeredName:"คุณลงทะเบียนเป็น",
      registeredEmail: "ด้วยอีเมล",
      accountStatus: "สถานะบัญชีของคุณคือ",
      regExp: "การลงทะเบียนหมดอายุในวันที่",
      deleteRegistration: "ลบ / รีเซ็ตการลงทะเบียน",
      email: "อีเมล",
      emailPlaceholder: "ที่อยู่อีเมลของคุณ",
      emailAndCode:"หากคุณได้รับเชิญ\n โปรดป้อนอีเมลและรหัส\n ก่อนที่จะคลิก 'ลงทะเบียน'",
      code: "รหัส",
      inviteCode:"รหัสการเชิญ",
      register1: "ลงทะเบียน",
      regCode: "รหัสการลงทะเบียน",
      migCode: "คุณมีรหัสการย้ายถิ่นฐานหรือไม่?"
    },
    page_about: {
        privacy: "ความเป็นส่วนตัว",
        cookies: "บริการบนเว็บนี้ไม่ใช้คุกกี้",
        warning:"เมื่อคุณส่งรายงานเกี่ยวกับผลิตภัณฑ์ลอกเลียนแบบที่น่าสงสัย แอปจะรวบรวมและส่งข้อมูลต่อไปนี้ไปยังเซิร์ฟเวอร์:",
        details:"รายละเอียดของผลิตภัณฑ์ - GTIN, รูปภาพ",
        timeAndDate: "เวลาและวันที่รายงาน",
        location:"ตำแหน่งของรายงาน",
        email:"ที่อยู่อีเมล",
        browserInfo:"ข้อมูลเบราว์เซอร์",
        referLink:"สำหรับข้อมูลเพิ่มเติม โปรดดูที่",
        privacy: "นโยบายความเป็นส่วนตัว",
      },
      page_help:{
        about: "เกี่ยวกับ",
        aboutDesc: "แอปพลิเคชันบนเว็บของแท้ช่วยให้คุณค้นหาและตรวจสอบว่าผลิตภัณฑ์ P&G ที่ต้องสงสัยเป็นของแท้หรือของปลอมโดยการสแกนหรือป้อนหมายเลขสินค้าการค้าสากล (GTIN)",
        aboutDesc1: "ในฐานะผู้ใช้ที่ลงทะเบียน แอปจะอนุญาตให้คุณถ่ายและส่งรูปถ่ายของปลอมที่น่าสงสัย การรายงานการพบเห็นสามารถทำได้ทั้งในโหมดออนไลน์และออฟไลน์",
        counterfeit: "รายงานการพบเห็นสินค้าลอกเลียนแบบ",
        counterfeitDesc: "เมื่อคุณพบผลิตภัณฑ์ลอกเลียนแบบที่ระบุได้และได้รับอนุญาตให้รายงานการพบเห็น คุณจะถูกนำไปยังกล่องโต้ตอบที่คุณสามารถแบ่งปันข้อมูลโดยละเอียดกับ P&G",
        counterfeitDesc1: "การแจ้งให้เราทราบว่าคุณพบสินค้าลอกเลียนแบบจากที่ใดช่วยให้เราสามารถปกป้องทั้งผู้บริโภคและแบรนด์ของเราได้ กรุณาแสดงความคิดเห็นก่อนที่จะรายงานการมองเห็น",
        support : "การสนับสนุนของคุณได้รับการชื่นชมอย่างแท้จริง",
        reportFake: "แจ้งความปลอม=ผู้ต้องสงสัย",
        welcomeMsg: "คุณได้รับเชิญเป็นการส่วนตัวจาก P&#38;G ให้ใช้แอพนี้ไม่เพียงแต่เพื่อเปรียบเทียบผลิตภัณฑ์กับผลิตภัณฑ์ปลอมที่เป็นที่รู้จักเท่านั้น แต่ยังเพื่อรายงานผู้ต้องสงสัยรายใหม่เกี่ยวกับผลิตภัณฑ์ปลอมอีกด้วย",
        instruction: "โปรดติดต่อ P&G เพื่อขอคำแนะนำโดยละเอียด",
      },
      page_tnc:{
        tnc: "ข้อกำหนดและเงื่อนไข",
        linkDesc: "การใช้และการเข้าถึงข้อมูลบนเว็บไซต์นี้อยู่ภายใต้บังคับของ",
        linkDesc1: "ที่กำหนดไว้ในข้อตกลงทางกฎหมายของเรา.",
      },
      page_dp:{
        dataPolicy: "นโยบายข้อมูล",
        cookies:"บริการบนเว็บนี้ไม่ใช้คุกกี้",
        dpDesc:"เมื่อคุณส่งรายงานเกี่ยวกับผลิตภัณฑ์ลอกเลียนแบบที่น่าสงสัย แอปจะรวบรวมและส่งข้อมูลต่อไปนี้ไปยังเซิร์ฟเวอร์:",
        productDetails: "รายละเอียดของผลิตภัณฑ์ - GTIN, รูปภาพ",
        tnd:"เวลาและวันที่รายงาน",
        location: "ตำแหน่งของรายงาน",
        email: "ข้อมูลเบราว์เซอร์",
        browserInfo: "ข้อมูลเบราว์เซอร์",
      },
      minion:{
        offlineMsg: "คุณกำลังออฟไลน์อยู่\n\nรูปภาพของคุณจะถูกอัปโหลด\nเมื่อคุณออนไลน์อีกครั้ง",
        uploadMsg:"ขอบคุณ อัปโหลดสำเร็จแล้ว!",
        uploadMsg1:"การอัปโหลดใช้เวลานานกว่าที่คาดไว้มาก\nโปรดลองอีกครั้งด้วยการเชื่อมต่อที่ดีขึ้น",
        uploadMsg2:"ขออภัย การอัปโหลดล้มเหลว",
        uploadMsg3:"การอัปโหลดข้อมูลถูกยกเลิก\nเนื่องจากใช้เวลานานกว่าที่คาดไว้มาก\nโปรดลองอีกครั้งด้วยการเชื่อมต่อที่ดีขึ้น",
        uploadMsg4:"ไม่สามารถอัปโหลดข้อมูลทั้งหมดได้\nโปรดลองอีกครั้งเมื่อออนไลน์",
        errMsg: "ปัญหาในการแยกวิเคราะห์ไฟล์ภาพ",
        errMsg1: "ไม่รองรับประเภทไฟล์",
        errMsg2: "ไม่พบเมื่อออฟไลน์"
      },
      badlistMatch:{
        backLabel:"ป้ายหลัง",
        batchCode:"รหัสแบทช์",
        position:"ตำแหน่งปัจจุบัน",
        offlineMsg:"คุณกำลังออฟไลน์อยู่\nคุณยังสามารถส่งผู้ต้องสงสัยรายใหม่เพื่ออัปโหลดในภายหลัง",
        failureMsg: "ขออภัย การรายงานการมองเห็นล้มเหลว",
        sightingConfirmed:"นี่คือสินค้าใช่ไหม? หากมีรหัสชุด มันจะดูเหมือนในภาพด้านบนทุกประการหรือไม่",
        no: "เลขที่",
        yes: "ใช่",
        whereDidYouSee: "คุณเห็นมันที่ไหน?",
        currentLocation: "ใช้ตำแหน่งปัจจุบันของฉัน",
        differentLocation: "เลือกสถานที่อื่น",
        comment: "คุณต้องการเพิ่มความคิดเห็นหรือไม่?",
        commentPlaceholder: "ข้อสังเกตเพิ่มเติม...",
        processing: "..กำลังประมวลผล..",
        sendPg: "ส่งไปที่พีแอนด์จี",
      },
      locationSelect: {
        currentposition: "ตำแหน่งปัจจุบัน",
        picked:"เลือกบนแผนที่",
        useCurrentLocation: "ใช้ตำแหน่งปัจจุบันของฉัน",
        useAddress:  "ใช้ที่อยู่ข้างต้น",
        savedLocation: "เลือกตำแหน่งที่บันทึกไว้:",
      },
      menu:{
        locations: "สถานที่",
        resumeUpload:"อัปโหลดต่อ",
        pauseUpload:"หยุดการอัปโหลดชั่วคราว",
        manageDevice: "จัดการอุปกรณ์",
        account: "บัญชี",
        cache:"ล้างแคช",
        quit: "ออกจากแอป",
      },
      scanEAN: {
        align:"โปรดจัดแนวบาร์โค้ดในกรอบด้านบนเพื่อสแกน",
        camera: "เลือกกล้อง",
        rescan:"สแกนอีกครั้ง",
        ok:"ตกลง",
        scan:"กำลังสแกนหาบาร์โค้ด ...",
      },
      store:{
        address: "452, Basingstoke Road, Whitley, Reading, England, RG2 0JF, สหราชอาณาจักร",
      },
      manageDevices: {
        edit: "แก้ไข",
        done: "เสร็จสิ้น",
        manageD: "จัดการอุปกรณ์",
        thisDevice: "อุปกรณ์นี้",
        active: "เซสชันปัจจุบัน",
        move: "ย้ายไปยังอุปกรณ์ใหม่",
        unlink: "อุปกรณ์นี้จะถูกยกเลิกโดยอัตโนมัติหลังจากคุณเชื่อมต่ออุปกรณ์ใหม่",
        generateCode: "สร้างโค้ดการโยกย้าย"
      },
      page_reg: {
        "enterCode": "ป้อนรหัสการลงทะเบียน",
        "enterMig": "ป้อนรหัสการย้ายข้อมูล",
        "regCode": "รหัสการลงทะเบียน",
        "inviteCode": "รหัสเชิญชวน",
        "register": "ลงทะเบียน",
        "enterMigGenerated": "ป้อนวันที่สร้างจากบัญชีของคุณที่มีอยู่เพื่อส่งต่อไปยังการลงทะเบียน",
        "migCode": "รหัสการโยกย้าย"
      }
      
  
  };
  