export default {
    app: {
        selectLang: "भाषा चुनें",
        messageText1:
            "कृपया हमें बताएं कि आपने नकली उत्पाद को कहां देखा है, 'पहुँच स्थान' अनुमति देने के द्वारा।",
        messageText2:
            "यह ऐप आपके ब्राउज़र में काम करना चाहिए, लेकिन सबसे अच्छा अनुभव पाने के लिए हम आपको Chrome या Safari का उपयोग करने की सलाह देते हैं।",
        messageText3:
            "क्षमा करें, आपके ब्राउज़र ने स्टोरेज एक्सेस को निषेधित किया है। कृपया मदद पृष्ठ देखें।",
    },
    page_check: {
        textOffline:
            "आप वर्तमान में ऑफ़लाइन हैं। आप फिर से अपलोड के लिए नए संदेहियों को जमा कर सकते हैं।",
        textUnauthorized:
            "क्षमा करें, डेटाबेस खोज विफल हुई।\n\n कृपया जांचें कि आपका पंजीकरण वैध है।",
        dbFailed: "क्षमा करें, डेटाबेस खोज विफल हुई।",
        thanksMessage: "इस नकली उत्पाद की रिपोर्ट करने के लिए धन्यवाद",
        checkGenuine: "जांचें कि आपका उत्पाद वास्तविक P&G है:",
        barCode: "बार कोड",
        gtinBarcode: "GTIN बारकोड",
        scanBtn: "स्कैन करें",
        batchCode: "बैच कोड",
        leaveBlank: "यदि उत्पाद पर बैच कोड नहीं है, तो खाली छोड़ दें",
        newSuspect: "नया संदेही",
        searchList: "खोज सूची",
        offline: "आप वर्तमान में ऑफ़लाइन हैं।",
        noEntries: "नकली उत्पादों की कोई प्रविष्टियाँ नहीं मिली।",
        fake1: "1 ज्ञात नकली उत्पाद:",
        handMatch: "क्या यह आपके हाथ में उत्पाद से मेल खाता है?",
        knownFake: "ज्ञात नकली उत्पाद:",
        imageMatch: "क्या इन उत्पादों की छवियाँ आपके हाथ में उत्पाद से मेल खाती हैं?",
        clickItem: "विवरण के लिए आइटम पर क्लिक करें। कृपया बैच कोड पर विशेष ध्यान दें।",
        productImage: "उत्पाद छवि",
        batch: "बैच",
        seeMore: "और देखें",
        about: "के बारे में",
        dataPolicy: "डेटा नीति",
        privacy: "गोपनीयता",
        privacyChoices:"आपकी गोपनीयता विकल्प",
        tnc: "नियम और शर्तें",
        allrights: "सभी अधिकार सुरक्षित",
    },
    page_reportNewFake: {
        additionalImage: "अतिरिक्त छवि। कृपया नीचे टिप्पणी जोड़ें ताकि यह स्पष्ट हो सके।",
        fileNotSupported: "फ़ाइल प्रकार समर्थित नहीं है",
        uploadMsg: "पिछले प्लेटफ़ॉर्म पर अपलोड हो रहा है; \nकुछ सेकंड लग सकते हैं।",
        close: "बंद करें",
        submitPhotos: "फ़ोटो जमा करें",
        suspectProduct: "संदेहास्पद उत्पाद",
        required: "आवश्यक",
        additionalImage1: "अतिरिक्त छवि",
        additionalImage2: "यदि आपको लगता है कि यह मददगार होगा, तो एक अतिरिक्त छवि जोड़ें।",
        comment: "टिप्पणी",
        additionalImagePlaceholder: "अतिरिक्त टिप्पणियाँ और/ या अवलोकन।",
        checkBatchcode: "बैच कोड सही है?",
        batchCode: "बैच कोड",
        whereDidYouSee: "आपने इसे कहां देखा?",
        useCurrentLocation: "मेरे वर्तमान स्थान का उपयोग करें",
        differentLocation: "विभिन्न स्थान चुनें",
        processing: "…प्रोसेसिंग…",
        submit: "ऊपर दिए गए पते का उपयोग करके जमा करें",
        titleReceipt: "रसीद",
        instrReceipt: "विक्रय रसीद की एक फ़ोटो जोड़ें(वैकल्पिक) ।",
        titleGTIN: "बार कोड",
        instrGTIN: "कृपया उत्पाद बारकोड(GTIN) की क्लोज़- अप फ़ोटो लें।",
        titleBatch: "बैच कोड",
        instrBatch: "कृपया बैच कोड की एक फ़ोटो लें।",
        titleBackLabel: "पीछे की लेबल",
        instrBackLabel: "कृपया पूरी उत्पाद पीछे की लेबल की एक फ़ोटो लें।",
    },
    page_locations: {
        permissionIDB: "दुर्भाग्यवश, इस ऐप को आपके डिवाइस पर ब्राउज़र स्टोरेज तक पहुँचने की अनुमति नहीं है।",
        browserPermission: "कृपया अपनी ब्राउज़र अनुमति सेटिंग्स की जांच करें और Privacy & Security के तहत site data की अनुमति दें।",
        addLocation: "स्थान जोड़ें",
        locationName: "स्थान का नाम: ",
        nameTaken: "नाम पहले से लिया जा चुका है",
        placeName: "स्थान का नाम",
        search: "खोजें: ",
        NameAndAdd: "नाम और पता",
        locationImage: "स्थान की छवि",
        address: "पता: ",
        coordinates: "निर्देशांक: ",
        here: "यहाँ",
        save: "सहेजें",
        storedLocations: "संग्रहीत स्थान",
        searchSaved: "सहेजे गए स्थानों में खोजें: ",
        filterByName: "नाम से फ़िल्टर करें",
        deleteAllPlaces: "सभी स्थानों को हटा दें।"
    },
    page_settings:{
        "register": "कृपया पंजीकरण के लिए ईमेल दर्ज करें।",
        "inviteCode": "पंजीकरण के लिए आपको एक निमंत्रण कोड की आवश्यकता है।",
        "registerFailed": "क्षमा करें, पंजीकरण विफल रहा। (सर्वर त्रुटि)",
        "registerSuccess": "आपका पंजीकरण सफल था",
        "registerFailed1": "क्षमा करें, आपके पंजीकरण में कुछ गलत हुआ।",
        "offline": "आप वर्तमान में ऑफलाइन हैं।\nकृपया बाद में पुनः प्रयास करें।",
        "registerFailed2": "क्षमा करें, पंजीकरण विफल रहा क्योंकि:\n",
        "reinvite": "पुनः निमंत्रण के लिए एक मान्य ईमेल पता आवश्यक है।",
        "invite": "एक निमंत्रण ईमेल भेज दिया गया है ",
        "inviteFailed": "निमंत्रण भेजने में ",
        "failed": " विफल रहा।",
        "permissionIDB":"दुर्भाग्य से, यह ऐप आपके उपकरण पर ब्राउज़र संग्रहण तक पहुँच नहीं सकता है ताकि आपकी लॉगिन विवरणों को रख सके।",
        "yourAccount":"आपका खाता",
        "siteDataPermission":"कृपया अपनी ब्राउज़र अनुमति सेटिंग्स की जांच करें और 'Privacy & Security' के तहत 'site data' की अनुमति दें।",
        "registeredName":"आप पंजीकृत हैं जैसा कि",
        "registeredEmail": "ईमेल के साथ",
        "accountStatus": "आपकी खाता स्थिति है",
        "regExp": "पंजीकरण समाप्त होता है",
        "deleteRegistration": "पंजीकरण हटाएं / रीसेट करें",
        "email": "ईमेल",
        "emailPlaceholder": "आपका ईमेल पता",
        "emailAndCode":"यदि आपको आमंत्रित किया गया है,\n तो कृपया 'Register' पर क्लिक करने से पहले ईमेल और कोड दर्ज करें।",
        "code": "कोड",
        "inviteCode":"निमंत्रण कोड",
        "register1": "पंजीकरण",
        "regCode": "पंजीकरण कोड",
        "migCode": "आपके पास माइग्रेशन कोड है क्या?"
    },
    page_about: {
        "privacy": "गोपनीयता",
        "cookies": "यह वेब सेवा कुकीज़ का उपयोग नहीं करती है।",
        "warning": "जैसा कि आप एक संदिग्ध नकली उत्पाद की एक रिपोर्ट सबमिट करते हैं, ऐप निम्नलिखित जानकारी को संग्रहित करता है और सर्वर को भेजता है:",
        "details": "उत्पाद का विवरण - GTIN, छवियाँ",
        "timeAndDate": "रिपोर्ट का समय और तिथि",
        "location": "रिपोर्ट का स्थान",
        "email": "ईमेल पता",
        "browserInfo": "ब्राउज़र जानकारी",
        "referLink": "अधिक जानकारी के लिए, संदर्भ दें",
        "privacy": "गोपनीयता नीति"
    },
    page_help:{
      about: "के बारे में",
      aboutDesc: "वास्तविक वेब आधारित ऐपलिकेशन आपको एक संभालक P&G उत्पाद की वास्तविकता या गलत उत्पाद है या नहीं है, इसके लिए उत्पाद के ग्लोबल ट्रेड आइटम नंबर (GTIN) को स्कैन करने या दर्ज करने के माध्यम से खोज और सत्यापित करने की अनुमति देता है।",
      aboutDesc1: "पंजीकृत उपयोगकर्ता के रूप में, ऐप आपको संभालक फ़ोटो लेने और सबमिट करने की अनुमति देता है। रिपोर्टिंग एक दृश्य के लिए ऑनलाइन और ऑफलाइन मोड के लिए उपलब्ध होता है।",
      counterfeit: "गलत दृश्यों पर रिपोर्ट करें",
      counterfeitDesc: "जब आप एक पहचान गए गलत उत्पाद पाते हैं और उस दृश्य को रिपोर्ट करने की अनुमति देते हैं, तो आपको एक डायलॉग में भेजा जाता है जहां आप P&G के साथ विस्तृत जानकारी साझा कर सकते हैं।",
      counterfeitDesc1: "हमें बताने के लिए कि आप गलत उत्पाद कहाँ देखा था, हमें हमारे प्रभाकारों और हमारे ब्रांड को सुरक्षित करने में मदद करता है। कृपया रिपोर्ट करने से पहले टिप्पणी छोड़ें।",
      support : "आपकी सहयोग वास्तव में स्वीकार की जाती है।",
      reportFake: "गलत रिपोर्ट= संभालक",
      welcomeMsg: "आपको P&#38;G द्वारा व्यक्तिगत रूप से इस ऐप का उपयोग करने की आवश्यकता है, न केवल जाने गए गलत उत्पादों के साथ उत्पादों को तुलना करने के लिए, बल्कि नए संभालक उत्पादों की रिपोर्ट भी करने के लिए।",
      instruction: "कृपया P&#38;G के साथ विस्तृत निर्देशों के लिए संपर्क करें।",
    },
    page_tnc:{
      tnc: "शर्तें और सर्तें",
      linkDesc: "यह साइट पर की गई जानकारी का उपयोग और एक्सेस निम्नलिखित",
      linkDesc1: "हमारे लेगल समझौते के तहत निर्धारित किया गया है।",
    },
    page_dp:{
      dataPolicy: "डेटा नीति",
      cookies:"यह वेब सेवा कुकीज़ नहीं उपयोग करती है।",
      dpDesc:"जब आप एक संभालक उत्पाद की रिपोर्ट सबमिट करते हैं, ऐप निम्नलिखित जानकारी को सर्वर को संग्रहित और भेजता है:",
      productDetails: "उत्पाद की विवरण - GTIN, तस्वीरें",
      tnd:"रिपोर्ट का समय और दिनांक",
      location: "रिपोर्ट का स्थान",
      email: "ईमेल पता",
      browserInfo: "ब्राउज़र जानकारी",
    },
    minion:{
        offlineMsg: "आप वर्तमान में ऑफलाइन हैं।\n\nआपकी तस्वीरें\nआप ऑनलाइन होने पर अपलोड होंगी।",
        uploadMsg:"धन्यवाद, अपलोड सफल हुआ!",
        uploadMsg1:"अपलोड किया गया था अपेक्षा से अधिक लंबा।\nकृपया बेहतर कनेक्शन के साथ फिर से प्रयास करें।",
        uploadMsg2:"क्षमा करें, अपलोड विफल हुआ।",
        uploadMsg3:"डेटा अपलोड रद्द किया गया,\nक्योंकि यह अपेक्षा से अधिक लंबा लग गया।\nकृपया बेहतर कनेक्शन के साथ फिर से प्रयास करें।",
        uploadMsg4:"सभी डेटा अपलोड नहीं किया जा सका,\nकृपया ऑनलाइन होने पर फिर से प्रयास करें।",
        errMsg: "तस्वीर फ़ाइल पार्स करने में समस्या।",
        errMsg1: "समर्थित फ़ाइल प्रकार नहीं है।",
        errMsg2: "ऑफलाइन में नहीं मिल सकता।"
      },
    badlistMatch:{
        backLabel:"पीछे का लेबल",
        batchCode:"बैच कोड",
        position:"वर्तमान स्थिति",
        offlineMsg:"आप वर्तमान में ऑफलाइन हैं।\nआप अभी भी बाद में नए संभालक को अपलोड करने के लिए जमा कर सकते हैं।",
        failureMsg: "क्षमा करें, दृश्य रिपोर्ट करने विफल हुआ।",
        sightingConfirmed:"यह उत्पाद है? यदि इसमें बैच कोड है, तो यह उपरोक्त तस्वीर में कैसे दिखता है?",
        no: "नहीं",
        yes: "हाँ",
        whereDidYouSee: "आप कहाँ देखा?",
        currentLocation: "मेरी वर्तमान स्थिति का उपयोग करें",
        differentLocation: "अलग स्थान चुनें",
        comment: "क्या आप टिप्पणी जोड़ना चाहते हैं?",
        commentPlaceholder: "अतिरिक्त परिक्षण...",
        processing: "..प्रसंस्करण कर रहा है..",
        sendPg: " P&G को भेजें",
    },
    locationSelect: {
        currentposition: "वर्तमान स्थिति",
        picked:"मानचित्र पर चयनित",
        useCurrentLocation: "मेरी वर्तमान स्थिति का उपयोग करें",
        useAddress: "उपरोक्त पते का उपयोग करें",
        savedLocation: "एक संग्रहित स्थान का चयन करें:",
    },
    menu:{
        locations: "स्थान",
        resumeUpload:"अपलोड को रिसम करें",
        pauseUpload:"अपलोड को रुकान",
        manageDevice: "डिवाइस प्रबंधित करें",
        account: "खाता",
        cache:"कैश को साफ करें",
        quit: "ऐप से बाहर निकलें",
    },
    scanEAN: {
        align:"कृपया उपरोक्त क्षेत्र में बारकोड को स्कैन करने के लिए संयुक्त करें",
        camera: "कैमरा चुनें",
        rescan:"फिर से स्कैन करें",
        ok:"ठीक है",
        scan:"बारकोड की खोज में ...",
    },
    store:{
        address: "452, Basingstoke Road, Whitley, Reading, England, RG2 0JF, United Kingdom",
    },
    manageDevices: {
        edit: "संपादित करें",
        done: "सम्पन्न",
        manageD: "डिवाइसेस का प्रबंधन करें",
        thisDevice: "यह डिवाइस",
        active: "एक्टिव सेशन्स",
        move: "नया डिवाइस में शिफ्ट करें",
        unlink: "आप एक नया डिवाइस जोड़ते ही इस डिवाइस को ऑटोमैटिक रूप से अनबांध दिया जाएगा",
        generateCode: "माइग्रेशन कोड उत्पन्न करें"
      },
      page_reg: {
        "enterCode": "रजिस्ट्रेशन कोड दर्ज करें",
        "enterMig": "माइग्रेशन कोड दर्ज करें",
        "regCode": "रजिस्ट्रेशन कोड",
        "inviteCode": "निमंत्रण कोड",
        "register": "पंजीकरण करें",
        "enterMigGenerated": "अपने मौजूदा खाते से उत्पन्न हुई गई माइग्रेशन कोड दर्ज करें, जिससे पंजीकरण ट्रांसफर हो सके。",
        "migCode": "माइग्रेशन कोड"
      }
      
};
   
    
    
    
    