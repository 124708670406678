<!--
	genuinePG frontend

	Modal, opened from Page_check.svelte

    Shows details of BADlist match and allows user
    to report sighting to P&G

    @file         Modal_badlistMatch.svelte
    @copyright    P&G
-->

<script>
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  export let theme;
  import {
    URI_API,
    URI_IMG,
    deepCopyObject,
    toDecrypt,
    toEncrypt,
  } from "./minions";

  import M_locationSelect from "./Modal_locationSelect.svelte";

  import * as pako from "pako";

  export let badlistMatch;

  // ============================= store variables =============================

  import {
    st_email,
    st_userRegistered,
    st_connected,
    st_location_available,
    st_location_latitude,
    st_location_longitude,
    st_location_accuracy,
  } from "./stores";

  // user management
  let email;
  let userRegistered = false;
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
  });
  st_email.subscribe((value) => {
    email = value;
  });

  // geolocation
  let location_available;
  let location_latitude;
  let location_longitude;
  let location_accuracy;
  st_location_available.subscribe((value) => {
    location_available = value;
  });
  st_location_latitude.subscribe((value) => {
    location_latitude = value;
  });
  st_location_longitude.subscribe((value) => {
    location_longitude = value;
  });
  st_location_accuracy.subscribe((value) => {
    location_accuracy = value;
  });

  // ================================= Images  =================================

  let imageNames = [];
  let imageTitle = [];

  const beautifyImagename = (imageName) => {
    if (imageName == "backlabel") {
      return $t("badlistMatch.backLabel") ;
    }
    if (imageName == "batch") {
      return $t("badlistMatch.batchCode");
    }
    let name = imageName.charAt(0).toUpperCase() + imageName.slice(1);
    name = name.replace(/_/g, " ");
    return name;
  }; //beautifyImagename()

  // const showImgPopUp = (imageName) => {
  //   document.getElementById("imgPopUp").src = imageName;
  //   document.getElementById("divImgPopUp").style.display = "block";
  // };

  const showImgPopUp = (imageName) => {
    // // console.log("in show popup: ",imageName);
    var image = new Image();
    image.src = imageName;
    let w = window.open("");
    w.document.write(image.outerHTML);
  };

  // const closeImgPopUp = () => {
  //   document.getElementById("divImgPopUp").style.display = "none";
  // };

  // ============================ Location selection ===========================

  import { findAddressFromGeo } from "./minions";
  import { st_lastUsedPlace } from "./stores";

  let place;
  let showLocationSelect = false;

  let lastUsedPlace;
  st_lastUsedPlace.subscribe((value) => {
    lastUsedPlace = value;
  });

  const place_here = async () => {
    if (!location_available) {
      dispatch("retryGeo");
      return;
    }

    place = {
      name: $t("badlistMatch.position"),
      address: "",
      latitude: location_latitude,
      longitude: location_longitude,
      accuracy_m: location_accuracy,
      image: "",
      active: true,
    };

    const address = await findAddressFromGeo(
      location_latitude,
      location_longitude
    );
    place.address = address;
  }; //place_here()

  const locationSelected = (evt) => {
    if (evt.detail.place) {
      place = evt.detail.place;
    }

    showLocationSelect = false;
  }; //locationSelected()

  // ======================= Confirm or Reject Sighting ========================

  import { storeLastUsedPlace } from "./minions";
  import { st_auth } from "./stores";

  let sightingConfirmed = false;
  let comment = "";
  let confirmationSent = false;

  let auth;
  st_auth.subscribe((value) => {
    auth = value;
  });

  const rejectSighting = () => {
    dispatch("close", { answer: "rejected" });
  }; //rejectSighting()

  const confirmSighting = () => {
    sightingConfirmed = true;

    // Set default location to last used location
    place = deepCopyObject(lastUsedPlace);
  }; //confirmSighting()

  const uploadSighting = async () => {
    // Sent only once
    if (confirmationSent) {
      return;
    }
    confirmationSent = true;

    const reply = await fetch(URI_API("report_sighting"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...auth,
        badlistID: badlistMatch.id ? await toEncrypt(badlistMatch.id) : 0,
        ean: badlistMatch.ean ? await toEncrypt(badlistMatch.ean) : 0,
        batch: badlistMatch.batch ? await toEncrypt(badlistMatch.batch) : "",
        email: email ? await toEncrypt(email) : "",
        latitude: place.latitude ? await toEncrypt(place.latitude) : 0.0,
        longitude: place.longitude ? await toEncrypt(place.longitude) : 0.0,
        location_accuracy: place.accuracy_m
          ? await toEncrypt(place.accuracy_m)
          : 0.0,
        timestamp: await toEncrypt(new Date().toISOString()),
        comment: comment ? await toEncrypt(comment.trim()) : "",
        place_name: place.name ? await toEncrypt(place.name) : "",
        place_image: await toEncrypt("placeimagedfgggggh"),
      }),
    });

    if (!reply || reply.status >= 500) {
      st_connected.set(false);
      // // console.log('no connection');
      dispatch("message", {
        text: $t("badlistMatch.offlineMsg"),
      });
    }

    if (reply.ok) {
      // // console.log("Report sighting: OK");
    } else {
      const msg = await reply.text();
      // // console.log(`Reporting the sighting failed because: ${msg}`);
      dispatch("message", { text: $t("badlistMatch.failureMsg") });
    }

    storeLastUsedPlace(place);

    dispatch("close", { answer: "confirmed" });
  }; //uploadSighting()

  // ========================== Swipe right to go back =========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("box-input");

    // // console.log(swipeArea);

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        rejectSighting();
      }
    });
  }; //setupSwipeRight()

  // =========================== Construction / Init ===========================

  import { onMount } from "svelte";
  import { t } from "./i18n";

  onMount(async () => {
    confirmationSent = false;

    // // console.log("badlistMatch", badlistMatch)
    imageNames = badlistMatch.img_prefix.split("|");
    imageTitle = badlistMatch.images.split(",");
  
    const resBadlistDetails = await fetch(URI_API("fetch_badlist_details"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...auth,
        id: await toEncrypt(badlistMatch.id)
      }),
    });

    // // console.log("resBadlistDetails: ",resBadlistDetails);
    let badListDetails;
    try {
      const compressData = await resBadlistDetails.arrayBuffer();
      const decompressData = pako.inflate(new Uint8Array(compressData), {
        to: "string",
      });

      badListDetails = JSON.parse(decompressData);
      // // console.log("badListDetails: ",badListDetails);
    } catch (error) {
      // // console.log("in catch: ", error);
    }

    if(resBadlistDetails.ok){
      if (Object.keys(badListDetails).length) {
        badListDetails[0].images = await toDecrypt(badListDetails[0].images);

        imageNames = badListDetails[0].img_prefix.split("|");
        imageTitle = badListDetails[0].images.split(",");
      }
    }
  
    // Use batch code as first image if available
    imageNames.sort(function (x, y) {
      return x == "batch" ? -1 : y == "batch" ? 1 : 0;
    });

    setupSwipeRight();
  }); //onMount()
</script>

<!-- =============================  H T M L  ============================= -->

<div class="modal" class:light={!theme} class:dark={theme} on:click={close}>
  <div id="frame">
    <div id="box-images" style="overflow: scroll; white-space: nowrap;" class:small={sightingConfirmed}>
      {#each imageNames as name, i}
        <div class="imgBox" style="width: 100%; margin:0 auto; display: inline-block;" class:small={sightingConfirmed}>
            <h3>{beautifyImagename(imageTitle[i])}</h3>
            <img src={name} alt="Image" style="max-width: 100%;" on:click={showImgPopUp(name)}/>
        </div>
      {/each}
    </div>

    <div id="box-input">
      {#if !sightingConfirmed}
        <p>
          {$t("badlistMatch.sightingConfirmed")}
        </p>

        <div class="btn-row">
          <div class="btn" on:click={rejectSighting}>{$t("badlistMatch.no")}</div>
          <div class="btn" on:click={confirmSighting}>{$t("badlistMatch.yes")}</div>
        </div>
      {:else}
        <div>
          <h3>{$t("badlistMatch.whereDidYouSee")}</h3>
          <div id="btns-location">
            <button
              class="btn"
              class:btn-gray={!location_available}
              on:click={place_here}
              title="Use my current location"
            >
            {$t("badlistMatch.currentLocation")}
            </button>
            <button
              class="btn"
              on:click={() => {
                showLocationSelect = true;
              }}
              style="flex:2;"
              title="Choose different location"
            >
            {$t("badlistMatch.differentLocation")}
            </button>
          </div>
          <div id="box-place-info">
            {#if place && place.name && place.address}
              <span style="color: var(--PG-blue);">{place.name}:</span>
              {place.address}
            {/if}
          </div>
        </div>

        <div id="box-comment" class:light={!theme} class:dark={theme}>
          <h3>{$t("badlistMatch.comment")}</h3>

          <div id="box-txt-comment">
            <textarea
              id="txt-comment"
              bind:value={comment}
              maxlength="300"
              placeholder= {$t("badlistMatch.commentPlaceholder")}
            />
          </div>
        </div>

        <div>
          {#if confirmationSent}
            <div class="btn-row">
              <div class="btn">{$t("badlistMatch.processing")}</div>
            </div>
          {:else}
            <div class="btn-row">
              <div class="btn btn-blue" on:click={uploadSighting}>
                {$t("badlistMatch.sendPg")}
              </div>
            </div>
          {/if}
        </div>
      {/if}
    </div>
  </div>

  {#if showLocationSelect}
    <M_locationSelect
      on:selected={locationSelected}
      on:retryGeo={() => dispatch("retryGeo")}
    />
  {/if}
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 214, 218, 0.8);
    display: block;
    z-index: 51;
  }

  #frame {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    /* background-color: white; */
    overflow-x: hidden;
    overflow-y: auto;
  }

  .btn-row {
    display: flex;
    flex-direction: row;
    padding: 0.5em;
  }

  /* #box-images {
    flex: 0 0 80%;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: col;
    background-color: var(--bg-gray);
    overflow-x: auto;
    overflow-y: auto; 
  } */

  #box-images.small {
    flex: 0 0 40%;
    height: 40%;
    transition: 300ms;
  }

  .imgBox {
    height: 100%;
    padding: 1em;
  }
  .imgBox img {
    height: 90%;
    width: 100%;
    object-fit: contain;
  }
  .imgBox h3 {
    font-size: 1.2em;
    font-weight: 700;
  }
  .imgBox.small {
    padding: 0.5em;
  }
  .imgBox.small img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
  .imgBox.small h3 {
    display: none;
  }
  #box-input {
    flex: 2;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  #box-input p {
    font-size: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  #box-comment {
    flex-grow: 3;
    display: flex;
    flex-direction: column;
  }

  #box-txt-comment {
    width: 100%;
    flex-grow: 3;
    text-align: center;
    padding: 0.5em;
  }

  #txt-comment {
    padding: 0.5em;
    width: 100%;
    height: 100%;
    font-size: 1em;
    resize: none;
  }

  #btns-location {
    margin-top: 0.5em;
    width: 100%;
  }
  #btns-location .btn {
    width: 100%;
    margin-bottom: 0.5em;
  }

  #box-place-info {
    margin-top: 0.5em;
    font-size: 0.9em;
    min-height: 4em;
  }

  #carousel {
    width: 100%;
    height: 150px;
    background-color: #ff0000;
    
    overflow: visible;
    white-space:nowrap;
}

#carousel .slide {
    display: inline-block;
    z-index: 999;
}
.light{
    background-color: rgba(211, 214, 218, 0.8);
    color: black;
  }
  .dark{
    background-color: #242424;
    color: #fff;
  }
</style>
