<!--
	genuinePG frontend

	Page, opened from Modal_menu.svelte

    Allows user to save location and manage stored locations

    @file         Page_locations.svelte
    @copyright    P&G
-->

<script>
  import { PAGE } from "./config";
  import { deepCopyObject } from "./minions";
  import {
    ArrowLeftCircleIcon,
    SearchIcon,
    ToggleRightIcon,
    ToggleLeftIcon,
    Trash2Icon,
  } from "svelte-feather-icons";
  import { createEventDispatcher } from "svelte";
  import { t } from "./i18n"
  export let theme;
  let dispatch = createEventDispatcher();

  // ============================= store variables =============================

  import {
    st_permissionIDB,
    st_places,
    st_lastUsedPlace,
    st_location_available,
    st_location_latitude,
    st_location_longitude,
    st_location_accuracy,
  } from "./stores";

  // Places in storage
  let permissionIDB = true;
  st_permissionIDB.subscribe((value) => {
    permissionIDB = value;
  });

  let places = [];
  st_places.subscribe((value) => {
    places = deepCopyObject(value);
  });

  let lastUsedPlace;
  st_lastUsedPlace.subscribe((value) => {
    lastUsedPlace = deepCopyObject(value);
  });

  // geolocation
  let location_available;
  let location_latitude;
  let location_longitude;
  let location_accuracy;
  if(window.localStorage.getItem("store") !==null){
    $st_lastUsedPlace = JSON.parse(window.localStorage.getItem("store"));
  }
  st_location_available.subscribe((value) => {
    location_available = value;
  });
  st_location_latitude.subscribe((value) => {
    location_latitude = value;
  });
  st_location_longitude.subscribe((value) => {
    location_longitude = value;
  });
  st_location_accuracy.subscribe((value) => {
    location_accuracy = value;
  });

  // ========================= Swipe right to go back ==========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("swipeRightToClose");

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        close();
      }
    });
  }; //setupSwipeRight()

  // ============================= Maps -> Leaflet =============================

  import L from "leaflet";

  let map;
  let mapMarker;
  let mapCircle;
  let map_latitude = 51.4278;
  let map_longitude = -0.97127;
  let map_accuracy_m = 1;

  const createMap = async () => {
    // Start with current location if valid
    // if (window.navigator.onLine && location_latitude != 0 && location_longitude != 0) {
    if (location_latitude != lastUsedPlace.latitude && location_longitude != lastUsedPlace.longitude){
      map_latitude = location_latitude;
      map_longitude = location_longitude;
      map_accuracy_m = location_accuracy;
      place_address = await findAddressFromGeo(map_latitude, map_longitude);
      st_lastUsedPlace.update(location =>({
        ...location,
        name: "",
        image: "",
        active: true,
        latitude:location_latitude,
        longitude:location_longitude,
        address:place_address,
        accuracy_m:location_accuracy
      }));
 
      window.localStorage.setItem("store", JSON.stringify($st_lastUsedPlace))
    } else {
      // otherwise start with last used location
      place_name = lastUsedPlace.name;
      map_latitude = lastUsedPlace.latitude;
      map_longitude = lastUsedPlace.longitude;
      map_accuracy_m = lastUsedPlace.accuracy_m;
      place_address = lastUsedPlace.address;
      if (lastUsedPlace.image) {
        place_picture = lastUsedPlace.image;
      } else {
        place_picture = additionalImg_src;
      }
    }

    // New Leaflet map object
    if (map) {
      map.remove();
    }

    map = L.map("map");
    if (map_latitude == 0 && map_longitude == 0) {
      map.fitWorld();
    } else {
      map.setView([map_latitude, map_longitude]);
    }

    if (map_accuracy_m < 20) {
      map.setZoom(19);
    } else if (map_accuracy_m < 200) {
      map.setZoom(16);
    } else if (map_accuracy_m < 2000) {
      map.setZoom(14);
    } else {
      map.setZoom(12);
    }

    map.on("click", async (evt) => {
      map_latitude = evt.latlng.lat;
      map_longitude = evt.latlng.lng;
      map_accuracy_m = 1;
      place_picture = additionalImg_src;
      updateMap();
      place_address = await findAddressFromGeo(map_latitude, map_longitude);
    });

    // Map tiles from OpenStreetMap
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 20,
      minZoom: 5,
      zoomSnap: 0.25,
      zoomDelta: 0.25,
      maxNativeZoom: 19,
      attribution: "©OpenStreetMap",
    }).addTo(map);

    // Map marker
    L.Icon.Default.imagePath = "/img/";
    mapMarker = L.marker([map_latitude, map_longitude], {
      draggable: true,
    }).addTo(map);

    mapMarker.on("dragstart", (evt) => {
      map_accuracy_m = 1;
      place_picture = additionalImg_src;
    });

    mapMarker.on("move", (evt) => {
      map_latitude = evt.latlng.lat;
      map_longitude = evt.latlng.lng;
    });

    mapMarker.on("moveend", async (evt) => {
      updateMap();
      place_address = await findAddressFromGeo(map_latitude, map_longitude);
    });

    // Accuracy circle
    mapCircle = L.circle([map_latitude, map_longitude], {
      color: "none",
      fillColor: "var(--PG-blue)",
      fillOpacity: 0.25,
      radius: map_accuracy_m,
    }).addTo(map);
  }; //createMap()

  const updateMap = () => {
    if (!map) {
      return;
    }

    map.setView([map_latitude, map_longitude]);

    if (mapMarker) {
      mapMarker.setLatLng([map_latitude, map_longitude]);
    }

    if (mapCircle) {
      mapCircle.setLatLng([map_latitude, map_longitude]);
      mapCircle.setRadius(map_accuracy_m);
    }
  }; //updateMap()

  // ============================== Add location ===============================

  import { placeImg_maxSideLength_px } from "./config";
  import {
    additionalImg_src,
    pictureFromExtPhotoApp,
    storePlace,
    deleteStoredPlace,
    readStoredPlaces,
    findAddressFromGeo,
  } from "./minions";

  let place_name = "";
  let place_address = "";
  let place_picture = additionalImg_src;
  let searchAddress = "";
  let readyToSavePlace = false;
  let placeNameExits = false;

  const validatePlaceName = () => {
    // allow whatever?
    // let str = place_name;
    // str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    // place_name = str;

    placeNameExits = false;

    readyToSavePlace =
      place_name.length > 1 && !(map_latitude == 0 && map_longitude == 0);

    // check that name is unique
    if (places && place_name in places) {
      placeNameExits = true;
      readyToSavePlace = false;
    }
  }; //validatePlaceName()

  const place_here = async () => {
    if (!location_available) {
      dispatch("retryGeo");
      return;
    }

    place_name = "";
    place_address = "";
    map_latitude = location_latitude;
    map_longitude = location_longitude;
    map_accuracy_m = location_accuracy;
    place_picture = additionalImg_src;
    searchAddress = "";

    updateMap();
    place_address = await findAddressFromGeo(map_latitude, map_longitude);
  }; //place_here()

  const findGeoFromAddress = async () => {
    if (!searchAddress || searchAddress.length < 5) {
      return;
    }

    const reply = await fetch(
      `https://nominatim.openstreetmap.org/search?q="${searchAddress}"&format=json`
    ).catch((err) => {
      // console.log(err);
      return;
    });

    if (reply && reply.ok) {
      const data = await reply.json();
      if (data && data.length > 0) {
        const match = data[0];
        // // console.log(match)
        if ("lat" in match && "lon" in match && "display_name" in match) {
          map_latitude = parseFloat(match.lat);
          map_longitude = parseFloat(match.lon);
          map_accuracy_m = 1;
          place_address = match.display_name;
          place_picture = additionalImg_src;
          place_name = searchAddress;
          validatePlaceName();

          if ("boundingbox" in match && match.boundingbox.length == 4) {
            // // console.log(match.boundingbox);
            const diameter = L.latLng([
              match.boundingbox[0],
              match.boundingbox[2],
            ]).distanceTo(
              L.latLng([match.boundingbox[1], match.boundingbox[3]])
            );
            map_accuracy_m = diameter / 4;
          }
        }

        updateMap();
      }
      // // console.log(data);
    }
  }; //findGeoFromAddress()

  const addPlacePicture = async () => {
    place_picture = await pictureFromExtPhotoApp(placeImg_maxSideLength_px);
  }; //addPlacePicture()

  const savePlace = async () => {
    if (!readyToSavePlace) {
      return;
    }

    let image = place_picture;
    if (image.includes("image/svg+xml;")) {
      image = "";
    }

    const newPlace = {
      name: place_name.trim(),
      latitude: map_latitude,
      longitude: map_longitude,
      accuracy_m: map_accuracy_m,
      address: place_address,
      image: image,
      active: true,
      timestamp: new Date(),
    };

    readyToSavePlace = false;

    await storePlace(newPlace);
    await readStoredPlaces();
    updatePlaceLstFilter();

    searchAddress = "";
  }; //savePlace()

  // ============================ Manage place list ============================

  import { clearDbPlaces } from "./minions";

  let placeLst_filter = "";
  let placesFiltered = [];

  const updatePlaceLstFilter = () => {
    placesFiltered = Object.values(places);

    // filter by name and address
    if (
      placeLst_filter &&
      placeLst_filter.length > 0 &&
      placesFiltered.length > 0
    ) {
      placesFiltered = placesFiltered.filter((item) => {
        if (item.name && item.name.includes(placeLst_filter)) {
          return true;
        }
        if (item.address && item.address.includes(placeLst_filter)) {
          return true;
        }
        return false;
      });
    }

    // prepare for chronological sorting
    for (let i = 0; i < placesFiltered.length; i++) {
      if (!("timestamp" in placesFiltered[i])) {
        placesFiltered[i].timestamp = new Date(2020, 1, 1);
      } else {
        // convert date strings to date object
        placesFiltered[i].timestamp = new Date(placesFiltered[i].timestamp);
      }
    }

    const activePlaces = placesFiltered.filter((item) => {
      return item.active;
    });
    activePlaces.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));

    const sleepingPlaces = placesFiltered.filter((item) => {
      return !item.active;
    });
    sleepingPlaces.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));

    placesFiltered = activePlaces.concat(sleepingPlaces);

    // without chronological sorting, for reference only
    // placesFiltered.sort((a,b)=> Number(b.active) - Number(a.active));
  }; //updatePlaceLstFilter()

  const selectPlaceFromList = (place) => {
    if (!place.active) {
      return;
    }

    place_name = place.name;
    place_address = place.address;
    map_latitude = place.latitude;
    map_longitude = place.longitude;
    map_accuracy_m = place.accuracy_m;
    if (place.image) {
      place_picture = place.image;
    } else {
      place_picture = additionalImg_src;
    }

    updateMap();
    searchAddress = "";

    // scroll up to map
    document
      .getElementById("page-locations")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }; //selectPlaceFromList()

  const setPlaceStatus = async (place, active) => {
    if (!(place.name in places)) {
      return;
    }

    place.active = active;

    // Quick update in session
    places[place.name].active = active;
    updatePlaceLstFilter();

    // save and sync iDB with UI
    await storePlace(place);
    await readStoredPlaces();
    updatePlaceLstFilter();
  }; //setPlateStatus()

  const deletePlace = async (place) => {
    if (!(place.name in places) || place.active) {
      return;
    }

    // Quick update in session
    placesFiltered = placesFiltered.filter((item) => {
      return item.name != place.name;
    });

    // delete and sync iDB with UI
    await deleteStoredPlace(place);
    await readStoredPlaces();
    updatePlaceLstFilter();
  }; //deletePlace()

  const deleteAllPlaces = async () => {
    placesFiltered = [];
    place_name = "";
    place_address = "";
    place_picture = additionalImg_src;
    searchAddress = "";
    readyToSavePlace = false;

    clearDbPlaces();
  }; //deleteAllPlaces()

  // =========================== Construction / Init ===========================

  import { onMount, onDestroy } from "svelte";

  onMount(async () => {
    setupSwipeRight();
    await createMap();
    updatePlaceLstFilter();
    let anchorlist = document.getElementsByTagName("a");
    for (let i = 0; i < anchorlist.length; i++) {
      if (anchorlist[i].href.toLowerCase() == "https://leafletjs.com/") {
        anchorlist[i].setAttribute("target", "_blank");
        break;
      }
    }
  }); //onMount()

  const close = () => {
    dispatch("close", { page: PAGE.check });
  }; //close()

  onDestroy(async () => {
    if (map) {
      map.remove();
    }
  }); //onDestroy()
</script>

<!-- =============================  H T M L  ============================= -->

<div class="page" id="page-locations" class:light={!theme} class:dark={theme}>
  <div id="map" style="z-index: 20;"/>

  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->

  <div id="swipeRightToClose" >
    <button class="btn-back" class:textBlue={!theme} class:dark={theme}  on:click={() => close(PAGE.check)} title="Back">
      <ArrowLeftCircleIcon size="2.5x" />
    </button>
    {#if !permissionIDB}
      <p>
        {$t("page_locations.permissionIDB")}
      </p>
      <p>
        {$t("page_locations.browserPermission")}
      </p>
    {:else}
      <h2>{$t("page_locations.addLocation")}</h2>

      <div class="input-txt">
        <div class="cont-row">
          <label for="txt-name" class:textBlue={!theme} class:dark={theme}>{$t("page_locations.locationName")}</label>
          {#if placeNameExits}
            <div id="placeNameExitsWarning">{$t("page_locations.nameTaken")}</div>
          {/if}
        </div>
        <div class="cont-row">
          <input
            id="txt-name"
            bind:value={place_name}
            on:input={validatePlaceName}
            autocomplete="off"
            type="text"
            class:inputBox={!theme} class:grayInputBox={theme}
            placeholder= {$t("page_locations.placeName")}
          />
        </div>
      </div>
      <div class="input-txt">
        <div class="cont-row">
          <label for="txt-address" class:textBlue={!theme} class:dark={theme}>{$t("page_locations.search")}</label>
        </div>
        <div class="cont-row">
          <input
            id="txt-address"
            bind:value={searchAddress}
            autocomplete="off"
            type="text"
            class:inputBox={!theme} class:grayInputBox={theme}
            placeholder={$t("page_locations.NameAndAdd")}
          />
          <button
            id="btn-searchAddress"
            on:click={findGeoFromAddress}
            title="Search"
          >
            <SearchIcon size="1.5x" />
          </button>
        </div>
      </div>

      <div class="box-place">
        <button
          class="box-place-img"
          style="height: 100%;"
          on:click={addPlacePicture}
          title="Location Image"
        >
          <img src={place_picture} alt= {$t("page_locations.locationImage")}/>
        </button>

        <div class="box-place-address">
          <div>
            <h4>{$t("page_locations.address")}</h4>
            {place_address}
            <h4>{$t("page_locations.coordinates")}</h4>
            Lat: {map_latitude.toFixed(3)}, Lon: {map_longitude.toFixed(3)}
          </div>
        </div>
      </div>

      <div class="cont-row" style="margin-top: 1em; margin-bottom: 3em;">
        <button
          id="btn-here"
          class="btn"
          class:btn-gray={!location_available}
          class:btnDark={theme}
          on:click={place_here}
          title="Add Location"
        >
          {$t("page_locations.here")}
        </button>
        <button
          id="btn-save"
          on:click={savePlace}
          class="btn btn-gray"
          class:btnDark={theme && readyToSavePlace}
          class:btn-blue={readyToSavePlace}
          title="Save"
        >
        {$t("page_locations.save")}
        </button>
      </div>

      <h2>{$t("page_locations.storedLocations")}</h2>

      <div class="input-txt">
        <div class="cont-row">
          <label for="txt-filter" class:textBlue={!theme} class:dark={theme}>{$t("page_locations.searchSaved")}</label>
        </div>
        <div class="cont-row">
          <input
            id="txt-filter"
            bind:value={placeLst_filter}
            on:input={updatePlaceLstFilter}
            type="text"
            class:inputBox={!theme} class:grayInputBox={theme}
            placeholder= {$t("page_locations.filterByName")}
          />
        </div>
      </div>

      <div id="box-placeLst">
        {#each placesFiltered as place}
          <div class="box-place" on:click={() => selectPlaceFromList(place)}>
            <div class="box-place-img" class:deactivated={!place.active}>
              {#if place.image}
                <img src={place.image} alt="Place Image" />
              {:else}
                <img src="/img/marker-icon-placeholder.png" alt="Marker Icon" />
              {/if}
            </div>

            <div class="box-place-address" class:deactivated={!place.active}>
              {#if place.active}
                <div
                  class="box-place-btn"
                  on:click|stopPropagation={() => setPlaceStatus(place, false)}
                >
                  <ToggleRightIcon size="1.8x" />
                </div>
              {:else}
                <div
                  class="box-place-btn"
                  on:click|stopPropagation={() => setPlaceStatus(place, true)}
                >
                  <ToggleLeftIcon size="1.8x" />
                </div>
              {/if}
              <h4>{place.name}</h4>
              {place.address}
              {#if !place.active}
                <div
                  class="box-place-btn"
                  on:click|stopPropagation={() => deletePlace(place)}
                >
                  <Trash2Icon size="1.6x" />
                </div>
              {/if}
            </div>
          </div>
        {/each}
      </div>
      

      {#if placesFiltered.length > 0}
        <button
          id="btn-deletePlaceDB"
          class="btn"
          on:click={deleteAllPlaces}
          class:btnDark={theme}
          title="Delete"
        >
          {$t("page_locations.deleteAllPlaces")}
        </button>
      {/if}

      <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
            <ArrowLeftCircleIcon size='2x' />
        </div> -->
      <button class="btn-back" class:textBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
        <ArrowLeftCircleIcon size="2.5x" />
      </button>
    {/if}
  </div>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  .btn-back {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border: none;
    background: none;
  }

  #map {
    margin-top: -3em;
    display: block;
    width: calc(100% + 6em);
    margin-left: -3em;
    height: 28em;
    overflow: hidden;
  }

  .input-txt {
    margin-bottom: 1em;
    width: 100%;
    text-align: left;
    color: var(--PG-blue);
  }
  .input-txt label {
    margin-left: 10px;
    line-height: 30px;
  }

  .input-txt input {
    line-height: 1.4em;
    width: 100%;
    font-size: 1.4em;
    padding: 0.2em 0.6em 0.2em 0.6em;
    border-radius: 50vh;
  }
  #placeNameExitsWarning {
    font-size: 0.7em;
    position: absolute;
    right: 3em;
    color: black;
  }
  #txt-address {
    border-radius: 50vh 0px 0px 50vh;
  }
  #btn-searchAddress {
    color: white;
    font-size: 1em;
    background-color: var(--PG-blue);
    width: 2.7em;
    border-radius: 0px 50vh 50vh 0px;
    border: none;
    height: 2.65em;
    margin-left: -1px;
    border: none;
  }

  .box-place {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .box-place-img {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    flex: 0 0 5.7em;
    width: 5.7em;
    height: 5.7em;
    padding: none;
    border: 1px solid var(--txt-gray);
    margin-right: 1em;
    overflow: hidden;
  }
  .box-place-img img {
    width: 105%;
    object-fit: contain;
    margin: -1px;
  }
  .box-place-img.deactivated img {
    filter: grayscale(100%);
  }

  .box-place-btn {
    float: right;
    margin: 0 0 0.5em 0.5em;
  }

  .box-place-address {
    font-size: 0.9em;
  }

  .box-place-address h4 {
    margin-top: 0px;
  }
  .box-place-address.deactivated h4 {
    color: #777;
  }

  #btn-deletePlaceDB {
    width: 100%;
    margin-top: 2em;
  }
  .light{
    background-color: var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #242424;
  }
  .inputBox{
    background-color:  white;
    color: #242424;
    border: 1px solid var(--PG-blue);
  }
  .grayInputBox{
    color: #fff;
    background-color: #5a5757;
    border: 1px solid #ffffff;
  }
  .textBlue{
    color: var(--PG-blue);
  }
  .btnDark{
    background-color: var(--PG-blue);
    border: 1px solid #ffffff;    
    color: #ffffff;
  }
</style>
