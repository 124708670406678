export default {
    app: {
      selectLang: "Selecione o idioma",
      messageText1:
        "Por favor, permita-nos saber onde você viu o produto falso, dando a permissão 'Local de acesso'.",
      messageText2:
        "Este app deve funcionar no seu navegador,\nymas para uma melhor experiência, recomendamos usar o Chrome ou o Safari",
      messageText3:
        "Desculpe, seu navegador proíbe o acesso ao armazenamento. Por favor, consulte a página de ajuda.",
    },
    page_check: {
      textOffline:
        "Você está off-line no momento.\nVocê ainda pode enviar novos suspeitos para upload mais tarde.",
      textUnauthorized:
        "Lamentamos, mas a pesquisa na base de dados falhou.\n\n Verifique se o seu registo é válido.",
      dbFailed: "Desculpe, a pesquisa no banco de dados falhou.",
      thanksMessage: "Obrigado por denunciar este produto falso",
      checkGenuine: "Verifique se o seu produto é genuíno P&G:",
      barCode: "Código de barras",
      gtinBarcode: "Código de barras GTIN",
      scanBtn: "Varredura",
      batchCode: "Código de lote",
      leaveBlank: "Deixe em branco se nenhum código de lote estiver presente no produto",
      newSuspect: "Novo suspeito",
      searchList: "Lista de pesquisa",
      offline: "Você está off-line no momento.",
      noEntries :"Não foram encontradas entradas de produtos falsificados.",
      fake1: "1 produto falso conhecido:",
      handMatch: "Isso corresponde ao produto em sua mão?",    
      knownFake: "produtos falsificados conhecidos:",
      imageMatch:"As imagens de algum desses produtos correspondem ao produto que você tem em mãos?",
      clickItem: "Clique no item para detalhes. Preste atenção especial ao código do lote.",
      productImage: "Imagem do Produto",
      batch: "Lote",
      seeMore: "Ver mais",
      about: "Sobre",
      dataPolicy: "Política de Dados",
      privacy: "Privacidade",
      privacyChoices: "As suas escolhas de privacidade",
      tnc: "Termos e Condições",
      allrights:"Todos os direitos reservados",
    },
    page_reportNewFake: {
      additionalImage: "Imagem adicional. Por favor, adicione um comentário abaixo para explicar.",
      fileNotSupported:"Tipo de arquivo não suportado",
      uploadMsg: "Upload em segundo plano;\npode levar alguns segundos.",
      close: "Cerrar",
      submitPhotos:"Envie fotos de",
      suspectProduct: "produto suspeito",
      required: "Obrigatório",
      additionalImage1: "Imagem adicional",
      additionalImage2: "Adicione uma imagem adicional, se achar que isso será útil.",
      comment: "Comente",
      additionalImagePlaceholder:"Comentários e/ou observações adicionais.",
      checkBatchcode: "Código do lote correto?",
      batchCode: "Código de lote",
      whereDidYouSee: "Onde você viu isso?",
      useCurrentLocation:"Usar minha localização atual",
      differentLocation:"Escolha um local diferente",
      processing: "..em processamento..",
      submit:"Envie usando o endereço acima",
      titleReceipt: "Recibo",
      instrReceipt: "Opcional. Adicione uma foto do recibo de venda.",
      titleGTIN: "Código de barras",
      instrGTIN: "Por favor, tire uma foto do código de barras do produto (GTIN).",
      titleBatch: "Código do lote",
      instrBatch: "Por favor, tire uma foto do código do lote.",
      titleBackLabel: "Rótulo traseiro",
      instrBackLabel: "Por favor, tire uma foto do rótulo traseiro completo do produto."
    },
    page_locations:{
      permissionIDB: "Infelizmente, este aplicativo não pode acessar o armazenamento do navegador no seu dispositivo para salvar locais para você.",
      browserPermission: "Verifique as configurações de permissão do seu navegador e permita `dados do site` em `Privacidade e segurança`.",
      addLocation: "Adicionar endereço",
      locationName: "Nome do local:",
      nameTaken: "nome já utilizado",
      placeName: "Nome do lugar",
      search: "Procurar:",
      NameAndAdd: "Nome e endereço",
      locationImage: "Imagem de localização",
      address: "Endereço:",
      coordinates: "Coordenadas:",
      here: "aqui",
      save: "Guardar",
      storedLocations: "Locais armazenados",
      searchSaved: "Pesquise em locais salvos:",
      filterByName: "Filtrar por nome",
      deleteAllPlaces: "Exclua todos os lugares",
    },
    page_settings: {
      register:"Por favor, insira o e-mail para se registrar.",
      inviteCode: "Você precisa de um código de convite para se registrar.",
      registerFailed: "Lamentamos, mas o registo falhou.\n(Erro do servidor)",
      registerSuccess: "Seu cadastro foi realizado com sucesso",
      registerFailed1: "Desculpe, algo deu errado com seu registro.",
      offline: "Você está off-line no momento.\nTente novamente mais tarde.",
      registerFailed2: "Desculpe, o registro falhou porque:\n",
      reinvite: "A reinvitação requer um endereço de e-mail válido.",
      invite: "Um e-mail de convite foi enviado para",
      inviteFailed: "Enviando convite para ",
      failed: " fracassado.",
      permissionIDB:"Infelizmente, este aplicativo não pode acessar o armazenamento do navegador no seu dispositivo para manter seus dados de login.",
      yourAccount:"Sua conta",
      siteDataPermission:"Verifique as configurações de permissão do seu navegador e permita 'dados do site' em `Privacidade e segurança`.",
      registeredName:"Você está registrado como",
      registeredEmail: "com o e-mail",
      accountStatus: "O status da sua conta é",
      regExp: "A inscrição expira em",
      deleteRegistration: "Excluir/redefinir registro",
      email: "E-mail",
      emailPlaceholder: "Seu endereço de email",
      emailAndCode:"Se você foi convidado,\n insira o e-mail e o código\n antes de clicar em 'Registrar'.",
      code: "Código",
      inviteCode:"Código de Convite",
      register1: "Registro",
      regCode: "Código de Registro",
      migCode: "Você tem um código de migração?"

    },
    page_about: {
        privacy: "Privacidade",
        cookies: "Este serviço web não utiliza cookies.",
        warning:"À medida que você envia uma denúncia de um produto suspeito de ser falsificado, o aplicativo coleta e envia as seguintes informações ao servidor:",
        details:"Detalhes do produto - GTIN, Imagens",
        timeAndDate: "Hora e data do relatório",
        location:"Localização do relatório",
        email:"Localização do relatório",
        browserInfo:"Informações do navegador",
        referLink:"Para obter mais informações, consulte",
        privacy: "política de Privacidade",
      }
      , page_help:{
        about: "Sobre",
        aboutDesc: "O aplicativo genuíno baseado na Web permite que você pesquise e verifique se um produto suspeito da P&G é genuíno ou falsificado, digitalizando ou inserindo seu Número Global de Item Comercial (GTIN).",
        aboutDesc1: "Como usuário registrado, o aplicativo permite tirar e enviar fotos de suspeitas de falsificação. O relato de um avistamento fica acessível tanto no modo online quanto offline.",
        counterfeit: "Relatório sobre avistamentos de falsificações",
        counterfeitDesc: "Ao encontrar um produto falsificado identificado e com permissão para relatar o avistamento, você será direcionado para uma caixa de diálogo onde poderá compartilhar informações detalhadas com a P&G.",
        counterfeitDesc1: "Contar-nos onde viu o produto falsificado permite-nos proteger tanto os nossos consumidores como a nossa marca. Por favor, deixe um comentário antes de relatar um avistamento.",
        support : "Seu apoio é realmente apreciado.",
        reportFake: "Denunciar falso = suspeito",
        welcomeMsg: "Você foi convidado pessoalmente pela P&G para usar este aplicativo não apenas para comparar produtos com produtos falsificados conhecidos, mas também para denunciar novos suspeitos de produtos falsificados.",
        instruction: "Entre em contato com a P&G para obter instruções detalhadas.",
      },
      page_tnc:{
        tnc: "Termos e Condições",
        linkDesc: "A utilização e acesso à informação deste site está sujeita à",
        linkDesc1: ",estabelecido em nosso acordo legal.",
      },
      page_dp:{
        dataPolicy: "Política de Dados",
        cookies:"Este serviço web não utiliza cookies.",
        dpDesc:"À medida que você envia uma denúncia de um produto suspeito de ser falsificado, o aplicativo coleta e envia as seguintes informações ao servidor:",
        productDetails: "Detalhes do produto - GTIN, Imagens",
        tnd:"Hora e data do relatório",
        location: "Localização do relatório",
        email: "Endereço de email",
        browserInfo: "Informações do navegador",
      },
      minion:{
        offlineMsg: "Você está off-line no momento.\n\nSuas fotos serão enviadas\nquando você estiver on-line novamente.",
        uploadMsg:"Obrigado, upload realizado com sucesso!",
        uploadMsg1:"O upload demorou muito mais que o esperado.\nTente novamente com uma conexão melhor.",
        uploadMsg2:"Desculpe, o upload falhou.",
        uploadMsg3:"O upload dos dados foi cancelado\nporque demorou muito mais que o esperado.\nTente novamente com uma conexão melhor.",
        uploadMsg4:"Não foi possível fazer upload de todos os dados.\nTente novamente quando estiver on-line.",
        errMsg: "Problema ao analisar arquivo de imagem.",
        errMsg1: "Tipo de arquivo não suportado.",
        errMsg2: "Não pode ser encontrado offline."
      },
      badlistMatch:{
        backLabel:"Contra-rótulo",
        batchCode:"Código de lote",
        position:"posição atual",
        offlineMsg:"Você está off-line no momento.\nVocê ainda pode enviar novos suspeitos para upload mais tarde..",
        failureMsg: "Desculpe, reportar o avistamento falhou.",
        sightingConfirmed:"Este é o produto? Se tiver um código de lote, fica exatamente como na imagem acima?",
        no: "não",
        yes: "sim",
        whereDidYouSee: "Onde você viu isso?",
        currentLocation: "Usar minha localização atual",
        differentLocation: "Escolha um local diferente",
        comment: "Você gostaria de adicionar um comentário?",
        commentPlaceholder: "Observações adicionais...",
        processing: "..em processamento..",
        sendPg: " Enviar para P&G",
      },
      locationSelect: {
        currentposition: "Posição atual",
        picked:"Escolhido no mapa",
        useCurrentLocation: "Usar minha localização atual",
        useAddress:  "Utilize o endereço acima",
        savedLocation: "Selecione um local salvo:",
      },
      menu:{
        locations: "Localizações",
        resumeUpload:"Continuar o carregamento",
        pauseUpload:"Pausar upload",
        manageDevice: "Gerenciar dispositivos",
        account: "Conta",
        cache:"Limpar cache",
        quit: "Sair do aplicativo",
      },
      scanEAN: {
        align:"Por favor, alinhe o código de barras no quadro acima para digitalizá-lo",
        camera: "Selecione Câmera",
        rescan:"Digitalizar novamente",
        ok:"OK",
        scan:"Digitalizando código de barras ...",
      },
      store:{
        address: "452, Basingstoke Road, Whitley, Reading, Inglaterra, RG2 0JF, Reino Unido",
      },
      manageDevices: {
        edit: "Editar",
        done: "Concluído",
        manageD: "Gerenciar Dispositivos",
        thisDevice: "Este Dispositivo",
        active: "Sesões Ativas",
        move: "Mover para um novo Dispositivo",
        unlink: "Este dispositivo será automaticamente desvinculado após você ter parado com um novo dispositivo",
        generateCode: "Gerar Código de Migração"
      },
      page_reg: {
        "enterCode": "Insira o Código de Registro",
        "enterMig": "Insira o código de migração",
        "regCode": "Código de Registro",
        "inviteCode": "Código de Convite",
        "register": "Registrar-se",
        "enterMigGenerated": "Insira o código de migração gerado a partir da sua conta existente para transferir a inscrição.",
        "migCode": "Código de Migração"
      }
      
  };
  