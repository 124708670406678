<!--
	Icon: Barcode

    @file         Icon_barcode.svelte
    @copyright    P&G
-->

<script>
  export let size = "24px";
</script>

<!-- =============================  H T M L  ============================= -->

<svg
  class="svg-icon"
  stroke="currentColor"
  fill="currentColor"
  width={size}
  height={size}
  viewBox="999 1000 12751 7001"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    stroke="none"
    d="M 1335,8000 L 999,8000 999,1000 1670,1000 1670,8000 1335,8000 Z"
  />
  <path
    stroke="none"
    d="M 7375,8000 L 7040,8000 7040,1000 7710,1000 7710,8000 7375,8000 Z"
  />
  <path
    stroke="none"
    d="M 13414,8000 L 13078,8000 13078,1000 13749,1000 13749,8000 13414,8000 Z"
  />
  <path
    stroke="none"
    d="M 4229,6268 L 3893,6268 3893,1000 4565,1000 4565,6268 4229,6268 Z"
  />
  <path
    stroke="none"
    d="M 2788,6268 L 2117,6268 2117,1000 3458,1000 3458,6268 2788,6268 Z"
  />
  <path
    stroke="none"
    d="M 10548,6268 L 9877,6268 9877,1000 11218,1000 11218,6268 10548,6268 Z"
  />
  <path
    stroke="none"
    d="M 6102,6268 L 5766,6268 5766,1000 6437,1000 6437,6268 6102,6268 Z"
  />
  <path
    stroke="none"
    d="M 8503,6268 L 8167,6268 8167,1000 8839,1000 8839,6268 8503,6268 Z"
  />
  <path
    stroke="none"
    d="M 12153,6268 L 11817,6268 11817,1000 12489,1000 12489,6268 12153,6268 Z"
  />
</svg>

<!-- ============================  S T Y L E  ============================ -->

<style>
</style>
