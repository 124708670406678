<!--
	genuinePG frontend

	Page, opened from Page_check.svelteHTML

    Imprint, General and legal information about app

    @file         Page_about.svelte
    @copyright    P&G
-->

<script>
  import { createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();
  import {t} from "./i18n"
  import { PAGE } from "./config";
  import { ArrowLeftCircleIcon } from "svelte-feather-icons";
  export let theme;

  // ========================= Swipe right to go back ==========================

  const setupSwipeRight = async () => {
    let touchStartX;
    let swipeArea = document.getElementById("page-about");

    // // console.log(swipeArea);

    swipeArea.addEventListener("touchstart", (evt) => {
      touchStartX = evt.changedTouches[0].screenX;
    });

    swipeArea.addEventListener("touchend", (evt) => {
      const touchEndX = evt.changedTouches[0].screenX;
      if (touchEndX - touchStartX > 80) {
        close();
      }
    });
  }; //setupSwipeRight()

  // =========================== Construction / Init ===========================

  import { onMount } from "svelte";

  onMount(async () => {
    setupSwipeRight();
  }); //onMount()

  const close = () => {
    dispatch("close", { page: PAGE.check });
  }; //close()
</script>

<!-- =============================  H T M L  ============================= -->

<div class:light={!theme} class:dark={theme} id="page-about">
  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:btnBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>

  <h2>{$t("page_dp.dataPolicy")}</h2>
  <p>{$t("page_dp.cookies")}</p>
  <p>
    {$t("page_dp.dpDesc")}
  </p>
  <ul>
    <li>{$t("page_dp.productDetails")}</li>
    <li>{$t("page_dp.tnd")}</li>
    <li>{$t("page_dp.location")}</li>
    <li>{$t("page_dp.email")}</li>
    <li>{$t("page_dp.browserInfo")}</li>
  </ul>

  <!-- <div id="btn-back" on:click={()=>close(PAGE.check)}>
        <ArrowLeftCircleIcon size='2x' />
    </div> -->
  <button id="btn-back" class:btnBlue={!theme} class:dark={theme} on:click={() => close(PAGE.check)} title="Back">
    <ArrowLeftCircleIcon size="2.5x" />
  </button>
</div>

<!-- ============================  S T Y L E  ============================ -->

<style>
  p {
    margin-bottom: 1em;
  }

  #btn-back {
    /* position: absolute;
        margin-top: -4%; */
    /* margin-bottom: -4%; */
    text-align: left;
    /* color: var(--PG-blue); */
    border: none;
    background: none;
  }

  ul {
    padding-left: 2em;
    margin-bottom: 2em;
  }
  .light{
    background-color: var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #fff;
    background-color: #242424;
  }
  .btnBlue{
    color: var(--PG-blue);
  }
</style>
